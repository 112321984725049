import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { toastOptions, sort } from 'utils/utils.jsx';

const CustomerContext = React.createContext();

class CustomerContextProvider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			customers: [],
			selectedCustomer: {},
			
			loadCustomers: this.loadCustomers,
			createUpdateCustomer: this.createUpdateCustomer,
			updateQuoteCustomer: this.updateQuoteCustomer,
			getCustomer: this.getCustomer,
			getCustomerHistory: this.getCustomerHistory,
			deleteCustomer: this.deleteCustomer,
			searchCustomer: this.searchCustomer,
			sort: this.sort,
		}
	}

	componentDidMount(){
		this.loadCustomers()
	}

	api_error  = () => {
		if( window.confirm('There were some error loading this page. Please refresh') ){
			window.location.reload()
		}
	}

	loadCustomers = () => {
		fetch(`/api/customer/list`,{
			method: 'POST'
		})
		.then(res => res.json())
		.then(customers =>{
			this.setState({...this.state, customers, loading: false });
		})
		.catch(err =>{
			this.setState({...this.state, loading: false });
			this.api_error()
			console.log(err)
		})
	}

	searchCustomer = async ( search, fullInfo= false ) => {
		let res = await fetch(`/api/customer/search`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			credentials: 'include',
			method: 'POST',
			body: JSON.stringify({search, fullInfo})
		})
		let resj = await res.json()
		return resj
	}

	createUpdateCustomer = async ( customer ) => {

		let toastID = toast.loading(`${customer._id ?'Updating':'Creating'} Customer...`)
		try{
			let req = await fetch(`/api/customer/manage`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				method: 'POST',
				body: JSON.stringify(customer)
			})
			let res = await req.json()

			let customers = this.state.customers
			toast.update(toastID, {...toastOptions, render: `Customer ${customer._id ?'Updated':'Created'}`, type: "success"})
			if(customer._id){
				// Update the updated customer in the loacal array as well instead of fetching all customers again.
				customers = customers.map(cus => customer._id == cus._id ? customer : cus)
			}else{
				customers.push(res)
			}
			this.setState({...this.state, customers})
			return res

		}catch(err){
			toast.update(toastID, {...toastOptions, render: "There were some issues creating this customer", type: "error"})
			this.api_error()
			console.log(err)
			return customer
		}
	}

	updateQuoteCustomer = async ( quote, customer ) => {

		let toastID = toast.loading(`${customer._id ?'Updating':'Creating'} Customer...`)
		try{
			let req = await fetch(`/api/customer/manage`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				method: 'POST',
				body: JSON.stringify(customer)
			})
			let res = await req.json()

			let customers = this.state.customers
			toast.update(toastID, {...toastOptions, render: `Customer ${customer._id ?'Updated':'Created'}`, type: "success"})
			if(customer._id){
				// Update the updated customer in the loacal array as well instead of fetching all customers again.
				customers = customers.map(cus => customer._id == cus._id ? customer : cus)
			}else{
				customers.push(res)
			}
			this.setState({...this.state, customers})
			return res

		}catch(err){
			toast.update(toastID, {...toastOptions, render: "There were some issues creating this customer", type: "error"})
			this.api_error()
			console.log(err)
			return customer
		}
	}

	getCustomer = async ( cid ) => {
		fetch(`/api/customer/${cid}`, {
			method: 'GET',
		})
		.then(res => res.json())
		.then(res =>{
			//this.setState({...this.state, customers });
			console.log(res)
		})
		.catch(err =>{
			this.api_error()
			console.log(err)
		})
	}

	deleteCustomer = async ( cid ) => {

		let toastID = toast.loading("Deleting Customer...")
		fetch(`/api/customer/${cid}`, {
			method: 'DELETE',
		})
		.then(res => res.json())
		.then(res =>{
			toast.update(toastID, {...toastOptions, render: res.text, type: res.res})
			let customers = this.state.customers.filter(cus => {
				if(cus._id !== cid){
					return cus
				}
			})
			this.setState({...this.state, customers})
		})
		.catch(err =>{
			toast.update(toastID, {...toastOptions, render: "There were some error deleting this customer", type: "error"})
			this.api_error()
			console.log(err)
		})
	}

	getCustomerHistory = async (cid) => {
		let req = await fetch(`/api/customer/history/${cid}`, {
			method: 'GET',
		})
		let res = await req.json();
		return res
	}

	sort = (sortKey, desc) => {
		let customers = sort(this.state.customers,sortKey, desc)
		this.setState({...this.state, customers})
	}
	

	render() {
		return (
			<CustomerContext.Provider value={this.state}>
				{this.props.children}
			</CustomerContext.Provider>
		);
	}
}

export { CustomerContext, CustomerContextProvider };
