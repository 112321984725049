import React, { Component } from 'react';

import './Loading.scss';

class Loading extends Component {

	render() {

		return (
			<div className="dot-pulse"></div>
		)
	}
}
export default Loading