import { Component } from 'react';
import { EventContext } from 'contexts/EventContext';
import Wrapper from 'pages/Wrapper';
import { HiUserAdd } from 'react-icons/hi';
import { AiOutlineEdit } from 'react-icons/ai';
import { RiDeleteBinLine } from 'react-icons/ri';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';

import FadingDots from "react-cssfx-loading/lib/FadingDots";
import Kalend, { CalendarView } from 'kalend'

import EventPopup from 'components/EventPopup/EventPopup';

import './Diary.scss';
import 'kalend/dist/styles/index.css';

export default class Events extends Component {
	static contextType = EventContext;

	constructor(props){
		super(props)

		this.state = {
			selectedEvent: {},
			selectedView: CalendarView.MONTH,
			showEventPopup: false,
			sort: {
				key: 'createdAt',
				desc: true,
			},
			deletingEvents: [],
			popupMode: 'edit',
			view: 'calendar'
		}

		this.columnNames = {
			eventTitle: 'Title',
			eventType: 'Event Type',
			eventDate: 'Event Date',
			smsEnabled: 'SMS Reminder',
			emailEnabled: 'Email Reminder',
			createdAt: 'Created at',
		}
	}

	showEventPopup = (selectedEvent = {}, popupMode = 'edit') => {
		this.setState({selectedEvent, showEventPopup: true, popupMode})
	}
	hideEventPopup = () => {
		this.setState({selectedEvent: {}, showEventPopup: false})
	}

	deleteEvent = async (event) => {
		let deletingEvents = this.state.deletingEvents
		deletingEvents.push(event)
		this.setState({...this.state, deletingEvents})

		this.context.deleteEvent(event)
		this.setState({...this.state, deletingEvents: this.state.deletingEvents})
	}

	sort = (key) => {
		let desc = this.state.sort.key == key && this.state.sort.desc
		this.context.sort(key, !desc)
		this.setState({...this.state, sort: {key, desc: !desc}})
	}

	componentDidMount(){
		document.title = 'Events';
		this.context?.sort('createdAt', true)
	}

	formatDate = str => {
		let date = new Date(str)
		return `${date.toDateString().substring(4)} ${date.toTimeString().substring(0, 5)}`
	}

	getEventsFormatted = () => {
		let calendar = {}
		let table = []

		this.context.events.forEach(event => {
			let date = new Date(event.date)
			let key = `${String(date.getUTCDate()).padStart(2,0)}-${String(date.getUTCMonth()+1).padStart(2,0)}-${date.getUTCFullYear()}`
			
			// let endDate = date.setHours(date.getHours() + 1)
			let color = (event.eventType == 'Other' ? 'orange' : ((event.eventType == 'Measure') ? 'blue' : '#4caf50'))
			event = {
				...event,
				startAt: date.toISOString(),
				endAt: date.toISOString(),
				summary: event.eventTitle,
				smsEnabled: event.smsReminder ? 'Yes' : 'No',
				emailEnabled: event.emailReminder ? 'Yes' : 'No',
				eventDate: new Date(event.date).toDateString(),
				color,
			}
			if(!calendar[key]){
				calendar[key] = []
			}

			calendar[key].push(event)
			table.push(event)
		})
		return {calendar,table}
	}

	setView = (selectedView) => {
		this.setState({...this.state, selectedView})
	}

	render() {

		let events = this.getEventsFormatted()
		window.change = (e) => {this.setState({...this.state, selectedView: e })}
		window.cv = CalendarView
		return (
			<Wrapper>
				{this.state.showEventPopup && <EventPopup event={this.state.selectedEvent} hide={this.hideEventPopup} mode={this.state.popupMode}/>}
				<div id="event_page_container">
					<div className="event_page_header">
						<button className="add_event_button" onClick={e => this.showEventPopup()}>
							<HiUserAdd />
							Add Event
						</button>
						<div className="event_tab_btns">
							<button className={`event_tab_btn calender_view ${this.state.view == 'calendar' && 'active_view'}`} onClick={e => this.setState({...this.state, view: 'calendar'})}>Calendar View</button>
							<button className={`event_tab_btn table_view ${this.state.view == 'table' && 'active_view'}`} onClick={e => this.setState({...this.state, view: 'table'})}>Table View</button>
						</div>
					</div>
					{this.context.loading ? 
						<div className="preloader">
							<FadingDots width="70px" height="70px" color="#62d3f1" />
						</div>
						:
						<>
							{this.state.view == 'calendar' ?
							<div style={{height: '500px'}}>
								{/* <Calend
									onEventClick={event=>this.showEventPopup(event)}
									onNewEventClick={(e)=>this.showEventPopup(e)}
									events={events.calendar}
									initialDate={new Date().toISOString()}
									hourHeight={60}
									initialView={CalendarView.MONTH}
									selectedView={CalendarView.MONTH}
									disabledViews={[CalendarView.AGENDA, CalendarView.DAY]}
									onSelectView={(e)=>{}}
									onPageChange={(e)=>{}}
									showMoreMonth={(e)=>{console.log(e, 'showMore')}}
								/> */}

								<div className='calendar_view_selector'>
									<div>
										<button className={this.state.selectedView == CalendarView.THREE_DAYS ? 'active':''} onClick={(e) => {this.setState({...this.state, selectedView: CalendarView.THREE_DAYS })}}>3 Days</button>
										<button className={this.state.selectedView == CalendarView.WEEK ? 'active':''} onClick={(e) => {this.setState({...this.state, selectedView: CalendarView.WEEK })}}>Week</button>
										<button className={this.state.selectedView == CalendarView.MONTH ? 'active':''} onClick={(e) => {this.setState({...this.state, selectedView: CalendarView.MONTH })}}>Month</button>
									</div>
								</div>

								<Kalend
									onEventClick={this.showEventPopup}
									onNewEventClick={this.showEventPopup}
									events={events.table}
									initialDate={new Date().toISOString()}
									hourHeight={60}
									initialView={CalendarView.MONTH}
									selectedView={this.state.selectedView}
									disabledViews={[CalendarView.AGENDA, CalendarView.DAY]}
									// onSelectView={(e)=>{console.log(e)}}
									onPageChange={(e)=>{console.log(e)}}
									isNewEventOpen={false}
									disabledDragging={false}
								/>
							</div>
							:
							<>
								{this.context.events.length != 0 ?
								<div className="events_table">
									<div className="table">
										<div className="table_headings">
											{Object.keys(this.columnNames).map((key) => {
												return (
													<div key={key} className="th sortable_th" onClick={e => this.sort(key)}>
														{this.columnNames[key]}&nbsp;
														{this.state.sort.key == key ?
															this.state.sort.desc ? <GoTriangleDown/> : <GoTriangleUp />
															:''
														}
													</div>
													)
											})}
											<div className="th"></div>
										</div>

										<div className="table_body">
										{Array.isArray(events.table) && events.table.map((event, index) => {
											return (
												<div className="tr" key={index}>
													{Object.keys(this.columnNames).map(key => <div key={key} className="td">{key == 'createdAt' ? this.formatDate(event[key]) : event[key]}</div>)}
													<div className="td">
														<button className="icon_btns" onClick={e => this.showEventPopup(event, 'edit')} disabled={this.state.deletingEvents.includes(event._id)}>
															<AiOutlineEdit />
														</button>
														{/* <button className="icon_btns" onClick={e => this.showEventPopup(event, 'view')} disabled={this.state.deletingEvents.includes(event._id)}>
															<FaEye />
														</button> */}
														<button className="icon_btns" onClick={e => this.deleteEvent(event._id)} disabled={this.state.deletingEvents.includes(event._id)}>
															<RiDeleteBinLine />
														</button>
													</div>
												</div>
												)
										})}
										</div>

									</div>
								</div>
								:
								<h3>No records found.</h3>}
							</>}
						</>
					}
				</div>
			</Wrapper>
		)
	}
}
