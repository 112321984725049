import React, { Component } from 'react';
import { toastOptions, isValidEmail } from 'utils/utils';
import { toast } from 'react-toastify';


const UserContext = React.createContext()

class UserContextProvider extends Component {

	constructor(props) {
		super(props)

		this.state = {
			isLoggedIn: undefined,
			user: {},
			users: [],
			loadingUsersList: true,

			storeSidebarCounts: this.storeSidebarCounts,
			logUserIn: this.logUserIn,
			logUserOut: this.logUserOut,
			createUpdateUser: this.createUpdateUser,
			listUsers: this.listUsers,
			refreshUser: this.refreshUser,
			deleteUser: this.deleteUser,
			searchUser: this.searchUser,
			forgotPassword: this.forgotPassword,
			resetPassword: this.resetPassword,
		}
	}


	componentDidMount = async () => {
		this.refreshUser()
	}

	refreshUser = async () => {
		try {
			let res = await fetch(`/api/users/current`, { method: 'POST' })
			let data = await res.json()

			let isLoggedIn = data.user ? true : false
			let user = data.user ? data.user : {}

			this.setState({ isLoggedIn, user, })


		} catch (err) {
			this.api_error()
			console.log(err)
		}
	}

	api_error = () => {
		if (window.confirm('There were some error loading this page. Please refresh')) {
			window.location.reload()
		}
	}

	// storeSidebarCounts = (sidebarCounts) => {
	// 	console.log("storing storeSidebarCounts", sidebarCounts)
	// 	this.setState({...this.state, sidebarCounts})
	// }
	searchUser = async (search) => {
		let res = await fetch(`/api/users/search`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			credentials: 'include',
			method: 'POST',
			body: JSON.stringify({ search })
		})
		let resj = await res.json()
		return resj
	}


	logUserIn = async (username, password) => {

		let toastID = toast.loading('Logging you in... Please wait.')
		try {
			let res = await fetch(`/api/users/login`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				method: 'POST',
				body: JSON.stringify({ username, password }),
			})

			let data = await res.json()
			if (data.res == 'success') {
				let user = data.user
				console.log("user", user)
				this.setState({ isLoggedIn: true, user })
			}
			toast.update(toastID, { ...toastOptions, type: data.res, render: data.text })
			return data

		} catch (err) {
			console.log(err)
			this.api_error()
			toast.update(toastID, { ...toastOptions, type: 'error', render: 'There was an error logging you in. Please try again.' })
		}

	}

	createUpdateUser = async (user) => {
		let toastText = user._id ? 'Updating User...' : 'Creating new user...'
		let toastID = toast.loading(toastText)
		try {
			let res = await fetch(`/api/users/manage`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				method: 'POST',
				body: JSON.stringify(user),
			})

			res = await res.json()
			if (res.res == 'success') {
				let users = this.state.users

				if (user._id) {
					users = users.map(u => {
						if (u._id != user._id) {
							return u
						}
						return user
					})
				} else {
					users.push(res.user)
				}
				this.setState({ ...this.state, users })
			}
			toast.update(toastID, { ...toastOptions, type: res.res, render: res.text })
			return res.user ? res.user : user

		} catch (err) {
			toast.update(toastID, { ...toastOptions, type: 'error', render: 'An error occured. Please try again.' })
			console.log(err)
			this.api_error()
			return user
		}
	}

	listUsers = async () => {
		let res = await fetch(`/api/users`)
		let users = await res.json()
		users = users.map(user => {
			user.name = user.fname + ' ' + user.lname
			user.createdAt = new Date(user.createdAt).toDateString().substring(4)
			user.lastLogin = new Date(user.updatedAt).toDateString().substring(4) + ', ' + new Date(user.updatedAt).toTimeString().substring(0, 5)
			return user
		})
		// users = users.filter(user => user._id != this.state.user._id)
		this.setState({ ...this.state, users, loadingUsersList: false })
	}

	deleteUser = async (userId) => {
		let toastID = toast.loading('Deleting User...')
		try {
			let res = await fetch(`/api/users/${userId}`, { method: 'DELETE' })
			res = await res.json()
			if (res.res == 'success') {
				let users = this.state.users.filter(user => user._id != userId)
				this.setState({ ...this.state, users })
			}
			toast.update(toastID, { ...toastOptions, type: res.res, render: res.text })
			return res
		} catch (err) {
			toast.update(toastID, { ...toastOptions, type: 'error', render: 'An unknown error occured while delting selected user.' })
			this.api_error()
			console.log(err)
			return {}
		}
	}

	logUserOut = async () => {
		console.log("user logout");
		await fetch(`/api/users/logout`, {
			credentials: 'include',
			method: 'POST',
		})

		this.setState({ isLoggedIn: false, user: {} })
	}

	forgotPassword = async (email) => {
		if (!isValidEmail(email)) {
			toast.error("Please enter a valid email address")
			return
		}
		let toastID = toast.loading("Sending password reset link...")
		let req = await fetch(`/api/users/forgot_password`, {
			credentials: 'include',
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ email })
		})

		let res = await req.json()
		toast.update(toastID, { ...toastOptions, render: res.msg, type: res.type })
		console.log(res)
	}

	resetPassword = async (token, password) => {

		console.log(password, token)
		let toastID = toast.loading("Resetting Password...")
		let req = await fetch(`/api/users/reset_password`, {
			credentials: 'include',
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ password, token })
		})

		let res = await req.json()
		toast.update(toastID, { ...toastOptions, render: res.msg, type: res.type })
		if (res.type === 'success') {
			window.location.href = window.location.origin
		}
		return res
	}

	render() {
		return (
			<UserContext.Provider value={this.state}>
				{this.props.children}
			</UserContext.Provider>
		)
	}
}

export { UserContext, UserContextProvider }
