import { Component } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';

import './EventPopup.scss';


export default class ViewDetails extends Component{

	constructor(props){
		super(props)
	}
	
	render() {
		let cus = this.props.customer
		return (
			<>
				<div className="event_details">
					<h4>
						Personal Details 
						<button className="icon_btns" onClick={this.props.edit} ><AiOutlineEdit /></button>
					</h4>
					<div className="bi_columns">
						<p>
							<strong>First Name: </strong>{cus.fname}
						</p>
						<p>
							<strong>Last Name: </strong>{cus.lname}
						</p>
					</div>
					<div className="bi_columns">
						<p>
							<strong>Email: </strong>{cus.email}
						</p>
						<p>
							<strong>Phone#: </strong>{cus.phone}
						</p>
					</div>
				</div>

				<div className="cus_shipping_details">
					<h4>
						Shipping Details 
						<button className="icon_btns" onClick={this.props.edit} ><AiOutlineEdit /></button>
					</h4>
					<div className="bi_columns">
						<p>
							<strong>Town: </strong>{cus.town}
						</p>
						<p>
							<strong>City: </strong>{cus.city}
						</p>
					</div>
					<div className="bi_columns">
						<p>
							<strong>ZIP/Post Code: </strong>{cus.postCode}
						</p>
						<p>
							<strong>Country: </strong>{cus.country}
						</p>
					</div>
					<p><strong>Address Line 1: </strong>{cus.address1}</p>
					<p><strong>Address Line 2: </strong>{cus.address2}</p>
				</div>

				<div className="cus_shipping_details">
					<h4>
						Customer History
					</h4>
					<p>currently Not Available</p>
				</div>

			</>
		)
	}
}