import React, { Component } from 'react';
import { UserContext } from 'contexts/UserContext';
import { FiUser, FiLock } from 'react-icons/fi';
import { Navigate } from 'react-router-dom';

import './Recover.scss';

class Recover extends Component {
	static contextType = UserContext;
	state = {
		password: '',
    confirm_password: '',
    loading: false,
	}

	handleSubmit = async (event) => {
		event.preventDefault()
		let { password, confirm_password } = this.state

    if( password !== confirm_password ) {
      alert('Passwords do not match')
      return
    }
    let token = new URLSearchParams(window.location.search).get('token')

    this.setState({...this.setState, loading: true})
    let res = await this.context.resetPassword(token, password)
    this.setState({...this.setState, loading: false})
	}


	handleInput = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		})
	}
  
	render() {
    
    document.title = 'Enter new paswword...';
    if( !new URLSearchParams(window.location.search).get('token') ){
      return <Navigate to="/404" />
    }
    if( this.context.user.email ){
      return <Navigate to="/" />
    }
		return (
			<div id="recover_page">
				<form method="post" onSubmit={this.handleSubmit} className="recover_form">
					<div className="avatar__icon">
							<FiUser className="avatar__icon__svg" strokeWidth="1" />
					</div>

					<label className="password_label">
            <FiLock strokeWidth="2" />
            <input
              type="password"
              name="password"
              placeholder="New Password"
              required={true}
              onChange={this.handleInput}
              value={this.state.password}
            />
					</label>

          <label className="password_label">
            <FiLock strokeWidth="2" />
            <input
              type="password"
              name="confirm_password"
              placeholder="Confirm Password"
              required={true}
              onChange={this.handleInput}
              value={this.state.confirm_password}
            />
          </label>
					<input type="submit" name="submit" value="RESET PASSWORD" disabled={this.state.loading} />
				</form>
			</div>
		)
	}
}

export default Recover;
