import { Component } from 'react';

import Flatpickr from "react-flatpickr";
import { QuoteContext } from 'contexts/QuoteContext';
import Popup from 'components/Popup/Popup';
import { MdOutlinePlaylistAdd, MdCancelScheduleSend } from 'react-icons/md';
import { AiFillCloseCircle, AiFillCheckCircle, AiFillPrinter } from 'react-icons/ai';
import { currencyFormat, ControlledInput, paymentterms, DateOptions, getVAT, dateFormat, getTotalInvoiceCredits, getTotalInvoiceAmount, getInvoiceOutstanding, daysoverdue_str, infoTcs } from "utils/utils";
import { GrDocumentUpdate } from 'react-icons/gr';
import { SiMinutemailer } from 'react-icons/si';
import { BsCashCoin } from 'react-icons/bs';


import FadingBalls from "react-cssfx-loading/lib/FadingBalls";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { QuoteContextProvider } from 'contexts/QuoteContext';
import QuotePopup from 'components/QuotePopup/QuotePopup';
import PrintQuote from 'components/PrintQuote/PrintQuote';
import ReactToPrint from 'react-to-print';

import './InvoicePopup.scss';

export default class InvoicePopup extends Component {
	static contextType = QuoteContext;

	constructor(props) {
		super(props)

		let today = new Date();
		let newdue_date = new Date();
		let nextFitDate = this.props.invoice?.quote?.nextFitDate ? this.props.invoice.quote.nextFitDate : null;

		let due_date = nextFitDate ? new Date(nextFitDate) : new Date(newdue_date.setDate(newdue_date.getDate() + paymentterms));

		if (today - due_date > 0) {
			due_date = new Date(newdue_date.setDate(today.getDate() + paymentterms));
		}

		this.state = {
			paymentMethods: [],
			bankAccounts: [],
			invoice: {
				...this.props.invoice,
				due_date: this.props.invoice.due_date ? this.props.invoice.due_date : due_date,
				payments: [],
				createdAt: this.props.invoice.createdAt || today,
				tcs: infoTcs({ quote: { type: "job" } }),
			} || {
				amount: 0,
				createdAt: today,
				due_date: due_date,
				tcs: infoTcs({ quote: { type: "job" } }),
				paid_date: null,
				payments: []
			},
			newPaymentErrors: [],
			load: (this.props.load ? (this.props.load == "true" ? true : false) : true),
			loading: false,
			openjob: false,
			zindex: (this.props.zindex) ? this.props.zindex : 9,
			newPayment: null,
			quote: this.props.quote || {},
			disabled: false
		}
	}

	emailInvoice = async () => {
		this.setState({ ...this.state.invoice, disabled: true });
		await this.context.emailInvoice(this.state.invoice);
		this.setState({ ...this.state.invoice, disabled: false })
	}

	takeInvoicePayment = async (paymentlink) => {
		this.setState({ ...this.state, paymentlink });
	}

	recordPayment = async () => {
		this.setState({ ...this.state, recordPayment: true })
	}

	hideRecordPayment = async () => {
		this.setState({ ...this.state, recordPayment: false })
	}

	voidInvoice = async (e) => {
		e.preventDefault()
		this.setState({ ...this.state, voidingInvoice: true })
		let invoice = await this.context.voidInvoice(this.state.invoice)

		this.setState({ ...this.state, voidingInvoice: false, voiding: false, invoice })
	}

	markInvoicePaid = async (e) => {
		e.preventDefault()
		this.setState({ ...this.state, payingInvoice: true })
		await this.context.markInvoiceAsPaid(this.props.invoice.id)
		this.setState({ ...this.state, payingInvoice: false })
		this.props.hide()
	}

	updateInvoice = async (e) => {
		e.preventDefault()
		this.setState({ ...this.state, disabled: true });
		let res = await this.context.createUpdateInvoice(this.state.invoice);
		if (res.invoice) {
			this.setState({ ...this.state, invoice: res.invoice, disabled: false })
		}

	}

	raiseInvoice = async (e) => {
		e.preventDefault()
		this.setState({ ...this.state, disabled: true });

		let res = await this.context.createUpdateInvoice(this.state);

		if (res.invoice) {
			let quote = res.invoice.quote ? res.invoice.quote : this.state.quote;

			this.setState({ ...this.state, invoice: res.invoice, quote, disabled: false })
		}

	}

	preventSubmit = (e) => {
		if (e.keyCode === 13 && e?.target?.nodeName === 'INPUT') {
			e.preventDefault()
		}
	}

	toggleItem = (e, quoteitem, index) => {

		let thisinvoice = this.state.invoice;
		let invoiceitems = thisinvoice.invoice_lines || [];

		let thisquoteitem = quoteitem;

		let quoteitemid = thisquoteitem._id;

		thisquoteitem.quoteitemid = quoteitemid;
		thisquoteitem.quoteitemindexno = thisquoteitem.indexno;

		thisquoteitem.net_amount = thisquoteitem.net_amount || 0;
		thisquoteitem.tax_amount = thisquoteitem.tax_amount || 0;
		thisquoteitem.total_amount = thisquoteitem.total_amount || 0;
		thisquoteitem.unit_price = thisquoteitem.unit_price || 0;

		let newinvoiceitemslist = invoiceitems;

		if (!e.target.checked) {
			newinvoiceitemslist = newinvoiceitemslist.filter(invoiceitem => invoiceitem.quoteitemindexno !== thisquoteitem.indexno);
			// unticked... remove any from the list that match the ID
		} else {
			//is checked. add it to the list

			newinvoiceitemslist.push(thisquoteitem);
		}

		thisinvoice.invoice_lines = newinvoiceitemslist;
		this.context.setInvoice(thisinvoice);
		let total_amount = this.getInvoiceAmount(thisinvoice);

		thisinvoice.total_amount = Number(total_amount || 0).toFixed(2);;
		this.setState({ ...this.state, invoice: thisinvoice })

	}

	isQuoteitemIncluded = (quoteitem, invoice_lines) => {
		let isIncluded = invoice_lines.some(invoiceitem => quoteitem.indexno === invoiceitem.quoteitemindexno && typeof (invoiceitem.quoteitemindexno) !== "undefined");
		return isIncluded || false;
	}

	handleDate = (selectedDates, thisdate, instance, fieldname) => {
		let thisInvoice = this.state.invoice;

		if (Array.isArray(selectedDates)) {
			selectedDates = selectedDates[0];
		}
		if (fieldname === "date") {
			thisInvoice.date = selectedDates;
		}
		else if (fieldname === "due_date") {
			thisInvoice.due_date = selectedDates;
		}
		else if (fieldname === "paid_date") {
			thisInvoice.paid_date = selectedDates;
		}

		this.context.setInvoice(thisInvoice);
		this.setState({ ...this.state, invoice: thisInvoice })
	}

	handlePaymentDate = (selectedDates, thisdate, instance, fieldname) => {
		let newPayment = this.state.newPayment ? this.state.newPayment : {};

		selectedDates = dateFormat(selectedDates);

		if (fieldname === "payment_date") {
			newPayment.payment_date = selectedDates;
		}


		this.setState({ ...this.state, newPayment })
	}

	handleInput = (e) => {

		let field = e.target;
		let newItem = this.state.invoice.newItem ? this.state.invoice.newItem : { qty: 1, net_amount: 0, tax_amount: 0, total_amount: 0 };

		let thisInvoice = this.state.invoice;

		if (field.name === "tcs") {
			thisInvoice.tcs = e.target.value;
		}
		else if (field.name === "additional_description") {
			thisInvoice.notes = e.target.value;
		}
		else if (field.name === "void_reason") {
			thisInvoice.void_reason = e.target.value;
		}
		else if (field.name === "date") {
			thisInvoice.date = e.target.value;
		}
		else if (field.name === "due_date") {
			thisInvoice.due_date = e.target.value;
		}
		else if (field.name === "paid_date") {
			thisInvoice.paid_date = e.target.value;
		}
		else if (field.name === 'custom_line_description') {
			newItem.name = field.value;
			thisInvoice.newItem = newItem;
		}
		else if (field.name === 'custom_line_taxrateid') {
			newItem.taxrateid = field.value
			newItem.tax_amount = getVAT(newItem.net_amount, newItem.taxrateid);

			newItem.total_amount = Number((Number(newItem.net_amount) + Number(newItem.tax_amount)));
			thisInvoice.newItem = newItem;
		}
		else if (field.name === 'custom_line_unit_price') {
			let val = e.target.value

			newItem.unit_price = val;
			newItem.net_amount = Number(val) * (newItem.qty || 1)
			let vatamount = (newItem.taxrateid) ? getVAT(val, newItem.taxrateid) : 0;
			newItem.tax_amount = Number(vatamount);

			newItem.total_amount = Number((Number(newItem.net_amount) + Number(newItem.tax_amount)));

			thisInvoice.newItem = newItem;
		}
		else if (field.name === 'custom_line_qty') {

			newItem.qty = Number(field.value);
			thisInvoice.newItem = newItem;
		}
		this.context.setInvoice(thisInvoice);
		this.setState({ ...this.state, invoice: thisInvoice })
	}

	addLineItem = () => {
		let thisInvoice = this.state.invoice;
		let newItem = thisInvoice.newItem || {};
		newItem.unit_price = newItem.unit_price || 0;
		if (newItem.hasOwnProperty("name")) {
			let invoice_lines = thisInvoice.invoice_lines ? thisInvoice.invoice_lines : [];
			newItem.qty = newItem.qty || 1;
			invoice_lines.push(thisInvoice.newItem);
			thisInvoice.newItem = {};
			thisInvoice.invoice_lines = invoice_lines;
			this.context.setInvoice(thisInvoice);
			this.setState({ ...this.state, invoice: thisInvoice })
		}
		else {
			//there isn't a new item or it doesn't have enough properties
		}

	}

	additionalInvoiceItems = () => {
		let thisInvoice = this.state.invoice;
		return thisInvoice.invoice_lines?.filter(invoiceitem => invoiceitem.quoteitemid == "" || typeof (invoiceitem.quoteitemid) == "undefined");
	}

	removeInvoiceLine = invoiceitem => {
		let thisInvoice = this.state.invoice;
		let invoice_lines = thisInvoice.invoice_lines;
		invoice_lines = invoice_lines?.filter(thisitem => thisitem._id != invoiceitem._id);

		thisInvoice.invoice_lines = invoice_lines;
		let total_amount = this.getInvoiceAmount(thisInvoice);

		thisInvoice.total_amount = Number(total_amount || 0).toFixed(2);;

		this.context.setInvoice(thisInvoice);
		this.setState({ ...this.state, invoice: thisInvoice })
	}

	addInvoiceItem = quoteitem => {
		let thisquoteitem = quoteitem;
		let thisInvoice = this.state.invoice;

		let quoteitemid = thisquoteitem._id;

		thisquoteitem.quoteitemid = quoteitemid;

		thisquoteitem.net_amount = thisquoteitem.net_amount || 0;
		thisquoteitem.tax_amount = thisquoteitem.tax_amount || 0;
		thisquoteitem.total_amount = thisquoteitem.total_amount || 0;
		thisquoteitem.unit_price = thisquoteitem.unit_price || 0;
		thisquoteitem.qty = thisquoteitem.qty || 1;

		let invoice_lines = thisInvoice.invoice_lines ? thisInvoice.invoice_lines : [];
		let newItem = thisquoteitem;

		invoice_lines.push(newItem);

		thisInvoice.invoice_lines = invoice_lines;
		this.context.setInvoice(thisInvoice);
		this.setState({ ...this.state, invoice: thisInvoice })
	}

	openPaymentWindow = (invoice) => {

		window.open(invoice.links[1], 'Data', 'height=250,width=250');
	}

	removePayment = payment => {
		let thisInvoice = this.state.invoice;
		let payments = thisInvoice.payments;

		payments = payments?.filter(thispayment => thispayment._id != payment._id);

		thisInvoice.payments = payments;
		this.context.setInvoice(thisInvoice);
		this.setState({ ...this.state, requestDeletePayment: null, invoice: thisInvoice })
	}

	handlePaymentData = (e) => {

		let field = e.target;

		let newPayment = this.state.newPayment ? this.state.newPayment : {};

		if (!newPayment.payment_date) { newPayment.payment_date = new Date() }

		if (field.name === "payment_method") {
			newPayment.payment_method = e.target.value;
		}
		else if (field.name === "payment_amount") {
			newPayment.payment_amount = currencyFormat(e.target.value, "numbers");
		}
		else if (field.name === "bank_account") {
			newPayment.bank_account = e.target.value;
		}

		else if (field.name === "payment_reference") {
			newPayment.payment_reference = e.target.value;
		}



		this.setState({ ...this.state, newPayment })
	}

	isInvoiceEditable = (invoice) => {


		return this.getInvoiceOutstanding(invoice) >= this.getInvoiceAmount(invoice) ? true : false;


	}

	addPayment = async (e) => {
		this.setState({ ...this.state, addingPayment: true })
		let invoice = this.state.invoice;
		let invoiceEditable = this.isInvoiceEditable(invoice);

		let newPayment = this.state.newPayment ? this.state.newPayment : { payment_date: new Date };

		let newPaymentErrors = [];
		if (!newPayment.bank_account) {
			newPaymentErrors.push("bank_account");
		}
		if (!newPayment.payment_method) {
			newPaymentErrors.push("payment_method");
		}

		if (!newPayment.payment_reference) {
			newPaymentErrors.push("payment_reference");
		}

		if (newPaymentErrors.length <= 0) {
			let invoice_outstanding = invoice.invoice_outstanding || 0;

			let thisPaymentAmount = newPayment.payment_amount ? newPayment.payment_amount : invoice_outstanding;

			thisPaymentAmount = currencyFormat(thisPaymentAmount, "numbers")

			let thispayment = { ...newPayment, payment_amount: thisPaymentAmount }

			let payments = invoice.payments ? invoice.payments : [];

			let oldPayments = JSON.parse(JSON.stringify(payments));

			payments.push(thispayment);
			invoice.payments = payments;


			this.setState({ ...this.state, invoice, newPayment: null, addingPayment: false })

			let res = await this.context.addInvoicePayment({ ...thispayment, invoice, invoiceEditable }); // add invoice to the data just for the api

			console.log("res: ", res)
			if (res.res == "success") {
				// todo - find recently added invoice, get sageid
			}
			else {

				this.setState({ ...this.state, invoice: { ...this.state.invoice, payments: oldPayments }, newPaymentErrors, addingPayment: false })
			}
		}
		else {
			this.setState({ ...this.state, newPaymentErrors, addingPayment: false })
		}

	}

	getTotalPrice = (invoice) => {
		let totalprice = getTotalInvoiceAmount(invoice);
		return totalprice ? totalprice : 0;
	}

	getInvoiceAmount = (invoice) => {
		return getTotalInvoiceAmount(invoice);
	}

	getInvoiceOutstanding = (invoice) => {
		return getInvoiceOutstanding(invoice);
	}

	getTotalInvoiceCredits = (invoice) => {
		return getTotalInvoiceCredits(invoice);
	}

	renderRecordPayment = () => {
		if (this.state.recordPayment) {
			return (
				<RecordPayment hide={this.hideRecordPayment} hideMain={this.props.hide} invoice={this.state.invoice} />
			)
		}
	}

	getJobInvoiced = (quote) => {

		return getTotalInvoiced = (quote)

	}

	renderEditor = (htmlcontent) => {
		return (<RichEditor onchange={this.onRTEchange} initialValue={htmlcontent} />);
	}

	onRTEchange = (newcontent) => {
		let thisInvoice = this.state.invoice;
		thisInvoice.tcs = newcontent;
		this.context.setInvoice(thisInvoice);
		this.setState({ ...this.state, invoice: thisInvoice })
	}

	async componentDidMount() {
		window.scrollTo(0, 0);
		if (this.state.load && this.state.invoice._id !== 0) {
			this.setState({ ...this.state, loading: true })

			let res = await this.context.getInvoice(this.state.invoice._id);
			console.log("got invoice from context: ", res);
			this.setState({ ...this.state, invoice: res.invoice, paymentMethods: this.context.paymentMethods, bankAccounts: this.context.bankAccounts, loading: false, load: false })
		}
		else {
			this.setState({ ...this.state, load: false })
		}

		if (this.state.paymentMethods.length <= 0) {
			let res = await this.context.getPaymentMethods();
			console.log("res: ", res);
			this.setState({ ...this.state, paymentMethods: res.paymentMethods, bankAccounts: res.bankAccounts })
		}
	}

	render() {


		let invoice = this.state.invoice;

		console.log("invoice: ", invoice);

		let quote = invoice.quote || { invoices: [] };


		let customer = null;
		let payments = [];
		let invoice_lines = null;
		let quoteitems = null;
		let thistitle = null;

		let createdAt = null;
		let due_date = null;
		let invoiceAmount = 0;
		let total_outstanding = null;
		let total_amount = null;
		var total_joboutstanding;
		let total_credited = null;
		let total_jobinvoices = null;
		let total_paid = null;
		let thissubtitle = null;
		let overduestr = null

		let total_invoices_count, total_invoices_amount;
		let customeremailstr = ""
		let invoicetcs = infoTcs(invoice, "editor");
		let status = invoice?.status, jobStatus, quotePriceTotal, invoice_outstanding;

		let quoteid;

		if (invoice && quote && !this.state.load) {

			customer = invoice.customer || quote.customer;
			customeremailstr = customer?.email;
			quoteid = quote._id;


			if (customer?.alternativeEmail) {
				customeremailstr += " / " + customer?.alternativeEmail;
			}
			if (customer?.mobile) {
				customeremailstr += " / " + customer.mobile;
			}
			payments = invoice?.payments ? invoice.payments : []

			invoice_lines = invoice?.invoice_lines || [];
			total_invoices_count = invoice.quote.total_invoices_count;
			total_invoices_amount = invoice.quote.total_invoices_amount;
			jobStatus = quote.status;
			quoteitems = quote?.items || [];

			thistitle = (typeof (invoice?.displayed_as) == "undefined") ? "Raise Invoice For Job: " + quote.numericID : "Edit Invoice " + invoice.displayed_as;

			quotePriceTotal = quote.quotePriceTotal;

			createdAt = invoice.createdAt ? invoice.createdAt : new Date();
			due_date = invoice.due_date ? invoice.due_date : "";

			invoiceAmount = this.getInvoiceAmount(invoice);

			//outStandingAmount = invoiceAmount - totalPaid
			payments = invoice.payments ? invoice.payments : [];
			total_paid = payments.reduce((total, payment) => total + Number(payment.payment_amount), 0) || 0;
			total_amount = invoice.total_amount || 0;
			total_credited = this.getTotalInvoiceCredits(invoice);
			total_jobinvoices = invoice.total_jobinvoices;
			total_joboutstanding = invoice.total_outstanding;
			invoice_outstanding = total_amount - total_paid || 0;
			thissubtitle = invoice.status == "VOID" ? <><h3 className="void red">VOID  {dateFormat(invoice.void_date)} </h3> <br /> {invoice.void_reason && <> Reason: {invoice.void_reason}<br /><br /></>}</> : null;

			overduestr = daysoverdue_str(total_outstanding, due_date);
		}

		let newPayment = this.state.newPayment || { payment_amount: total_outstanding };

		let voiding = this.state.voiding;

		let editable = this.isInvoiceEditable(invoice);

		// console.log("editable: ", editable, invoice?.notes, invoicetcs);


		return (
			<>
				{
					this.state.loading || this.state.loading &&
					<div className="preloader" style={{ height: '40px' }}><FadingBalls color="#dd4477" /></div>
				}
				{
					!this.state.loading && !this.state.load &&
					<>
						{this.state.job &&
							<QuoteContextProvider>
								<QuotePopup className="quote_popup slidein_right" zindex={this.state.zindex} hide={e => this.setState({ ...this.state, job: null })} quote={{ _id: quoteid, loadquote: true }} mode={'edit'} />
							</QuoteContextProvider>
						}

						{
							!this.state.job &&
							<Popup {...this.props} className="invoice_popup" zindex={this.state.zindex} hide={this.props.hide}>
								{this.state.loading &&
									<>
										<div className="preloader" style={{ height: '40px' }}><FadingBalls color="#dd4477" /></div>
									</>
								}
								{
									!this.state.loading &&
									<>
										<div className='invoicetitle'><h2>{thistitle}</h2> {thissubtitle}</div>
										{voiding &&
											<>
												<h2>Void Reason</h2>

												<textarea name="void_reason" onChange={this.handleInput} placeholder="Reason to void this invoice" required={true} maxLength="50"></textarea>
												<div className="buttons invoice_void_buttons">
													<button type="button" className="themebutton button cancel" onClick={e => this.setState({ ...this.state, voiding: false })}>Cancel</button>
													<button className="themebutton button success" onClick={this.voidInvoice} disabled={this.state.voidingInvoice}>Void</button>
												</div>
											</>
										}
										{
											!voiding &&
											<>
												<div className="bi_columns invoice_dates">
													<div>
														<label>
															<span>Invoice Date</span>
															<Flatpickr options={DateOptions} onChange={(selectedDates, dateStr, instance) => { this.handleDate(selectedDates, dateStr, instance, "createdAt") }} name='createdAt' value={createdAt} required />
														</label>
														<label>
															<span>Due Date</span>
															<Flatpickr options={DateOptions} onChange={(selectedDates, dateStr, instance) => { this.handleDate(selectedDates, dateStr, instance, "due_date") }} name='due_date' value={due_date} required />
														</label>
													</div>
													<div>
														<h4>Job Summary</h4>
														<label>
															<b>Customer:</b> {customer?.fname + " " + customer?.lname + " (" + customeremailstr + ")"}<br /><br />
															<b>Total Job Price:</b> {currencyFormat(quotePriceTotal)}<br />
															<b>Total Job Invoices ({total_invoices_count}):</b> {currencyFormat(total_invoices_amount)}<br />
															<b>Total Job Outstanding:</b> {currencyFormat(total_joboutstanding)}<br />

															<b>Job Status: {jobStatus}</b><br /><br />
															<button className="themebutton sm-left viewjob" onClick={() => { this.setState({ ...this.state, job: { _id: quoteid } }) }}>view job</button>
														</label>
													</div>

													<div>
														<h4>Invoice Summary</h4>
														<b>Status:</b> {invoice.status}<br />
														<b>This Invoice Amount:</b> {currencyFormat(invoiceAmount)}<br />
														<b>This Invoice Payments:</b> {currencyFormat(total_paid)}<br />
														<b>This Invoice credits:</b> {currencyFormat(total_credited)}<br />
														<b>This Invoice Outstanding:</b> {currencyFormat(invoice_outstanding)}<br />
														<b>{overduestr}</b><br />
													</div>
												</div>


												<h4>Invoice Items</h4>
												<div className="table invoice_items">
													<div className="table_headings">
														<div></div>
														<div className="th">Item Name</div>
														<div className="th">Item Qty</div>
														<div className="th">Unit Price</div>
														<div className="th">Net Amount</div>
														<div className="th">Tax Amount</div>
														<div className="th">Total</div>
													</div>
													<div className="table_body">
														{invoice_lines?.map((invoiceitem, i) => {
															return (
																<div className="tr" key={i}>
																	<div>{editable && <AiFillCloseCircle className="remove_icon" onClick={e => this.removeInvoiceLine(invoiceitem)} />}</div>
																	<div className="td">{invoiceitem.name}<br />{invoiceitem.description}</div>
																	<div className="td">{invoiceitem.qty || 1}</div>
																	<div className="td">{currencyFormat(invoiceitem.unit_price || 0)}</div>
																	<div className="td">{currencyFormat(invoiceitem.net_amount || 0)}</div>
																	<div className="td">{currencyFormat(invoiceitem.tax_amount || 0)}</div>
																	<div className="td">{currencyFormat(invoiceitem.total_amount || 0)}</div>
																</div>
															)
														})}


													</div>
												</div>
												<h3 className={currencyFormat(invoiceAmount, "numbers") == currencyFormat(quotePriceTotal, "numbers") ? "green" : currencyFormat(invoiceAmount, "numbers") > currencyFormat(quotePriceTotal, "numbers") ? "red" : ""} style={{ textAlign: 'right', fontSize: '20px' }}>This Invoice Amount: {currencyFormat(invoiceAmount)}</h3>
												{editable && <>
													<h4>Quote Items</h4>
													<div className="table invoice_items">
														<div className="table_headings">
															<div></div>
															<div className="th">Item Name</div>
															<div className="th">Item Qty</div>
															<div className="th">Unit Price</div>
															<div className="th">Net Amount</div>
															<div className="th">Tax Amount</div>
															<div className="th">Total</div>
														</div>
														<div className="table_body">

															{quote?.items?.map((quoteitem, index) => {
																// if (quoteitems.some(quoteitem => quoteitem.indexno === invoiceitem.quoteitemindexno)) {
																// 	return;
																// }
																return (
																	<div className="tr" key={index}>
																		<div className="td">{editable && <AiFillCheckCircle className="add_icon" onClick={e => this.addInvoiceItem(quoteitem)} />}</div>
																		<div className="td">{quoteitem.name ? quoteitem.name : quoteitem.displayed_as ? quoteitem.displayed_as : ""}<br />{quoteitem.description}</div>
																		<div className="td">{quoteitem.qty}</div>
																		<div className="td">{currencyFormat(quoteitem.unit_price || 0)}</div>
																		<div className="td">{currencyFormat(quoteitem.net_amount || 0)}</div>
																		<div className="td">{currencyFormat(quoteitem.tax_amount || 0)}</div>
																		<div className="td">{currencyFormat((quoteitem.net_amount + quoteitem.tax_amount))}</div>
																	</div>
																)
															})}
															{
																status != "VOID" && editable &&
																<div className="tr newitem">
																	<div className="td"></div>
																	<div className="td"><input type="text" name="custom_line_description" value={invoice?.newItem?.name} placeholder="New Item" onChange={this.handleInput} /></div>
																	<div className="td"><input type="text" name="custom_line_qty" placeholder="1" value={invoice?.newItem?.qty} onChange={this.handleInput} /></div>
																	<div className="td price"><div className="currencysym">&pound;</div><ControlledInput value={currencyFormat(invoice?.newItem?.unit_price, "numbers")} name="custom_line_unit_price" type="text" inputMode="numeric" step="any" placeholder="Unit Price" onChange={this.handleInput} required autoComplete="off" /></div>
																	<div className="td taxrateid"><select className="taxrateid" onChange={this.handleInput} name="custom_line_taxrateid"><option>Please Select</option><option value="GB_STANDARD">20%</option><option value="GB_LOWER">5%</option><option value="GB_ZERO">0%</option><option value="GB_EXEMPT">Exempt</option></select></div>

																	<button className="themebutton" type="button" onClick={this.addLineItem}><MdOutlinePlaylistAdd />Add Line</button>

																</div>
															}

														</div>
													</div>
												</>}
												{
													(editable || invoice?.notes != "") &&
													<div className='textareawrapper'>
														<div className='title'>Additional description</div>
														{editable &&
															<textarea name="additional_description" placeholder="Invoice description" onChange={this.handleInput} defaultValue={invoice?.notes}></textarea>
														}
														{!editable &&
															<div dangerouslySetInnerHTML={{ __html: invoice?.notes }} />
														}

													</div>
												}
												{
													(editable || invoicetcs != "") &&
													<div className='textareawrapper'>
														<div className='title'>Terms and Conditions</div>
														{editable &&
															<>
																<ReactQuill theme="snow"
																	value={invoicetcs}
																	onChange={this.onRTEchange}
																	formats={[
																		'header',
																		'font',
																		'size',
																		'bold',
																		'italic',
																		'underline',
																		'strike',
																		'blockquote',
																		'list',
																		'bullet',
																		'indent'
																	]}
																></ReactQuill>
															</>
														}
														{!editable && <div dangerouslySetInnerHTML={{ __html: invoicetcs }} />}
													</div>
												}

												{invoice._id !== 0 &&
													<>
														<h4>Payments</h4>
														<div className="table payments_list">
															<div className="table_headings">

																<div className="th">Date Paid</div>
																<div className="th">Amount</div>
																<div className="th">Payment Method</div>
																<div className="th">Bank Account</div>
																<div className="th">Reference</div>
															</div>
															<div className="table_body">
																{payments?.map((payment, i) => {
																	return (
																		<div className="tr" key={i}>

																			<div className="td">{dateFormat(payment.payment_date)}</div>
																			<div className="td">{currencyFormat(payment.payment_amount)}</div>
																			<div className="td">{payment.payment_method}</div>
																			<div className="td">{payment.bank_account}</div>
																			<div className="td">{payment.payment_reference}</div>

																		</div>
																	)
																})}

																{
																	(invoice_outstanding > 0) && invoice.status != "VOID" ?
																		<div className="tr">

																			<div className="td"><Flatpickr options={DateOptions} onChange={(selectedDates, dateStr, instance) => { this.handlePaymentData(selectedDates, dateStr, instance, "paid_date") }} value={newPayment?.payment_date ? newPayment?.payment_date : new Date()} name='payment_date' /></div>
																			<div className="td price"><div className="currencysym">&pound;</div><ControlledInput className="form-control" value={currencyFormat(newPayment.payment_amount, "numbers") > 0 ? currencyFormat(newPayment.payment_amount, "numbers") : currencyFormat(invoice_outstanding, "numbers")} name="payment_amount" type="text" inputMode="numeric" step="any" placeholder="Payment Amount" onChange={this.handlePaymentData} required autoComplete="off" /></div>
																			<div className="td">
																				<select name="payment_method" className={'form-control ' + (this.state.newPaymentErrors.includes("payment_method") ? ' error' : ' ')} onChange={this.handlePaymentData} value={newPayment?.payment_method || ""} required>
																					<option>Please Choose</option>
																					{this.state.paymentMethods?.map((method, i) => {
																						return <option key={i} value={method.id}>{method.displayed_as}</option>
																					})}
																				</select>
																			</div>
																			<div className="td">
																				<select name="bank_account" className={'form-control ' + (this.state.newPaymentErrors.includes("bank_account") ? ' error' : ' ')} onChange={this.handlePaymentData} value={newPayment?.bank_account || ""} required>
																					<option>Please Choose</option>
																					{this.state.bankAccounts?.map((bankaccount, i) => {
																						return <option key={i} value={bankaccount.displayed_as}>{bankaccount.displayed_as}</option>
																					})}
																				</select>
																			</div>
																			<div className="td"><input type="text" name="payment_reference" value={newPayment?.payment_reference || ""} className={'form-control ' + (this.state.newPaymentErrors.includes("payment_reference") ? ' error' : ' ')} placeholder="Payment Reference" onChange={this.handlePaymentData} /></div>
																			{this.state.addingPayment && <div className="themebutton">Submitting Payment.. Please Wait</div>}
																			{!this.state.addingPayment && <button className="themebutton" type="button" onClick={this.addPayment}><MdOutlinePlaylistAdd />Add Payment</button>}

																		</div>
																		: null
																}

															</div>
														</div>

														<h3 style={{ textAlign: 'right', fontSize: '20px' }}>Total Paid: {currencyFormat(total_paid)}</h3>
													</>
												}

												<div>
													{
														!this.state.loading && invoice &&
														<div className="form_btns">
															{
																invoice._id == 0 && invoiceAmount > 0 && <button className="themebutton" onClick={this.raiseInvoice}>Raise Invoice</button>
															}
															{
																invoice._id !== 0 &&
																<>
																	<ReactToPrint trigger={() => { return <button type="button" disabled={this.context.saving} className="themebutton print"><AiFillPrinter />Print</button> }} content={() => this.componentRef} />
																	<div style={{ display: 'none' }}><PrintQuote ref={el => (this.componentRef = el)} invoice={this.state.invoice} /></div>

																	<button type="button" onClick={this.emailInvoice} className="themebutton email"><SiMinutemailer />Email Invoice</button>
																	{(invoice.status != "VOID") ?
																		<>
																			{invoice.total_outstanding > 0 && 1 == 2 &&
																				<>
																					<button className="themebutton print" type="button" onClick={e => this.openPaymentWindow(invoice)} target="_blank"><BsCashCoin />Take A Payment</button>
																				</>
																			}
																			{
																				editable &&
																				<>
																					<button className="themebutton update_invoice" onClick={this.updateInvoice}><GrDocumentUpdate />Update Invoice</button>
																					<button className="themebutton cancel" onClick={e => this.setState({ ...this.state, voiding: true })}><MdCancelScheduleSend /> Void Invoice</button>
																				</>

																			}


																		</>
																		:
																		null}
																</>

															}
														</div>



													}
												</div>

											</>
										}


										{this.renderRecordPayment()}
									</>}

							</Popup>
						}
					</>
				}

			</>
		)
	}
}