import { Component } from 'react';
import Flatpickr from "react-flatpickr";
import { AiFillSave } from 'react-icons/ai';

import { EventContext } from 'contexts/EventContext';
import ViewDetails from './ViewDetails';
import Popup from 'components/Popup/Popup';
import UserSearh from 'components/UserSearch/UserSearch';
import CustomerSearch from 'components/CustomerSearch/CustomerSearch';

import './EventPopup.scss';

export default class EventPopup extends Component {
	static contextType = EventContext;

	constructor(props){
		super(props)
		this.state = {
			event: {
				eventType: '',
				user: {},
				customer: {},
				eventTitle: '',
				details: '',
				date: '',
				emailReminder: false,
				smsReminder: false,
				...this.props.event,
			},
			loading: false,
			mode: this.props.mode,
		}
	}

	handleSubmit = async (e) => {
		e.preventDefault()

		let form = e.target.closest('form')
		let event = {...this.state.event}

		// Read the form Values
		Object.keys(this.state.event).forEach(key => {
			if(form[key]){
				event[key] = form[key]?.value
			}
		})
		event.smsReminder = form['smsReminder'].checked
		event.emailReminder = form['emailReminder'].checked

		

		this.setState({...this.state, event, loading: true})
		let res = await this.context.createUpdateEvent(event)
		this.setState({...this.state, loading: false, event: res})
	}

	selectedUser = user => {
		this.setState({...this.state, event: {...this.state.event, user: user}})
	}
	selectedCustomer = customer => {
		this.setState({...this.state, event: {...this.state.event, customer: customer}})
	}
	editMode = ()=>{
		this.setState({...this.state, mode: 'edit'})
	}

	render() {
		return (
			<Popup {...this.props} className="event_popup">
				{this.state.mode == 'view' ?
					<ViewDetails edit={this.editMode} event={this.state.event} />
				:
					<form method="post" className="create_event_form ">
						
						<h3>{this.state.event._id ? 'Edit':'Create a New'} Event</h3>
						<div className="bi_columns">
							<div className="event_details">
								<label>
									<span>Event Type</span>
									<select name="eventType" defaultValue={this.state.event.eventType} required>
										<option value="" disabled>Select Event Type</option>
										<option value="Measure">Measure</option>
										<option value="Fitting">Fitting</option>
										<option value="Other">Other</option>
									</select>
								</label>
								<label>
									<UserSearh name="userName" initialValue={this.state.event.user} select={this.selectedUser} />
								</label>
								<label>
									<span>Event Title</span>
									<input type="text" autoComplete="off" name="eventTitle" placeholder="Event Title" defaultValue={this.state.event.eventTitle} required />
								</label>
								<label>
									<span>Date & Time</span>
									<Flatpickr
										options={{
											altInput: true,
											altFormat: "M, D j, Y h:i K",
											dateFormat: "Z",
											enableTime: true,
											required: true,
											allowInput: false
										}}
										name="date"
										value={this.state.event.date}
										onChange={([val]) => this.setState({...this.state, event: {...this.state.event, date: val}})}
										required
									/>
								</label>
								<label className="checkbox_label">
									<input type="checkbox" name="smsReminder" placeholder="SMS Reminder" defaultChecked={this.state.event.smsReminder} />
									<span>SMS Reminder</span>
								</label>
								<label className="checkbox_label">
									<input type="checkbox" name="emailReminder" placeholder="Email Reminder" defaultChecked={this.state.event.emailReminder} />
									<span>Email Reminder</span>
								</label>
								
								<label>
									<span>Event Description</span>
									<textarea name="details" placeholder="Event's Description" defaultValue={this.state.event.details} required />
								</label>
							</div>

							<div className="event_customer_details">
								<label>
									<CustomerSearch name="customerName" initialValue={this.state.event.customer} getFullInfo={true} select={this.selectedCustomer} />
								</label>
								<label>
									<span>Email</span>
									<input type="text" autoComplete="off" name="email" placeholder="Email" value={this.state.event.customer.email} disabled/>
								</label>
								<label>
									<span>Phone</span>
									<input type="text" autoComplete="off" name="phone" placeholder="Phone" value={this.state.event.customer.phone} disabled/>
								</label>
								<label>
									<span>Town</span>
									<input type="text" autoComplete="off" name="town" placeholder="Town" value={this.state.event.customer.town} disabled/>
								</label>
								<label>
									<span>City</span>
									<input type="text" autoComplete="off" name="city" placeholder="City" value={this.state.event.customer.city} disabled/>
								</label>
								<label>
									<span>Postal Code</span>
									<input type="text" autoComplete="off" name="postCode" placeholder="Post Code" value={this.state.event.customer.postCode} disabled/>
								</label>
								<label>
									<span>Address Line 1</span>
									<input type="text" autoComplete="off" name="address1" placeholder="Address Line 1" value={this.state.event.customer.address1} disabled/>
								</label>
								<label>
									<span>Address Line 2</span>
									<input type="text" autoComplete="off" name="address2" placeholder="Address line 2" value={this.state.event.customer.address2} disabled/>
								</label>
							</div>

						</div>

						<div className="submit_bitton_wrapper">
							<button type="button" onClick={this.handleSubmit} disabled={this.state.loading}><AiFillSave /> {this.state.event._id ? 'Update':'Create'}</button>
						</div>

					</form>
				}
			</Popup>
		)
	}
}