import { Component } from 'react';
import { QuoteContext } from 'contexts/QuoteContext';
import Wrapper from 'pages/Wrapper';

import { tillTimeString, daysoverdue_str, daysoverdue, getInvoiceStatus } from 'utils/utils.jsx';

import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import FadingBalls from "react-cssfx-loading/lib/FadingBalls";
import { currencyFormat, dateFormat } from "utils/utils";
import InvoicePopup from 'components/InvoicePopup/InvoicePopup'

import './Invoices.scss';

export default class Invoices extends Component {
	static contextType = QuoteContext;

	constructor(props) {
		super(props)

		let invoiceid = new URLSearchParams(window.location.search).get('id')

		this.state = {
			sort: {
				key: 'createdDate',
				desc: true
			},
			showPopup: false,
			invoice: null,
			loading: false,
			load: true,
			quote: null,
			searchFilter: '',
			showinvoiceid: invoiceid,
			selectedInvoice: { lines: [], },
			mode: 'view',
		}

		this.columnNames = {
			invoice_number: 'Invoice #',
			contact_name: 'Customer',
			total_amount: 'Invoice Amount',
			total_paid: 'Amount Paid',
			outstanding: 'Amount Outstanding',
			status: 'Status',
			createdAt: 'Date Created',
			emailed_date: 'Date Sent',
			viewed_date: 'Date Viewed',
			due_date: 'Date Due',
		}
	}

	sort = (key) => {

		this.setState({ ...this.state, sort: { key, desc: !this.state.sort.desc } })
		this.context.sortInvoices(key, !this.state.sort.desc)
	}


	hidePopup = () => {

		this.setState({ ...this.state, invoice: null })
	}

	getTotalPrice = (quotejob) => {

		return quotejob.quotePriceTotal;
	}

	editMode = () => {
		this.setState({ ...this.state, mode: 'edit' })
	}

	async componentDidMount() {
		document.title = 'Invoices';
		if (this.state.load && !this.state.loading) {
			this.setState({ ...this.state, loading: true })
			this.context.getInvoices(false, true);
			this.setState({ ...this.state, load: false, loading: false })
		}

		let invoiceid = new URLSearchParams(window.location.search).get('id');

		invoiceid = invoiceid ? invoiceid : null;
		if (invoiceid) {
			this.setState({ ...this.state, load: true, invoice: { _id: invoiceid } })
		}
	}

	InvoiceTD(invoice) {

		let overdue = daysoverdue(invoice.due_date) > 0 ? "overdue" : "";
		let status = invoice.status;

		return (
			<>
				<div className="td cursor_pointer">
					{invoice.displayed_as}
				</div>
				<div className="td cursor_pointer" >
					{invoice.contact_name}
				</div>
				<div className="td cursor_pointer" >
					{currencyFormat(invoice.total_amount)}
				</div>

				<div className="td cursor_pointer" >
					{currencyFormat(invoice.total_paid)}
				</div>

				<div className="td cursor_pointer" >
					{currencyFormat(invoice.total_amount - invoice.total_paid)}
				</div>

				<div className="td cursor_pointer">
					<span className={'status ' + status.toLowerCase().replace(" ", "_")}>{status.replace("_", " ")}</span>
				</div>

				<div className="td cursor_pointer">
					{dateFormat(invoice.createdAt, "nice")}
				</div>

				<div className="td cursor_pointer">
					{dateFormat(invoice.emailed_date, "nice")}
				</div>

				<div className="td cursor_pointer">
					{dateFormat(invoice.viewed_date, "nice")}
				</div>
				<div className={'td cursor_pointer ' + overdue}>
					{dateFormat(invoice.due_date, "nice")}
					<br /> {daysoverdue(invoice.due_date, true)}
				</div>
			</>
		)
	}

	searchInvoices = (invoiceStatus) => {
		this.setState({ ...this.state, invoiceStatus })
	}

	render() {

		let invoices = this.context.invoices;

		let params = new URLSearchParams(window.location.search)

		if (this.state.searchFilter) {
			invoices = invoices?.filter(invoice => {

				let filter = this.state?.searchFilter?.toLowerCase()
				let str = `"${invoice?.contact_name}" "${invoice?.contact_reference}" "${invoice?.delivery_address_free_form}" "${invoice?.displayed_as}" "${invoice?.invoice_number}" "${invoice?.notes}" "${invoice?.terms_and_conditions}" "${invoice?.status?.displayed_as}"`
				invoice?.invoice_lines?.forEach(line => { str += `"${line.description}" ` })
				let regex = new RegExp(`(${filter})`, 'i')
				return regex.test(str)
			})
		}

		return (
			<Wrapper>
				{
					this.state.loading &&
					<>
						<div className="preloader" style={{ height: '40px' }}><FadingBalls color="#dd4477" /></div>
					</>
				}
				{!this.state.loading &&
					<>
						{this.state.invoice !== null && <>
							<InvoicePopup invoice={this.state.invoice} load="true" hide={this.hidePopup} />
						</>}

						<div id="jobs_page_container">
							<div className="jobs_page_header">
								<h2>Invoices</h2>
								<input type="text" placeholder="Start typing to filter invoices..." onInput={e => this.setState({ ...this.state, searchFilter: e.target.value })} className="filter_search" />
							</div>
							{1 == 2 ?
								<div className="jobs_page_header">
									<span className="button status PAID" onClick={e => this.searchInvoices("Paid")}>Paid</span>
									<span className="button status UNPAID" onClick={e => this.searchInvoices("unPaid")}>UnPaid</span>
									<span className="button status OVERDUE" onClick={e => this.searchInvoices("overDue")}>Over Due</span>
									<span className="button status VOID" onClick={e => this.searchInvoices("Void")}>Void</span>
								</div>
								: ""}

							<>
								{invoices?.length != 0 ?
									<>
										<div className="Jobs_table">
											<div className="table invoices">
												<div className="table_headings">
													{Object.keys(this.columnNames).map((key) => {
														return (
															<div key={key} className="th sortable_th" onClick={e => this.sort(key)}>
																{this.columnNames[key]}&nbsp;
																{this.state.sort.key == key ?
																	this.state.sort.desc ? <GoTriangleDown /> : <GoTriangleUp />
																	:
																	''
																}
															</div>
														)
													})}
													<div className="th"></div>
												</div>

												<div className="table_body">
													{Array.isArray(invoices) && invoices.map((invoice, index) => {
														return (
															<div className="tr" key={index} onClick={e => this.setState({ ...this.state, load: true, invoice: { _id: invoice._id } })}>
																{this.InvoiceTD(invoice)}
															</div>
														)
													})}
												</div>
											</div>
										</div>
										{(this.context.nextInvoicesPage && !this.context.loadingMore) && <>
											<button className="load_more_invoices_btn" onClick={e => this.context.loadInvoices(false)}>Load more</button>
										</>}
										{this.context.loadingMore && <>
											<button className="load_more_invoices_btn" disabled={true}> Loading... </button>
										</>}
									</>
									:
									<h3>No records found.</h3>}
							</>
						</div>
					</>
				}
			</Wrapper>
		)
	}
}
