import { Component } from 'react';
import { QuoteContext } from 'contexts/QuoteContext';
import FadingBalls from "react-cssfx-loading/lib/FadingBalls";
import InvoicePopup from 'components/InvoicePopup/InvoicePopup'
import { currencyFormat, dateFormat, getInvoiceStatus, getTotalQuoteInvoiced, getTotalCredited, getTotalQuotePaid } from "utils/utils";
import { BiBookAdd } from 'react-icons/bi';

import { GoLinkExternal } from 'react-icons/go';


export default class InvoicesTable extends Component {
	static contextType = QuoteContext;

	constructor(props) {
		super(props)

		this.state = {
			load: this.props.load ? (this.props.load == "false" ? false : true) : true,
			loading: false,
			quote: this.props.quote ? this.props.quote : { invoices: [] }
		}
	}

	openInvoice = (invoice) => {
		this.setState({ ...this.state, invoice })
	}


	async componentDidMount() {

		if (this.state.load && !this.state.loading) {
			this.setState({ ...this.state, loading: true })
			let quote = await this.context.getQuote(this.state.quote._id);
			this.setState({ ...this.state, quote, load: false, loading: false })
		}
	}

	hideCreateInvoice = () => {
		this.setState({ ...this.state, invoice: null, showInvoicePopup: false })
	}

	render() {

		let quote = this.context.quote;
		console.log("invoicestable with quote ", quote);
		let invoices = quote.invoices ? quote.invoices : [];

		let totalinvoiced = getTotalQuoteInvoiced(invoices)
		let totalpaid = getTotalQuotePaid(invoices);
		let total_credited = getTotalCredited(quote);

		let total_outstanding = +totalinvoiced - +totalpaid + +total_credited;
		//let invoice = this.state.invoice ? this.state.invoice : null;

		return (
			<>
				{
					this.state.loading &&
					<div className="preloader" style={{ height: '40px' }}><FadingBalls color="#dd4477" /></div>
				}

				{!this.state.loading && !this.state.load &&
					<>
						{this.state.invoice && <InvoicePopup zindex={this.state.zindex + 1} invoice={{ ...this.state.invoice, quote }} hide={() => this.setState({ ...this.state, invoice: null })} />}

						<div className="quote_notes">
							<div className="quote_notes_list_header list_section_header">
								<h4>Invoices ({invoices?.length})</h4>
								{quote.status != 'cancelled' &&
									<button type="button" onClick={e => this.setState({ ...this.state, invoice: { _id: 0, quote } })} disabled={this.context.saving} className="add_to_list_btn"><BiBookAdd />Add Invoice</button>
								}
							</div>
							<div className="quote_notes_list">
								<div className="related_invoices_summery">
									<div>Job Price: {currencyFormat(quote.quotePriceTotal)}</div>
									<div>Invoiced: {currencyFormat(totalinvoiced)}</div>
									<div>Paid: {currencyFormat(totalpaid)}</div>
									<div>Credited: {currencyFormat(total_credited)}</div>
									<div>Outstanding: {currencyFormat(total_outstanding)}</div>
								</div>

								<div className="table related_invoices">
									<div className="table_headings">
										<div className="th">Invoice #</div>
										<div className="th">Total Items</div>
										<div className="th">Status</div>
										<div className="th">Amount</div>
										<div className="th">Paid</div>
										<div className="th">Raised Date</div>
										<div className="th">Last Sent</div>
										<div className="th">Last Viewed</div>
									</div>
									<div className="table_body">
										{invoices?.map(invoice => {
											let status = getInvoiceStatus(invoice);

											return (
												<div className="tr" key={invoice._id}>
													<div className="td cursor" onClick={e => this.openInvoice(invoice)} >{invoice.displayed_as}</div>
													<div className="td cursor" onClick={e => this.openInvoice(invoice)} >{invoice.invoice_lines?.length}</div>
													<div className="td cursor" onClick={e => this.openInvoice(invoice)} >
														<span className={status.replace(" ", "").toLowerCase() + ' status'}>{status}</span>
													</div>
													<div className="td cursor" onClick={e => this.openInvoice(invoice)} >{currencyFormat(invoice.total_amount)}</div>
													<div className="td cursor" onClick={e => this.openInvoice(invoice)} >{currencyFormat(invoice.total_paid)}</div>
													<div className="td cursor" onClick={e => this.openInvoice(invoice)} >{dateFormat(invoice.createdAt, "nice")}</div>
													<div className="td cursor" onClick={e => this.openInvoice(invoice)} >{dateFormat(invoice.emailed_date, "nice")}</div>
													<div className="td cursor" onClick={e => this.openInvoice(invoice)} >{dateFormat(invoice.viewed_date, "nice")}</div>
													<div className="td cursor" onClick={() => window.open(`/invoice?id=${invoice._id}`)}  ><div className="icon_btns" ><GoLinkExternal /></div></div>
												</div>
											)
										})}
									</div>
								</div>

							</div>
						</div>

					</>
				}
			</>
		)


	}
}