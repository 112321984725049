import { Component } from 'react';
import { DashboardContext } from 'contexts/DashboardContext';
import { FaDownload } from "react-icons/fa";
import Popup from 'components/Popup/Popup';
import FadingDots from "react-cssfx-loading/lib/FadingDots";
import { currencyFormat, dateFormat, infocustomeradd } from "utils/utils";

import './Reports.scss';

export default class Reports extends Component {
	static contextType = DashboardContext;

	state = {
		report_from: this.props.report_from || new Date(),
		report_to: this.props.report_to || new Date(),
		report_type: this.props.report_type || "financials",
		load: true,
		loading: false
	}

	async componentDidMount() {
		if (this.state.load && !this.state.loading) {
			this.setState({ ...this.state, loading: true });
			let reports = await this.context.loadReports(this.state.report_from, this.state.report_to, this.state.report_type);
			this.setState({ ...this.state, reports, loading: false, load: false });
		}

	}

	componentWillUnmount() {
		this.context.unloadReports()
	}

	report = () => {
		let report_from = this.state.report_from;
		let report_to = this.state.report_to;
		let report_type = this.state.report_type;
		if (this.state.reports) {


			if (report_type == "financials") {
				let invoices = this.state.reports.invoices;
				let creditnotes = this.state.reports.creditnotes;
				let outstanding = this.state.reports.outstanding;

				let totalpayments = this.state.reports.totalpayments || 0;
				let totaloutstanding = this.state.reports.totaloutstanding || 0;
				let totalcredits = this.state.reports.totalcredits || 0;
				let grandtotal = currencyFormat(totalpayments - totalcredits);

				return (
					<>
						Total Payments : {currencyFormat(totalpayments)}<br />
						Total Credits : {currencyFormat(totalcredits)}<br />
						Total: {grandtotal}<br /><br />
						Total Outstanding : {currencyFormat(totaloutstanding)}<br />
						<div className='reports_payments'>
							<h3>Payments</h3>
							<div className="table_headings">
								<div className="th">Job ID</div>
								<div className="th">Invoice ID</div>
								<div className="th">Customer</div>
								<div className="th">Payments</div>
								<div className="th">Total Paid</div>
							</div>

							<div className="table_body">
								{invoices.map((invoice, index) => (
									<div className="tr" key={index}>
										<div className="td">{invoice.quote?.numericID}</div>
										<div className="td">{invoice.displayed_as}</div>
										<div className="td">{invoice.contact_name}</div>
										<div className="td">{invoice.payments_count}</div>
										<div className="td">{currencyFormat(invoice.total_payments)}</div>
									</div>
								))}
							</div>
						</div>
						<div className='reports_outstanding'>
							<h3>Outstanding</h3>
							<div className="table_headings">
								<div className="th">Job ID</div>
								<div className="th">Invoice ID</div>
								<div className="th">Customer</div>
								<div className="th">Date Due</div>
								<div className="th">Total Paid</div>
								<div className="th">Outstanding Amount</div>

							</div>

							<div className="table_body">
								{outstanding.map((invoice, index) => (
									<div className="tr" key={index}>
										<div className="td">{invoice.quote?.numericID}</div>
										<div className="td">{invoice.displayed_as}</div>
										<div className="td">{invoice.contact_name}</div>
										<div className="td">{dateFormat(invoice.due_date, "nice")}</div>
										<div className="td">{currencyFormat(invoice.total_payments)}</div>
										<div className="td">{currencyFormat(invoice.total_outstanding)}</div>
									</div>
								))}
							</div>
						</div>
						<div className='reports_creditnotes'><br />
							<h3>Credit Notes</h3>
							<div className="table_headings">
								<div className="th">Job ID</div>
								<div className="th">Customer</div>
								<div className="th">Total Credit</div>
							</div>

							<div className="table_body">
								{creditnotes.map((creditnote, index) => (
									<div className="tr" key={index}>
										<div className="td">{creditnote.quote.numericID}</div>
										<div className="td">{creditnote.contact_name}</div>
										<div className="td">{currencyFormat(creditnote.total_amount)}</div>
									</div>
								))}
							</div>
						</div>
					</>
				)

			}

		}

	}

	downloadCSVs = async (reports) => {
		this.downloadpayments();
		this.downloadcreditnotes();
	}

	downloadpayments = async (reports) => {
		let invoices = this.state.reports.invoices;
		let creditnotes = this.state.reports.creditnotes;
		let totalpayments = this.state.reports.totalpayments || 0;
		let totalcredits = this.state.reports.totalcredits || 0;
		let grandtotal = currencyFormat(totalpayments - totalcredits);
		let csv = [];
		invoices.forEach(invoice => {
			let payments = invoice.payments;
			let paymentscount = payments.length;
			let i = 0;
			return payments.map(payment => {
				i++;
				csv.push(
					{
						'Job ID': invoice.quote.numericID,
						'Invoice ID': invoice.displayed_as,
						'Customer': invoice.contact_name,
						'Invoiced Date': dateFormat(invoice.createdAt, "Y-m-d H:i:s") || '(empty)',
						'Payment Date': dateFormat(payment.payment_date, "Y-m-d H:i:s") || '(empty)',
						'Payment Number': i + ' ' + paymentscount || '(empty)',
						'Payment Amount': currencyFormat(payment.payment_amount)
					}
				)
			});
		})

		const csvContent = "data:text/csv;charset=utf-8," + Object.keys(csv[0]).map(key => key + ',').join('') + '\n' + csv.map(row => Object.values(row).map(value => '"' + value + '",').join('')).join('\n');

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", "payments.csv");
		document.body.appendChild(link); // Required for FF

		link.click();
	}

	downloadoutstandingpayments = async (reports) => {
		let outstanding = this.state.reports.outstanding;

		let totalpayments = this.state.reports.totalpayments || 0;
		let totalcredits = this.state.reports.totalcredits || 0;

		let csv = [];
		outstanding.forEach(invoice => {
			let payments = invoice.payments;
			let paymentscount = payments.length;
			let i = 0;
			return payments.map(payment => {
				i++;
				csv.push(
					{
						'Job ID': invoice.quote.numericID,
						'Invoice ID': invoice.displayed_as,
						'Customer': invoice.contact_name,
						'Invoiced Date': dateFormat(invoice.createdAt, "Y-m-d H:i:s") || '(empty)',
						'Payment Date': dateFormat(payment.payment_date, "Y-m-d H:i:s") || '(empty)',
						'Payment Number': i + ' ' + paymentscount || '(empty)',
						'Payment Amount': currencyFormat(payment.payment_amount)
					}
				)
			});
		})


		const csvContent = "data:text/csv;charset=utf-8," + Object.keys(csv[0]).map(key => key + ',').join('') + '\n' + csv.map(row => Object.values(row).map(value => '"' + value + '",').join('')).join('\n');

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", "payments.csv");
		document.body.appendChild(link); // Required for FF

		link.click();
	}

	downloadcreditnotes = async (reports) => {

		let creditnotes = this.state.reports.creditnotes;
		let totalpayments = this.state.reports.totalpayments || 0;
		let totalcredits = this.state.reports.totalcredits || 0;
		let grandtotal = currencyFormat(totalpayments - totalcredits);
		let csv = [];
		creditnotes.map(payment => {

			csv.push(
				{
					'Job ID': payment.quote.numericID,
					'Customer': payment.contact_name,
					'Credit Note ID': payment.displayed_as,
					'Credit Note Date': dateFormat(payment.date, "Y-m-d H:i:s") || '(empty)',
					'Payment Amount': currencyFormat(payment.total_amount)
				}
			)
		});


		const csvContent = "data:text/csv;charset=utf-8," + Object.keys(csv[0]).map(key => key + ',').join('') + '\n' + csv.map(row => Object.values(row).map(value => '"' + value + '",').join('')).join('\n');

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", "creditnotes.csv");
		document.body.appendChild(link); // Required for FF

		link.click();
	}
	render() {

		return (
			<Popup {...this.props} className={this.props.className + ' reports_popup'}>
				<div className="all_messages_popup">
					<div className="reports_heading_heading">
						<h2>Invoice Reports</h2><br />
						<h4>{dateFormat(this.state.report_from, "nice") + " - " + dateFormat(this.state.report_to, "nice")}</h4>
						<FaDownload onClick={e => this.downloadCSVs(this.context.reports)} />
					</div>
					{this.state.loading ?
						<div className="preloader"><FadingDots /></div>
						:
						<div className="table reports">
							{this.report()}

						</div>}

				</div>
			</Popup>
		)
	}
}