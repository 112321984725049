import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { toastOptions, sort, currencyFormat } from 'utils/utils.jsx';

const CreditNoteContext = React.createContext();

class CreditNoteContextProvider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			saving: false,
			
			nextPage: 1,
			loadingMore: false,
			
			copyLink: this.copyLink,
			
			getPublicCreditNote: this.getPublicCreditNote,
			
			

			updateLoadedCreditNoteData: this.updateLoadedCreditNoteData,
			sort: this.sort,
			
		}
	}

	componentDidMount(){
		// this.loadCreditNotes()
		// console.log(this)
	}

	copyLink = (id) => {
		navigator.clipboard.writeText(`${window.location.origin}/credit_note?id=${id}`);
		toast.success("Link Copied!")
   	}

	
	


	api_error  = () => {
		if( window.confirm('There were some error loading this page. Please refresh') ){
			window.location.reload()
		}
	}

	


	sort = (sortKey, desc) => {

		let creditNotes = sort(this.state.creditNotes, sortKey, desc)
		// console.log({sortKey, creditNotes})
		this.setState({...this.state, creditNotes})
	}


	

	
	updateLoadedCreditNoteData = (data) => {
		let creditNotes = this.state.creditNotes.map(creditNote => {
			return creditNote.sageID == data.sageID ? data : creditNote;
		})
		this.setState({...this.state, creditNotes})
	}

	
	

	render() {
		return (
			<CreditNoteContext.Provider value={this.state}>
				{this.props.children}
			</CreditNoteContext.Provider>
		);
	}
}

export { CreditNoteContext, CreditNoteContextProvider };
