import { Component } from 'react';
import { RiCloseCircleFill } from 'react-icons/ri';

import './Popup.scss';
import zIndex from '@mui/material/styles/zIndex';

export default class Popup extends Component {

	render() {
		return (
			<div id="popup-container-main" style={{zIndex: this.props.zindex}} className={this.props.className}>
				<div className="hidding_layout" /* onClick={this.props.hide} */></div>
				<div className="popup_main_content">

					<div className="popup_contents_outer">
						<button className="close_popup" onClick={this.props.hide}><RiCloseCircleFill /></button>
						<div className="popup_contents_inner">
							{this.props.children}
						</div>
					</div>
				</div>
			</div>

		)
	}
}