import { Component } from 'react';
import { AiFillSave } from 'react-icons/ai';

import { UserContext } from 'contexts/UserContext';
import Popup from 'components/Popup/Popup';

import './UserPopup.scss';

export default class UserPopup extends Component {
	static contextType = UserContext;

	constructor(props) {
		super(props)
		this.state = {
			user: {
				fname: '',
				lname: '',
				username: '',
				email: '',
				role: '',
				status: '',
				password: '',
				...this.props.user,
			},
			resetPass: false,
			loading: false,
		}
	}

	handleSubmit = async (e) => {
		e.preventDefault()

		let form = e.target
		let user = { ...this.state.user }

		if (!this.state.user._id && form['password'] && form['password']?.value !== form['confirm_password']?.value) {
			form.reportValidity()
			alert('Passwords do not match')
			return
		}
		// Read the form Values
		Object.keys(this.state.user).forEach(key => {
			if (form[key]) {
				user[key] = form[key]?.value
			}
		})
		user.username = user.username.replace(/[^a-zA-Z0-9\.\_]/g, '')
		// console.log(user)
		// return

		this.setState({ ...this.state, user, loading: true })
		let res = await this.context.createUpdateUser(user)
		this.setState({ ...this.state, loading: false, user: { ...user, ...res } })
		if (res._id) {
			this.props.hide()
		}
	}
	preventSubmission = (e) => {
		if (e.keyCode === 13 && e.target.tagName == 'INPUT') {
			e.preventDefault()
		}
	}

	resetPass = e => {
		this.setState({ ...this.state, resetPass: e.target.checked })
	}

	render() {
		return (
			<Popup {...this.props} className="user_popup">
				<form method="post" onSubmit={this.handleSubmit} onKeyDown={this.preventSubmission} className="create_user_form " autoComplete="off" >

					<h3>{this.state?.user?._id ? 'Edit' : 'Create a New'} User</h3>
					<div className="bi_columns">
						<label>
							<span>First Name</span>
							<input type="text" autoComplete="off" name="fname" placeholder="User's First Name" defaultValue={this.state.user.fname} />
						</label>
						<label>
							<span>Last Name</span>
							<input type="text" autoComplete="off" name="lname" placeholder="User's Last Name" defaultValue={this.state.user.lname} />
						</label>
					</div>

					<div className="bi_columns">
						<label>
							<span>Email</span>
							<input type="email" required={true} name="email" placeholder="User's Email Address" defaultValue={this.state.user.email} />
						</label>
						<label>
							<span>Username<small>(can only contain alphanumeric characters and . or _)</small></span>
							<input type="text" autoComplete="off" required={true} name="username" placeholder="User's Username" defaultValue={this.state.user.username} />
						</label>
					</div>

					{!this.state.user._id &&
						<div className="bi_columns">
							<label>
								<span>Password</span>
								<input type="password" required={true} name="password" placeholder="Password" defaultValue='' />
							</label>
							<label>
								<span>Confirm Password</span>
								<input type="password" required={true} name="confirm_password" placeholder="Confirm Password" defaultValue='' />
							</label>
						</div>}

					<div className="bi_columns">
						<label>
							<span>User Role</span>
							<select name="role" defaultValue={this.state.user.role} required={true} >
								<option value="" disabled={true}>--- Select a Role ---</option>
								<option value="admin">Admin</option>
								<option value="operator">Operator</option>
							</select>
						</label>
						<label>
							<span>User Status</span>
							<select name="status" defaultValue={this.state.user.status} required={true} >
								<option value="" disabled={true}>--- Select User Status ---</option>
								<option value="active">Active</option>
								<option value="inactive">Inactive</option>
							</select>
						</label>
					</div>
					{this.state.user._id && <>
						<div className="bi_columns">
							<label className="reset_password_label">
								<input type="checkbox" name="reset_password" placeholder="Password" onInput={this.resetPass} />
								<span> Change Password</span>
							</label>
							{this.state.resetPass && <>
								<label>
									<span>New Password</span>
									<input type="password" required={true} name="password" placeholder="Password" defaultValue='' />
								</label>
							</>}
						</div>
					</>}

					<div className="submit_bitton_wrapper">
						<button disabled={this.state.loading}><AiFillSave /> {this.state.user._id ? 'Update' : 'Create'} User</button>
					</div>

				</form>
			</Popup>
		)
	}
}