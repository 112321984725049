import { Component } from 'react';
import { CustomerContext, CustomerContextProvider } from 'contexts/CustomerContext';
import FadingBalls from "react-cssfx-loading/lib/FadingBalls";
import CustomerPopup from 'components/CustomerPopup/CustomerPopup';
import { HiUserAdd } from 'react-icons/hi';

import './CustomerSearch.scss';

// Context provider wrapper
export default class CustomerSearch extends Component {
	render() {
		return <CustomerContextProvider><CustomerSearchCompnent {...this.props} /></CustomerContextProvider>
	}
}
class CustomerSearchCompnent extends Component {
	static contextType = CustomerContext;

	constructor(props) {
		super(props)

		this.state = {
			customers: [],
			searchboxval: null,
			loading: true,
			selectedCustomer: null,
			showSearchResults: false,
			showAddCustomer: false,
		}
		this.timeout = null;

	}
	handleForceupdateMethod() {
		this.forceUpdate();
	};

	loadCustomers = (e) => {
		let searchval = e?.target?.value;
		this.setState({ ...this.state, searchboxval: searchval, showSearchResults: true });

		clearTimeout(this.timeout)
		this.timeout = setTimeout(async () => {
			this.setState({ ...this.state, loading: true })
			let customers = await this.context.searchCustomer(searchval, this.props.getFullInfo)
			this.setState({ ...this.state, customers, loading: false })
		}, 1500)
	}
	showCusSearch = (e) => {
		this.setState({ ...this.state, showSearchResults: true })
		this.loadCustomers({ target: { value: this.state.searchboxval || '' } })
	}
	hideCusSearch = () => {
		setTimeout(() => { this.setState({ ...this.state, showSearchResults: false }) }, 200)
	}
	select = cus => {

		this.setState({ ...this.state, selectedCustomer: cus, showSearchResults: false, searchboxval: cus.title ? cus.title + ' ' : '' + cus.fname + ' ' + cus.lname, loading: false })
		this.props.select(cus)
	}

	shouldComponentUpdate(nextProps, nextState) {

		if (nextProps.initialValue !== this.props.initialValue) {

			this.setState({ ...this.state, searchboxval: nextProps.initialValue })
			return true;
		}

		else if (nextState != this.state) {

			return true;
		}

		else {

			return false;

		}
	}


	hideAddCustomer = (e) => {

		this.setState({ ...this.state, showAddCustomer: false })
	}
	render() {

		let searchboxval = this.state.searchboxval;
		let selectedCustomer = this.state.selectedCustomer ? this.state.selectedCustomer : null;
		let initialValue = this.props.initialValue;

		let searchboxvalue = searchboxval ? searchboxval : selectedCustomer ? selectedCustomer.fname + ' ' + selectedCustomer?.lname : initialValue;

		return (
			<>
				{this.state.showAddCustomer && <CustomerPopup select={this.select} hide={this.hideAddCustomer} className="add_customer_not_found" />}
				<div className="search_customer">
					<label>
						<span>Customer</span>
						<input type="text" name={this.props.name} placeholder="Customer Name" value={searchboxvalue} onChange={this.loadCustomers} onFocus={this.showCusSearch} onBlur={this.hideCusSearch} required autofill="off" autoComplete="off" />
					</label>
					<span className="search_add_customer" onClick={e => this.setState({ ...this.state, showAddCustomer: true })}><HiUserAdd /></span>

					{this.state.showSearchResults &&
						<div className="group_focus">
							<div className="search_customer_list">
								{this.state.loading ? <div className="loading_customers">
									<FadingBalls />
								</div> :
									<>
										{!this.state.loading && this.state?.customers?.length == 0 &&
											<>
												<div className="search_customer_not_found">
													<span>No Customer Found </span>
													<span style={{ color: '#00688f', textDecoration: 'underline', cursor: 'pointer' }} onClick={e => this.setState({ ...this.state, showAddCustomer: true })}> Add new customer</span>
												</div>
											</>}
										{this.state?.customers?.map((cus) => {
											return (
												<div key={cus?._id} className="search_customer_list_item" onClick={e => this.select(cus)}>
													<span>{cus.title ? cus.title + ' ' : ''} {`${cus.fname} ${cus.lname} ${cus.company ? ` - ${cus.company}` : ``}`}</span>
													<div>
														<p>{`${cus.email ? 'Email: ' + cus.email : ''}`}</p>
														<p>{`${cus.phone ? 'Phone# ' + cus.phone : ''}`}</p>
														<p>{`${cus.postalCode ? 'Postal Code: ' + cus.postalCode : ''}`}</p>
														<p>{`${cus.address1 ? 'Address: ' + cus.address1 : ''}`}</p>
														<p>{`${cus.town ? 'Address: ' + cus.town : ''}`}</p>
													</div>
												</div>
											)
										})}
									</>
								}

							</div>
						</div>}
				</div>
			</>
		)
	}
}