import { Component, useRef } from 'react';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { currencyFormat, dateFormat, DateOptions, ControlledInput, defaultTCS, getVAT } from "utils/utils";

import { QuoteContext } from 'contexts/QuoteContext';
import { CustomerContextProvider } from 'contexts/CustomerContext';
import ViewDetails from './ViewDetails';
import CreditnotesTable from './CreditnotesTable';
import InvoicesTable from './InvoicesTable';
import Popup from 'components/Popup/Popup';
import PrintQuote from 'components/PrintQuote/PrintQuote';
import ReactToPrint from 'react-to-print';
import CustomerSearch from 'components/CustomerSearch/CustomerSearch';
import CustomerPopup from 'components/CustomerPopup/CustomerPopup';
import Loading from 'components/Loading/Loading'
import { InventoryContextProvider } from 'contexts/InventoryContext';

import InventorySearch from 'components/InventorySearch/InventorySearch.jsx'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


import { AiFillSave, AiOutlineDelete, AiFillPrinter, AiOutlineEdit } from 'react-icons/ai';
import { CgExtensionAdd } from 'react-icons/cg';
import { MdOutlineAddRoad, MdCancelScheduleSend } from 'react-icons/md';
import { BiBookAdd } from 'react-icons/bi';

import { SiConvertio, SiMinutemailer } from 'react-icons/si';
import FadingDots from "react-cssfx-loading/lib/FadingDots";
import './QuotePopup.scss';

export default class QuotePopup extends Component {
	static contextType = QuoteContext;

	constructor(props) {
		super(props)
		this.state = {
			quote: this.props.quote && this.props.quote._id !== 0 ? this.props.quote : {
				_id: 0,
				customer: this.props.quote.customer || '',
				customerName: '',
				description: '',
				showNotesOnInvoice: true,
				quotedPrice: '',
				accepted_date: this.props.quote.accepted_date ? this.props.quote.accepted_date : null, // is a job
				items: [{ qty: 1, taxrateid: "GB_STANDARD", indexno: Date.now() }],
				tcs: defaultTCS().both,
				jobs: [{}],
				notes: [{}],
				invoices: []
			},
			zindex: (this.props.zindex) ? this.props.zindex : 9,
			showInvoicePopup: false,
			showCreditNotePopup: false,
			mode: 'create',
			showCustomerSearch: false,
			invoiceAgain: false,
			loading: false,
			load: this.props.load ? this.props.load : true,
		}


		this.jobFields = {
			text: { areaArrived: 'Customer', supplier: 'Supplier', item: 'Item', size: 'Size' },
			date: { orderDate: 'Order Date', dueDate: 'Due Date', fitDate: 'Fit Date' }
		}

	}

	jobcomplete = async (e) => {
		let quote = { ...this.state.quote }
		quote.jobcomplete = true;
		let res = await this.context.addEditQuote(quote)
		if (res.res == 'success') {
			this.setState({ ...this.state, quote: res.quote ? res.quote : quote })
		}

		return
	}

	acceptquote = async (e) => {
		let quote = { ...this.state.quote }
		quote.quoteaccepted = true;
		quote.type = "job"
		let res = await this.context.addEditQuote(quote)
		if (res.res == 'success') {
			this.setState({ ...this.state, quote: res.quote ? res.quote : quote })
		}

		return
	}

	jobToQuote = async (e) => {
		let quote = { ...this.state.quote }
		quote.quoteaccepted = false;
		quote.accepted_date = null;
		quote.type = "quote"
		console.log("jobToQuote: ", quote);

		let res = await this.context.addEditQuote(quote)
		if (res.res == 'success') {
			this.setState({ ...this.state, quote: res.quote ? res.quote : quote })
		}

		return
	}

	editbtn = async (e) => {
		e.preventDefault()
		this.setState({ ...this.state, mode: 'edit' })
		return
	}

	addupdate = async (e) => {
		e.preventDefault()
		if (!e.target?.form?.checkValidity()) {
			e.target?.form?.reportValidity()
			return
		}

		let quote = { ...this.state.quote }
		let res = await this.context.addEditQuote(quote)
		if (res.res == 'success') {
			this.setState({ ...this.state, quote: res.quote ? res.quote : quote })
		}

		return
	}

	updateQuotedPrice = async (e) => {
		e.preventDefault()

		let quote = this.state.quote
		let val = parseFloat(e.target.value);
		let altPrice = val ? val : null;

		quote.altPrice = altPrice;

		this.context.setQuote(quote);

	}

	preventSubmit = (e) => {
		if (e.keyCode === 13 && e?.target?.nodeName === 'INPUT') {
			e.preventDefault()
		}
	}

	editMode = () => {
		this.setState({ ...this.state, mode: 'edit', invoiceAgain: true })
	}

	removeFromList = (listName, index) => {
		let quote = this.state.quote
		let list = quote[listName]
		if (!Array.isArray(list) || list?.length < index) {
			return
		}
		list.splice(index, 1)
		quote[listName] = list
		this.context.setQuote(quote);
	}

	addToList = (listName) => {

		let quote = this.context.quote ? this.context.quote : { loadquote: true };
		let list = quote[listName]

		if (!Array.isArray(list)) {
			list = [];
		}

		let indexno = Date.now();

		list.push({ price: 0, name: '', indexno: indexno })
		quote[listName] = list

		this.context.setQuote(quote);
	}

	handleInputValue = (e, listName, index) => {

		let quote = this.context.quote
		let list = quote[listName]
		let name = e.target.name;
		let val = (name == "qty" || name == "unit_price" || name == "line_total") ? parseFloat(e.target.value) : e.target.value;

		if (!Array.isArray(list) || !list[index]) {
			quote[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : val;

			this.context.setQuote(quote);
			return
		}

		list[index][e.target.name] = e.target.type === 'checkbox' ? e.target.checked : val;

		if (name == "unit_price" || name == "qty" || name == "taxrateid" || name == "line_total") {

			let thisitem = list[index];

			if (name == "taxrateid") {
				thisitem.taxrateid = val;
			}
			else if (name == "unit_price") {
				thisitem.unit_price = val;
				thisitem.price = val;
			}
			else if (name == "qty") {
				thisitem.qty = val;
			}
			else if (name == "line_total") {
				thisitem.total_amount = val;
			}

			list[index] = this.newItem(thisitem, name)
		}

		quote[listName] = list
		this.context.setQuote(quote);
	}


	handleDateInput = (value, name, listName, index) => {
		console.log("handling date input: ", name, value, listName, index)

		let newDate = new Date(value);
		//let newDate = value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate();

		console.log("new date: ", newDate)
		let quote = this.context.quote
		let list = quote[listName]

		if (!Array.isArray(list) || !list[index]) {
			quote[name] = newDate
			this.setState({ ...this.state, quote })
			return
		}

		list[index][name] = newDate
		quote[listName] = list
		this.setState({ ...this.state, quote })
	}

	selectedCustomer = customer => {
		let quote = this.context.quote
		quote.customer = customer

		this.setState({ ...this.state, quote })
	}

	deleteQuote = () => {
		this.context.deleteQuote(this.state.quote._id)
		this.props.hide()
	}

	cancelQuote = () => {
		this.context.cancelQuote(this.state.quote._id)
		this.props.hide()
	}

	sendQuote = async () => {
		let quote = await this.context.emailQuote(this.context.quote);

		this.setState({ ...this.state, quote, loading: false });
	}

	printQuote = () => {
		let fakeIFrame = window.document.createElement("iframe");
		const srcDoc = <PrintQuote quote={this.context.quote} />;

		document.body.appendChild(fakeIFrame);
		let fakeContent = fakeIFrame.contentWindow;
		fakeContent.document.open();
		fakeContent.document.src(ReactDOMServer.renderToString(srcDoc));
		fakeContent.document.close();
		fakeContent.focus();
		fakeIFrame.addEventListener("load", () => {
			fakeContent.print();
		});

	}

	selectInventoryItem = (item, index) => {
		console.log("item selected: ", item)
		let quote = this.context.quote
		let items = quote.items;

		let newItem = item;

		if (item._id) // chosen from the inventory list
		{
			newItem.name = item.itemname;
			newItem.itemid = item._id;
		}
		let thisitem = this.newItem(newItem, "");
		thisitem.indexno = items[index].indexno;


		items[index] = thisitem
		quote.items = items

		this.context.setQuote(quote);
	}

	newItem = (item, thischanged) => {
		console.log("item: ", item)
		let taxrateid = item.taxrateid || "GB_STANDARD";
		let qty = item.qty || 1;
		let unit_price = item.unit_price ? item.unit_price : item.price;
		let line_total = unit_price * qty;
		let tax_amount = getVAT(line_total, taxrateid, "add");
		let net_amount = unit_price * qty //line_total - tax_amount;

		if (thischanged == "line_total") {
			line_total = item.total_amount;
			tax_amount = getVAT(line_total, taxrateid, "minus");
			net_amount = line_total - tax_amount;
			unit_price = (net_amount) / qty
		}

		item.tax_amount = tax_amount
		item.qty = qty
		item.total_amount = (net_amount + tax_amount)
		item.taxrateid = taxrateid;
		item.net_amount = net_amount;
		item.unit_price = unit_price;

		return item;
	}

	onRTEchange = (newcontent) => {
		let quote = this.state.quote;
		quote.tcs = newcontent;

		this.setState({ ...this.state, quote })
		this.context.setQuote({ ...this.context.quote, tcs: newcontent })
	}

	createCreditNote = () => {
		this.setState({ ...this.state, showCreditNotePopup: true })
	}

	gotoQuote = async (e) => {
		if (e.target.value !== "") {
			this.setState({ ...this.state, loading: true })
			let quote = await this.context.getQuote(e.target.value)
			console.log("set current quote: ", quote)
			this.setState({ ...this.state, quote, loading: false });
			this.context.setQuote(quote);
		}

	}

	async componentDidMount() {
		if (this.state.load && !this.state.loading && this.state.quote._id !== 0) {
			this.setState({ ...this.state, loading: true })
			let quote = await this.context.getQuote(this.state.quote._id)
			this.setState({ ...this.state, quote, loading: false });
		}
		else if (this.state.quote._id == 0) {
			this.context.setQuote(this.state.quote);
		}
	}

	render() {

		let quote = this.state.quote._id !== 0 ? this.context.quote : this.state.quote;


		let invoices = this.context.quote.invoices;
		if (quote.loading) {
			return (
				<>
					<div className="popuploading"><FadingDots width="70px" height="70px" color="#62d3f1" /></div>
				</>
			)
		}
		if (!quote.loading) {

			let customer = quote.customer ? quote.customer : { title: "", fname: "", lname: "" };

			let quoteid = quote._id ? quote._id : null;

			let itemsTotalPrice = quote?.items?.reduce((total, item) => (item.total_amount ? (total += (Number(item.total_amount.toFixed(2)))) : total), 0)?.toFixed(2)
			let altPrice = quote.altPrice;

			let itemsTotalVAT = quote?.items?.reduce((vat, item) => (item.tax_amount ? (vat += Number(item.tax_amount)) : vat), 0)?.toFixed(2)

			let quotetype = quote.accepted_date ? "Job" : "Quote";

			let tcs = quote.tcs ? quote.tcs : defaultTCS();
			return (
				<>

					<Popup {...this.props}>
						{this.state.editcustomer && <CustomerContextProvider><CustomerPopup quote={quote} zindex={this.state.zindex + 1} customer={customer} hide={() => { this.setState({ ...this.state, editcustomer: null }) }} /></CustomerContextProvider>}
						{this.state.loading && this.state.mode !== "create" &&
							<div className="popuploading"><FadingDots width="70px" height="70px" color="#62d3f1" /></div>
						}
						{this.state.mode == 'view' ?
							<ViewDetails edit={this.editMode} quote={this.props.quote} />
							:
							<form method="post" id="createQuoteForm" className="quote_form" onSubmit={this.handleSubmit} onKeyDown={this.preventSubmit}>

								{quote._id ?
									<>
										<h3>Edit {quotetype} #{quote.numericID}</h3>

										<div className='inline'>
											{this.state.loading && <><Loading /></>}
											{!this.state.loading && <>
												<b>Created:</b> {dateFormat(quote.createdAt, "nice")} By <b>{quote.creator.fname + " " + quote.creator.lname}</b> | &nbsp;
												<b>Sent:</b> {quote.emailed_date ? dateFormat(quote.emailed_date, "nice") : <div className='red '>Not Sent</div>} | &nbsp;
												<b>Viewed:</b> {quote.viewed_date ? dateFormat(quote.viewed_date, "nice") : <div className='red '>Not Viewed</div>} | &nbsp;
												<b>Accepted:</b> {quote.accepted_date ? dateFormat(quote.accepted_date, "nice") : <div className=' red '>Not Accepted</div>} | &nbsp;
												<b>Next Fit Date:</b> {quote.nextFitDate ? dateFormat(quote.nextFitDate, "nice") : <div className=' red '>No Fit Dates</div>} | &nbsp;
												<b>Completed:</b> {quote.completed_date ? dateFormat(quote.completed_date, "nice") : <div className=' red '>Not Completed</div>}
											</>}
										</div>
									</>
									: <>
										<h3>Create a New  {quotetype} </h3></>}

								{quote.cancelled_date && <center><h3> This {quotetype} was cancelled on {dateFormat(quote.cancelled_date, "nice")}.</h3></center>}

								<div className="top_section">
									<div className='customer_wrapper'>
										<CustomerSearch name="customerName" select={this.selectedCustomer} initialValue={customer ? customer.company ? customer.company : customer.title ? customer.title + ' ' : '' + customer.fname + ' ' + customer.lname : ""} />
										<div className='customerdetails'>
											<div className='add'>
												<strong>Full Address</strong><br />
												{this.state.loading && <><Loading /></>}
												{!this.state.loading && <>
													{(customer.address1) ? <>{customer.address1}<br /></> : ""}
													{(customer.address2) ? <>{customer.address2}<br /></> : ""}
													{(customer.town) ? <>{customer.town}<br /></> : ""}
													{(customer.city) ? <>{customer.city}<br /></> : ""}
													{(customer.postCode) ? <>{customer.postCode}<br /></> : ""}
													{(customer.country) ? <>{customer.country}<br /></> : ""}

													{customer._id ?
														<>< br /><a onClick={(customer) => { this.setState({ ...this.state, editcustomer: customer }) }} className='smalllink'>edit customer details</a>
															<br />
															{
																customer.customerquotes &&
																<select className="form-control" value={quote._id} onChange={e => { this.gotoQuote(e) }} name="othercustomer_orders">

																	{
																		customer.customerquotes?.map((quote, index) => {
																			return (<option value={quote._id}>{dateFormat(quote.createdAt, "nice")} - {currencyFormat(quote.quotePriceTotal)}</option>)
																		})
																	}
																</select>
															}


														</>
														: ""
													}
												</>}

											</div>
											<div className='phone'>
												{this.state.loading && <><Loading /></>}
												{!this.state.loading && <>
													<strong>Email: </strong> {customer.email ? <span>{customer.email}</span> : "None"}<br />
													<strong>Alt Email: </strong> {customer.alternativeEmail ? <span>{customer.alternativeEmail}</span> : "None"}<br />
													<strong>Phone: </strong>{customer.phone ? <span>{customer.phone}</span> : "None"}<br />
													<strong>Mobile: </strong> {customer.mobile ? <span>{customer.mobile}</span> : "None"}<br />
												</>}

											</div>
										</div>
									</div>

									<div>
										<label className="checkbox_label" style={{ margin: 'auto 0 -19px auto' }}>
											{this.state.loading && <><Loading /></>}
											{!this.state.loading &&
												<input type="checkbox" name="showNotesOnInvoice" checked={quote.showNotesOnInvoice} onChange={this.handleInputValue} />
											}
											<span>Show To Customer</span>
										</label>

										<label>
											<span>Notes</span>
											{this.state.loading && <><Loading /></>}
											{!this.state.loading &&
												<textarea name="description" placeholder="Description" value={quote.description} onChange={this.handleInputValue} />
											}

										</label>
									</div>
								</div>
								<div style={{ marginBottom: '20px', display: "block" }}>
									<label className="checkbox_label" style={{ margin: 'auto 0 -19px auto' }}>
										{this.state.loading && <><Loading /></>}
										{!this.state.loading &&
											<input type="checkbox" name="showvat" checked={quote.showvat} onChange={this.handleInputValue} />
										}
										<span>Show VAT To Customer</span>
									</label>
								</div>
								<div className="quote_items">
									<div className="list_section_header">
										<h4>Items</h4>
										<button type="button" onClick={e => this.addToList('items')} disabled={this.context.saving} className="add_to_list_btn"><CgExtensionAdd />Add Item</button>
									</div>
									<div className="quote_items_list_header">
										<div className="tri_columns">
											<h5>Item</h5>
											<h5>Qty</h5>
											<h5>Unit Price</h5>
											<h5>Net Amount</h5>
											<h5>VAT</h5>
											<h5>Line Total</h5>
										</div>
									</div>
									<div className="quote_items_list">
										<InventoryContextProvider>
											{this.state.loading && <><Loading /></>}
											{!this.state.loading && <>
												{quote.items?.map((item, index) => {
													if (item !== "" && item !== undefined && item !== null) {
														let unit_price = Number(item.unit_price || 0).toFixed(2);
														let net_amount = Number(item.net_amount || 0).toFixed(2);
														let total_amount = Number(item.total_amount || 0).toFixed(2);
														let taxrateid = item.taxrateid;
														let itemqty = item.qty ? Number(item.qty) : 1;

														return (
															<div>
																<div className="quote_item tri_columns" key={index}>
																	<InventorySearch initialValue={item} item={item} selectInventoryItem={this.selectInventoryItem} index={index} />
																	<div className="item_qty"><input value={itemqty} name="qty" type="number" step="any" placeholder="1" onChange={e => this.handleInputValue(e, 'items', index)} required autoComplete="off" /></div>
																	<div className="item_price"><div className="currencysym">&pound;</div><ControlledInput value={currencyFormat(unit_price, "numbers")} name="unit_price" type="text" inputMode="numeric" step="any" placeholder="Unit Price" onChange={e => this.handleInputValue(e, 'items', index)} required autoComplete="off" /></div>
																	<div className="item_price">{currencyFormat(net_amount)}</div>
																	<div className="item_price"><select className="taxrateid" onChange={e => this.handleInputValue(e, 'items', index)} value={taxrateid} name="taxrateid"><option>Please Select</option><option value="GB_STANDARD">20%</option><option value="GB_LOWER">5%</option><option value="GB_ZERO">0%</option><option value="GB_EXEMPT">Exempt</option></select></div>
																	<div className="line_total"><div className="currencysym">&pound;</div><ControlledInput value={currencyFormat(total_amount, "numbers")} name="line_total" type="text" inputMode="numeric" step="any" placeholder="Line Total" onChange={e => this.handleInputValue(e, 'items', index)} required autoComplete="off" /></div>
																	<button className='delete' type="button" onClick={e => this.removeFromList('items', index)} disabled={this.context.saving}><AiOutlineDelete /></button>
																</div>
																<div>
																	<textarea value={item.description} name="description" placeholder="Item Description" onChange={e => this.handleInputValue(e, 'items', index)} ></textarea>
																</div>
															</div>
														)
													}
												})}
											</>}

										</InventoryContextProvider>
										<div className="quote_total quote_item tri_columns">
											{this.state.loading && <><Loading /></>}
											{!this.state.loading && <>
												<span></span>
												<span></span>
												<span></span>
												<span></span>
												<label>
													<h6 style={{ margin: '10px 0 5px 0' }}>Total VAT: {currencyFormat(itemsTotalVAT, "")}</h6>
												</label>
												<label>
													<h6 style={{ margin: '10px 0 5px 0' }}>Total Price: {currencyFormat(itemsTotalPrice, "")}</h6>
													<span style={{ fontSize: '12px' }}>Alternative Price: </span>
													<ControlledInput type="numeric" name="altPrice" value={currencyFormat(altPrice ? altPrice : itemsTotalPrice, "numbers")} onChange={e => this.updateQuotedPrice(e)} required autoComplete="off" />
												</label>
											</>}

										</div>
									</div>
								</div>

								<div className="quote_jobs">
									<div className="quote_jobs_list_header list_section_header">
										<h4>Job Inventory</h4>
										<button type="button" onClick={e => this.addToList('jobs')} disabled={this.context.saving} className="add_to_list_btn" ><MdOutlineAddRoad />Add Item</button>
									</div>
									<div className="quote_jobs_list">
										{quote?.jobs?.map((job, index) => {
											console.log("job: ", job);
											let instock = job.inStock ? job.inStock : false
											return (
												<div className="quote_job quad_columns" key={index}>
													{Object.keys(this.jobFields.text).map((fieldName, i) => {
														return (<label key={i}>
															<span>{this.jobFields.text[fieldName]}</span>
															<input value={job[fieldName]} name={fieldName} type="text" placeholder={this.jobFields.text[fieldName]} onChange={e => this.handleInputValue(e, 'jobs', index)} required autoComplete="off" />
														</label>)
													})}
													{Object.keys(this.jobFields.date).map((fieldName, i) => {
														var thisdate = job[fieldName] || "";

														return (<label key={i}>
															<span>{this.jobFields.date[fieldName]}</span>
															<Flatpickr options={DateOptions} name={fieldName} value={thisdate} onChange={([val]) => this.handleDateInput(val, fieldName, 'jobs', index)} />
														</label>)
													})}
													<div className="bi_columns">
														<label className="checkbox_label in_stock">
															<input checked={instock} name="inStock" type="checkbox" placeholder="Available in sock?" onChange={e => this.handleInputValue(e, 'jobs', index)} />
															<span>In Stock?</span>
														</label>
														<label className="delete_job">
															<button type="button" className="delete" onClick={e => this.removeFromList('jobs', index)} disabled={this.context.saving}>
																<AiOutlineDelete />
															</button>
														</label>
													</div>
												</div>
											)
										})}
									</div>
								</div>

								<div className="quote_notes">
									<div className="quote_notes_list_header list_section_header">
										<h4>Internal Notes &amp; Comments</h4>
										<button type="button" onClick={e => this.addToList('notes')} disabled={this.context.saving} className="add_to_list_btn"><BiBookAdd />Add Note</button>
									</div>
									<div className="quote_notes_list">
										{this.state.loading && <><Loading /></>}
										{!this.state.loading && <>
											{quote?.notes?.map((note, index) => {
												return (
													<div className="quote_note" key={index}>
														<textarea value={note.description} name="description" placeholder="Note Description" onChange={e => this.handleInputValue(e, 'notes', index)} ></textarea>
														<button type="button" className="delete" onClick={e => this.removeFromList('notes', index)} disabled={this.context.saving}><AiOutlineDelete /></button>
													</div>
												)
											})}
										</>}

									</div>
								</div>
								<div className="quote_notes">
									<div className="quote_notes_list_header list_section_header">
										<h4>Quote Text</h4>
									</div>
									<div>
										{this.state.loading && <><Loading /></>}
										{!this.state.loading && <>
											<ReactQuill theme="snow"
												value={tcs}
												onChange={this.onRTEchange}
												formats={[
													'header',
													'font',
													'size',
													'bold',
													'italic',
													'underline',
													'strike',
													'blockquote',
													'list',
													'bullet',
													'indent'
												]}
											></ReactQuill>
										</>}

									</div>
								</div>
								{this.state.loading && <><Loading /></>}

								{!this.state.loading && quotetype == 'Job' && quote._id !== 0 && <>

									<InvoicesTable load="false" />
									{invoices?.length > 0 &&
										<CreditnotesTable quote={quote} load="false" />
									}

								</>}

							</form>
						}
						{quote.status == 'cancelled' &&
							<div className="form_button_wrapper">This {quotetype} has been Cancelled.</div>
						}
						{this.state.loading && <><Loading /></>}
						{!this.state.loading &&
							<>
								{quote.status != 'cancelled' &&
									<div className="form_button_wrapper">

										{quoteid && <>
											{!this.state.confirmcancel && <>
												{
													quote.total_outstanding == quote.total_invoices_amount && <button type="button" onClick={e => { this.setState({ ...this.state, confirmcancel: true }) }} disabled={this.context.saving} className="cancel">{/* <MdDeleteForever />Delete */}<MdCancelScheduleSend />Cancel</button>

												}

												<ReactToPrint
													trigger={() => {
														// NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
														// to the root node of the returned component as it will be overwritten.
														return <button type="button" disabled={this.context.saving} className="print"><AiFillPrinter />Print</button>;
													}}
													content={() => this.componentRef}
												/>
												<div style={{ display: 'none' }}><PrintQuote ref={el => (this.componentRef = el)} quote={this.context.quote} /></div>


												<button type="button" onClick={this.sendQuote} disabled={this.context.saving} className="email"><SiMinutemailer />Send to Customer</button>
											</>}
											{this.state.confirmcancel && <>
												<button type="button" onClick={this.cancelQuote} disabled={this.context.saving} className="cancel">{/* <MdDeleteForever />Delete */}<MdCancelScheduleSend />Are You Sure You Want To Cancel This {quotetype}?</button>
											</>}

										</>}

										{quotetype == "Quote" && quoteid && <>
											<button type="button" onClick={this.acceptquote} disabled={this.context.saving} className="convert"><SiConvertio />Convert to Job</button>
										</>}


										{this.state.mode == 'view' && <>
											<button type="button" form="createQuoteForm" onClick={this.editbtn} disabled={this.context.saving}><AiOutlineEdit />Edit</button>
										</>
										}

										<button type="button" form="createQuoteForm" onClick={this.addupdate} disabled={this.context.saving}><AiFillSave />{!quoteid ? "Create" : "Update"}</button>
										{
											quotetype == "Job" && !quote.completed_date && quoteid && invoices?.length > 0 && <>
												<button type="button" form="createQuoteForm" onClick={this.jobcomplete} disabled={this.context.saving}><AiFillSave />Job Complete</button>

											</>
										}
										{
											quotetype == "Job" && !quote.completed_date && quoteid && invoices?.length <= 0 && <>
												<button type="button" onClick={this.jobToQuote} disabled={this.context.saving} className="convert"><SiConvertio />Convert to Quote</button>
											</>
										}


									</div>
								}
							</>}


					</Popup>

				</>
			)
		}


	}
}