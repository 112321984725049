import React, { Component } from 'react';
import { Link } from "react-router-dom";

import './Settings.scss';

class Settings extends Component {

	get_redirectURL = async () => {
		console.log("getting redirectURL")
		let req = await fetch("/api/accounting/authenticate", {
			method: 'GET',

			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		})
		let res = await req.json();
		if (res.redirecturl) {
			window.location = res.redirecturl;
		}

	}

	render() {

		return (
			<div id="recover_page">
				Refresh Sage Token.<br />
				<a target="_blank" rel="noopener noreferrer" onClick={e => { this.get_redirectURL() }}  > Continue </a>

			</div>
		)
	}
}

export default Settings;
