import { Component } from 'react';
import { UserContext, UserContextProvider } from 'contexts/UserContext';
import FadingBalls from "react-cssfx-loading/lib/FadingBalls";

import './UserSearch.scss';

// Context provider wrapper
export default class UserSearch extends Component {
	render() {
		return <UserContextProvider><UserSearchCompnent {...this.props} /></UserContextProvider>
	}
}
class UserSearchCompnent extends Component {
	static contextType = UserContext;
	constructor(props) {
		super(props)

		let search = '';
		 if(this.props.initialValue?.fname){
			search = this.props.initialValue?.fname
		}
		if(this.props.initialValue?.lname){
			search += ' '+ this.props.initialValue?.lname
		}
		this.state = {
			users : [],
			search : this.props.search || search,
			loading : true,
			selectedUser : this.props.initialValue || {},
			showSearchResults: false,
		}
		this.timeout = null
	}

	loadUsers = (e) => {
		this.setState({...this.state, search : e?.target?.value, showSearchResults: true})
		clearTimeout(this.timeout)
		this.timeout = setTimeout(async () => {
			this.setState({...this.state, loading: true})
			let users = await this.context.searchUser(this.state.search, this.props.getFullInfo)
			this.setState({...this.state, users, loading : false})
		}, 3000)
	}
	showCusSearch = (e) => {
		this.setState({...this.state, showSearchResults: true, search: this.state.search})
		this.loadUsers({target : {value : this.state.selectedUser?.fname || ''}})
	}
	hideCusSearch = () => {
		let search = '';
		 if(this.state.selectedUser?.fname){
			search = this.state.selectedUser?.fname
		}
		if(this.state.selectedUser?.lname){
			search += ' '+ this.state.selectedUser?.lname
		}
		setTimeout(()=>{ this.setState({...this.state, showSearchResults: false, search }) },200)
	}
	select = user => {
		this.setState({...this.state, selectedUser : user, showSearchResults: false, search : user.fname +' '+ user.lname, loading: false})
		this.props.select(user)
	}
	componentDidMount() {
		let search = ''
		if(this.props.initialValue?.fname){
			search = this.props.initialValue?.fname
		}
		if(this.props.initialValue?.lname){
			search += ' '+this.props.initialValue?.lname
		}
		console.log("usersearch: " , search);
		this.setState({...this.state, search, selectedUser: this.props.initialValue})
	}
	render() {
		return (
			<>
				<div className="search_user">
					<label>
						<span>User</span>
						<input type="text" name={this.props.name} placeholder="User Name" value={this.state.search} onChange={this.loadUsers} onFocus={this.showCusSearch} onBlur={this.hideCusSearch} required autoComplete="off"/>
					</label>
					
					{this.state.showSearchResults &&
						<div className="group_focus">
							<div className="search_user_list">
								{this.state.loading ? <div className="loading_users">
									<FadingBalls />
								</div>:
								<>
								{!this.state.loading && this.state?.users?.length == 0 && <div className="search_user_not_found">No User Found</div>}
								{this.state?.users?.map((user) => {
									return(
									<div key={user?._id} className="search_user_list_item" onClick={e => this.select(user)}>
										<span>{`${user.fname} ${user.lname} ${user.email ? '('+user.email+')':''}`}</span>
									</div>
									)})}
								</>
								}
								
							</div>
						</div>}
				</div>
			</>
		)
	}
}