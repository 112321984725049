import { Component } from 'react';
import { Link } from 'react-router-dom';
import { ImQuotesLeft } from 'react-icons/im';
import { GrTasks, GrNotes } from 'react-icons/gr';
import { FaFileInvoiceDollar, FaUsers } from 'react-icons/fa';
import { RiCustomerService2Fill, RiLogoutCircleFill } from 'react-icons/ri';
import { MdInventory2 } from 'react-icons/md';
import logo from 'assets/logo.png';

import { DashboardContext } from 'contexts/DashboardContext';
import './SideBar.scss';

class SideBar extends Component {
	static contextType = DashboardContext;

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			load: true,
			data: [] //this.context.sidebarCounts
		}

		this.menuItems = [
			// { route: '/dashboard', icon: <FaHome />, linkText: 'Home'},
			{ index: "quotes", countkey: "openquotescount", route: '/quotes', icon: <ImQuotesLeft />, linkText: 'Open Quotes' },
			{ index: "jobs", countkey: "openjobscount", route: '/jobs', icon: <GrTasks />, linkText: 'Open Jobs' },
			{ index: "invoices", countkey: "openinvoicescount", route: '/invoices', icon: <FaFileInvoiceDollar />, linkText: 'Open Invoices' },

			{ index: "customers", countkey: "customerscount", route: '/customers', icon: <RiCustomerService2Fill />, linkText: 'Customers' },
			{ index: "events", countkey: "eventscount", route: '/diary', icon: <GrNotes />, linkText: 'Diary' },
			{ index: "users", countkey: "userscount", route: '/users', icon: <FaUsers />, linkText: 'Users' },
			{ route: '/inventory', countkey: "", icon: <MdInventory2 />, linkText: 'Inventory' },
			{ route: '/settings', countkey: "", icon: <MdInventory2 />, linkText: 'Settings' },
		]
	}

	async componentDidMount() {

		if (!this.state.loading && this.state.load) {

			this.setState({ ...this.state, loading: true, load: false })
			this.context.loadSideBar();
			this.setState({ ...this.state, loading: false, load: false })
		}
	}

	render() {
		if (this.state.loading) {
			return;
		}

		let location = window.location
		let counts = this.context.sidebarCounts || [];

		return (
			<>
				<div className="sidebar_container">
					<Link to="/dashboard" style={{ color: '#fff', textDecoration: 'none' }}>
						<div className="logo_container">
							<img src={logo} />
						</div>
					</Link>
					<nav className="sidebar_nav">
						<div className="sidebar_pages_links">
							{
								!this.state.loading && <>
									{this.menuItems.map((menu, index) => {

										let menucount = counts.hasOwnProperty(menu.countkey) ? counts[menu.countkey] : 0;


										return (
											<Link to={menu.route} key={menu.route} className={location.pathname.replaceAll('/', '') == menu.route.replaceAll('/', '') ? 'active' : ''}>
												{menu.icon}
												<span>{menu.linkText} {menucount || ''}</span>
											</Link>
										)
									})}
								</>
							}


							<Link to="#" onClick={this.context.logUserOut}>
								<RiLogoutCircleFill />
								<span>Logout</span>
							</Link>
						</div>
					</nav>
				</div>
			</>

		);
	}
}

export default SideBar;