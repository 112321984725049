import { Component } from 'react';
import { Link } from 'react-router-dom';
import FadingBalls from "react-cssfx-loading/lib/FadingBalls";
import { BiSearch } from 'react-icons/bi';

import './Search.scss';

export default class NavbarSearch extends Component {

	constructor(props) {
		super(props)
		this.state = {
			search: '',
			loading: false,
			customers: [],
			quotes: [],
			showResults: false,
		}
		this.timeout = null
	}

	handleChange = async (e) => {
		this.setState({ ...this.state, search: e.target.value })
		clearTimeout(this.timeout)
		this.timeout = setTimeout(async () => {
			if (this.state.search.length < 3) {
				return
			}

			this.setState({ loading: true })
			if (e.target.value.length > 2) {
			}
			let req = await fetch("/api/dashboard/search", {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ search: e.target.value })
			})
			let res = await req.json()
			this.setState({ loading: false, customers: res.customers, quotes: res.quotes, customerquotes: res.customerquotes, invoices: res.invoices })
		}, 2000)
	}

	showResults = () => {
		clearTimeout(this.timeout)
		this.setState({ ...this.state, showResults: true })
	}
	hideResults = () => {
		this.timeout = setTimeout(() => {
			this.setState({ ...this.state, showResults: false })
		}, 250)
	}

	bounceto = (link) => {
		console.log("bouncing to", link);
		window.location.href = link;
	}

	render() {
		let customers = this.state.customers ? this.state.customers : [];
		let customerquotes = this.state.customerquotes ? this.state.customerquotes : [];
		let quotes = this.state.quotes ? this.state.quotes : [];
		let invoices = this.state.invoices ? this.state.invoices : [];


		return (
			<form className="search_form" onSubmit={e => e.preventDefault()}>
				<input type="text" placeholder="Search..." defaultValue={this.state.search} onFocus={this.showResults} onBlur={this.hideResults} onInput={this.handleChange} />
				<BiSearch />
				{this.state.showResults && <div className="search_results">
					{this.state.loading ?
						<div className="direcional">
							<FadingBalls />
						</div>
						:
						<>
							{this.state.search.length < 3 && <>
								<p className="direcional">Type 3 or more characters to search</p>
							</>}
							{this.state.search.length > 2 && <>
								{customers.map(cus =>
									<div key={cus._id}>

										<div className="result_item" onClick={e => this.bounceto(`/customers?id=${cus._id}`)}>
											Customer:{cus.title ? cus.title + ' ' : ''}
											{`${cus.fname} ${cus.lname}`}
											{`${cus.company ? `, ${cus.company}` : ``}`}
											{`${cus.town ? `, ${cus.town}` : ``}`} {`${cus.city ? `, ${cus.city}` : ``}`}

										</div>
									</div>)}

								{customerquotes.map(quote =>
									<div key={quote._id}>

										<div key={quote._id} className="result_item" onClick={e => this.bounceto(`/${quote.type}s?id=${quote._id}`)} >
											{quote.type.at(0).toUpperCase() + quote.type.slice(1)}: {quote.numericID} {quote.customer.fname} {quote.customer.lname}
										</div>
									</div>)}

								{quotes.map(quote =>
									<div key={quote._id}>

										<div key={quote._id} className="result_item" onClick={e => this.bounceto(`/${quote.type}s?id=${quote._id}`)} >
											{quote.type.at(0).toUpperCase() + quote.type.slice(1)}: {quote.numericID} {quote.customer.fname} {quote.customer.lname}
										</div>
									</div>)}

								{invoices.map(invoice =>
									<div key={invoice._id}>

										<div key={invoice._id} className="result_item" onClick={e => this.bounceto(`/invoices?id=${invoice._id}`)}>
											{"Invoice"}: {invoice.displayed_as} {invoice.customer.fname} {invoice.customer.lname}
										</div>
									</div>)}
								{!this.state.loading && customers.length === 0 && customerquotes.length === 0 && quotes.length === 0 && invoices.length === 0 && <p className="direcional">No results found</p>}

							</>}
						</>}
				</div>}
			</form>
		)
	}
}