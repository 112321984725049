import { Component } from 'react';
import { QuoteContext } from 'contexts/QuoteContext';
import FadingBalls from "react-cssfx-loading/lib/FadingBalls";
import { Link } from 'react-router-dom';
import { BiBookAdd } from 'react-icons/bi';
import { CreditNoteContextProvider } from 'contexts/CreditNoteContext';
import CreditNotePopup from '../CreditNotePopup/CreditNotePopup';
import { currencyFormat, dateFormat, ControlledInput, infocustomeradd,infoTcs} from "utils/utils";

export default class RelatedCreditNotes extends Component{
	static contextType = QuoteContext;

	constructor(props){
		super(props)

		this.state = {
			load: this.props.load ? (this.props.load == "false" ? false : true) : true,
			loading: false,
			quote: this.props.quote? this.props.quote : {creditNotes:[]}
		}
	}

	reload = () =>{
		
		this.load();
	}

	
	async componentDidMount(){
		
		if (this.state.load &&  !this.state.loading)
		{
			
			this.setState({...this.state, loading: true})
		
			let quote = await this.context.getQuote(this.state.quote._id);
		
			
			this.setState({...this.state, quote, load:false, loading: false})
		}
	}
	
	render() {
		
		let quote = this.context.quote;
		let creditNotes = quote.creditnotes? quote.creditnotes : [];

		if(this.state.loading){
			return <div className="preloader" style={{height: '40px'}}><FadingBalls color="#dd4477"/></div>
		}

			let outstanding = creditNotes?.filter(creditNote => creditNote.status === 'UNPAID')?.reduce((sum, creditNote) => creditNote.total_amount+sum, 0) || 0
			outstanding += creditNotes?.filter(creditNote => creditNote.status === 'PART_PAID')?.reduce((sum, creditNote) => creditNote.total_amount+sum, 0) || 0
			return (
				<>
				{this.state.creditnote && <CreditNoteContextProvider><CreditNotePopup zindex={11} quote={quote} creditNote={this.state.creditnote} hide={() => this.setState({...this.state, creditnote:null})} /></CreditNoteContextProvider>}
					
				<div className="quote_notes">
										<div className="quote_notes_list_header list_section_header">
											<h4>Credit Notes ({creditNotes?.length})</h4>
											{!quote.date_cancelled && quote.total_paid > quote.total_credited &&
											<button type="button" onClick={e => this.setState({...this.state, creditnote:{credit_note_lines:[],quote}})}  disabled={this.context.saving} className="add_to_list_btn"><BiBookAdd />Add Credit Note</button>
											}
										</div>
										<div className="quote_notes_list">
											<div className="related_invoices_summery">
												<div>Paid: {currencyFormat(quote.total_paid)}</div>
												<div>Credited: {currencyFormat(quote.total_credited)}</div>
											</div>
											<div className="table related_creditnotes">
												<div className="table_headings">
													<div className="th">#</div>
													<div className="th">Date Raised</div>
													<div className="th">Total Items</div>
													<div className="th">Amount</div>
													<div className="th">Tax</div>
													<div className="th">Total Amount</div>
													
												</div>
												<div className="table_body">
													{creditNotes?.map((creditNote, i) => {
														return(
															<div className="tr" onClick={e => this.setState({...this.state, creditnote:creditNote})} key={creditNote._id} >
																<div className="td">{i+1}</div>
																<div className="td">{dateFormat(creditNote.date,"nice")}</div>	
																<div className="td">{creditNote.credit_note_lines?.length}</div>
																<div className="td">{currencyFormat(creditNote.total_amount - creditNote.tax_amount)}</div>
																<div className="td">{currencyFormat(creditNote.tax_amount)}</div>
																<div className="td">{currencyFormat(creditNote.total_amount)}</div>
															</div>
														)
													})}
												</div>
											</div>
										</div>
									</div>

					

				</>
			)
		
	}
}