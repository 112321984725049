import { Component } from 'react';
import { UserContext } from 'contexts/UserContext';
import Wrapper from 'pages/Wrapper';
import { FaUserPlus } from 'react-icons/fa';
import { AiOutlineEdit } from 'react-icons/ai';
import { RiDeleteBinLine } from 'react-icons/ri';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import { sort, dateFormat, ControlledInput, infocustomeradd, infoTcs } from "utils/utils";
import UserPopup from 'components/UserPopup/UserPopup';
import FadingDots from "react-cssfx-loading/lib/FadingDots";

import './Users.scss';

export default class Users extends Component {
	static contextType = UserContext;

	constructor(props) {
		super(props)

		this.columnNames = {
			'username': 'Username',
			'email': 'Email',
			// 'fname': 'First Name',
			// 'lname': 'Last Name',
			'name': 'Name',
			'createdDate': 'Created At',
			'lastLogin': 'Last Login',
			'loginTimes': 'Logins Count',
			'role': 'Role',
			'status': 'Status',
		}

		this.state = {
			deletingUsers: [],
			sort: {
				key: 'createdAt',
				desc: true,
			},
			search: '',
			showPopup: false,
			selectedUser: {}
		}
	}

	componentDidMount() {
		document.title = 'Users';
		if (this.context.user.role === 'admin') {
			this.context.listUsers()
		}
	}

	sort = (key) => {
		let desc = this.state.sort.key == key && this.state.sort.desc
		this.setState({ ...this.state, sort: { key, desc: !desc } })
	}

	filterUsers = (e) => {
		this.setState({ ...this.state, search: e.target.value.toLowerCase() })
	}

	showUserPopup = async (selectedUser) => {
		selectedUser = selectedUser._id ? selectedUser : {}
		this.setState({ ...this.state, showPopup: true, selectedUser })
	}

	hidePopup = () => {
		this.setState({ ...this.state, showPopup: false })
	}

	deleteUser = async (id) => {
		this.setState({ ...this.state, deletingUsers: [...this.state.deletingUsers, id] })
		await this.context.deleteUser(id)
		this.setState({ ...this.state, deletingUsers: this.state.deletingUsers.filter(user => user != id) })
	}

	render() {

		let users = sort(this.context.users, this.state.sort.key, this.state.sort.desc)
		if (this.state.search !== '') {
			users = users.filter(user => {
				return (user?.email?.includes(this?.state?.search) || user?.username?.includes(this.state.search) || user?.fname?.includes(this.state.search) || user?.lname?.includes(this.state.search))
			})
		}

		return (
			<Wrapper>
				{this.state.showPopup && <UserPopup hide={this.hidePopup} user={this.state.selectedUser} />}
				{this.context.loadingUsersList ?
					<div className="preloader">
						<FadingDots width="70px" height="70px" color="#62d3f1" />
					</div>
					:
					<div className="user_page_container">
						<div className="user_page_header">
							<h1>All Users</h1>
							<input type="text" autoComplete="off" placeholder="Start Typing to Filter Users..." onInput={this.filterUsers} className="filter_search" />
							<button className="add_user" onClick={this.showUserPopup}><FaUserPlus /> Add New User</button>
						</div>
						{this.context.users.length > 0 ?
							<div className="users_table">
								<div className="table">
									<div className="table_headings">

										{Object.keys(this.columnNames).map((key) => {
											return (
												<div key={key} className="th sortable_th" onClick={e => this.sort(key)}>
													{this.columnNames[key]}&nbsp;
													{this.state.sort.key == key ?
														this.state.sort.desc ? <GoTriangleDown /> : <GoTriangleUp />
														: ''
													}
												</div>
											)
										})}
									</div>

									<div className="table_body">
										{Array.isArray(users) && users.map((user, index) => {
											return (
												<div className="tr" key={index}>

													{Object.keys(this.columnNames).map(key => <div key={key} className="td cursor_pointer" onClick={e => this.showUserPopup(user)}>{key == 'createdAt' ? dateFormat(user[key], "nice") : user[key]}</div>)}
													<div className="td">
														<button className="icon_btns" onClick={e => this.showUserPopup(user)} disabled={this.state.deletingUsers.includes(user._id)}>
															<AiOutlineEdit />
														</button>
														<button className="icon_btns" onClick={e => this.deleteUser(user._id)} disabled={this.state.deletingUsers.includes(user._id)}>
															<RiDeleteBinLine />
														</button>
													</div>
												</div>
											)
										})
										}
									</div>

								</div>
							</div>
							:
							<h4>The are currently no users on the plateform.</h4>}
					</div>}
			</Wrapper>
		)
	}
}
