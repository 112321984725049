import { Component } from 'react';
import { QuoteContext } from 'contexts/QuoteContext';
import Wrapper from 'pages/Wrapper';

import { MdRequestQuote, MdCancelScheduleSend } from 'react-icons/md';
import { currencyFormat, dateFormat } from "utils/utils";
import { AiOutlineEdit } from 'react-icons/ai';
import { FaBan } from 'react-icons/fa';
import { GoLinkExternal } from 'react-icons/go';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import FadingDots from "react-cssfx-loading/lib/FadingDots";

import QuotePopup from 'components/QuotePopup/QuotePopup';

import './Jobs.scss';

export default class Jobs extends Component {
	static contextType = QuoteContext;

	constructor(props) {
		super(props)

		this.state = {
			sort: {
				key: 'accepted_date',
				desc: true
			},
			jobs: [],
			load: true,
			loading: false
		}

		this.columnNames = {
			numericID: 'Job Number',
			customerName: 'Customer',
			outofstock: 'In Stock',
			quotePriceTotal: 'Quoted Price',
			total_invoiced: 'Total Invoiced',
			total_outstanding: 'Total Outstanding',
			status: 'Status',
			nextFitDate: 'Next Fit Date',
			createdby: "Created By",
			createdAt: 'Date Created',
			accepted_date: 'Quote Accepted',
		}
	}

	sort = (key) => {
		this.setState({ ...this.state, sort: { key, desc: !this.state.sort.desc } })
		this.context.sort(key, !this.state.sort.desc)
	}

	deleteQuote = async (quoteId) => {
		this.setState({ ...this.state, deletingJobs: [...this.state.deletingJobs, quoteId] })
		await this.context.deleteQuote(quoteId)
		this.setState({ ...this.state, deletingJobs: this.state.deletingJobs.filter(cid => cid !== quoteId) })
	}

	cancelQuote = async (quoteId) => {
		let retVal = window.confirm("Are you sure you want to cancel this?");

		if (retVal === true) {
			this.setState({ ...this.state, deletingJobs: [...this.state.jobs, quoteId] })
			await this.context.cancelQuote(quoteId)
			this.setState({ ...this.state, jobs: this.state.jobs.filter(cid => cid !== quoteId) })
		} else {

			return false;
		}

	}

	async componentDidMount() {
		document.title = 'Jobs';
		if (this.state.load && !this.state.loading) {
			this.setState({ ...this.state, loading: true })
			let jobs = await this.context.getQuotes({ type: 'job' });
			this.sort(this.state.sort.key)
			this.setState({ ...this.state, jobs, load: false, loading: false })
		}

		let jobid = new URLSearchParams(window.location.search).get('id');
		jobid = jobid ? jobid : null;
		if (jobid) {
			this.setState({ ...this.state, quote: { _id: jobid } })
		}
	}


	render() {

		let jobs = this.context.quotes;
		let today = dateFormat(new Date(), "nice");

		if (this.state.searchFilter) {
			jobs = jobs?.filter(quote => {
				let filter = this.state?.searchFilter?.toLowerCase()
				let str = `"${quote?.customer?.email}" "${quote?.customer?.fname}" "${quote?.customer?.lname}" "${quote?.creator?.email}" "${quote?.creator?.fname}" "${quote?.creator?.lname}" "${quote?.QuotePriceTotal}"`

				quote?.items?.forEach(item => { str += `"${item.name}" ` })
				quote?.jobs?.forEach(job => { str += `"${job.areaArrived}" "${job.item}" "${job.suplier}"` })
				quote?.notes?.forEach(note => { str += `"${note.description}" ` })
				str += ` "${quote.numericID}"`

				let regex = new RegExp(`(${filter})`, 'i')

				return regex.test(str)
			})
		}

		return (
			<Wrapper>
				<div id="jobs_page_container">
					{this.state.quote != null &&
						<QuotePopup className="quote_popup slidein_right" hide={() => { this.setState({ ...this.state, quote: null }) }} quote={this.state.quote} mode={this.state.mode} />
					}

					<div className="jobs_page_header">
						<button className="add_job_button" onClick={e => this.setState({ ...this.state, quote: { _id: 0, accepted_date: today } })}>
							<MdRequestQuote />
							New Job
						</button>
						<input type="text" autoComplete="off" placeholder="Start typing to filter jobs..." onInput={e => this.setState({ ...this.state, searchFilter: e.target.value })} className="filter_search" />
					</div>
					{this.state.loading ?
						<div className="preloader">
							<FadingDots width="70px" height="70px" color="#62d3f1" />
						</div>
						:
						<>
							{jobs.length !== 0 ?
								<div className="Jobs_table">
									<div className="table jobs">
										<div className="table_headings">
											{Object.keys(this.columnNames).map((key) => {
												return (
													<div key={key} className="th sortable_th" onClick={e => this.sort(key)}>
														{this.columnNames[key]}&nbsp;
														{this.state.sort.key === key ?
															this.state.sort.desc ? <GoTriangleDown /> : <GoTriangleUp />
															: ''
														}
													</div>
												)
											})}
											<div className="th">Actions</div>
										</div>

										<div className="table_body">
											{Array.isArray(jobs) && jobs.map((quote, index) => {

												return (
													<div className="tr {status}" key={index}>
														{Object.keys(this.columnNames).map(key => {

															let value = key;
															let instock = quote.outofstock ? quote.outofstock : false;
															switch (value) {

																case 'customerName': {
																	let customerName = quote.customer ? <>{quote.customer.fname}  {quote.customer.lname}</> : <></>;

																	value = <span>{quote.customer.company ? <><b>{quote.customer.company}</b><br /></> : <></>}{customerName}</span>;
																	break;
																}
																case "outofstock": {
																	value = <span className={instock ? "outofstock" : "instock"}>{instock ? <>No</> : <>Yes</>}</span>;
																	break;
																}
																case 'createdby': {
																	value = <span>{quote.creator.fname} {quote.creator.lname}</span>;
																	break;
																}
																case 'createdAt': {
																	value = <span>{dateFormat(quote.createdAt, "nice")}</span>;
																	break;
																}
																case 'accepted_date': {
																	value = <span>{dateFormat(quote.accepted_date, "nice")}</span>;
																	break;
																}
																case 'nextFitDate': {
																	value = <span>{dateFormat(quote[key], "nice")}</span>;
																	break;
																}
																case 'quotePriceTotal': {
																	value = currencyFormat(quote.quotePriceTotal);
																	break;
																}
																case 'total_invoiced': {
																	value = currencyFormat(quote.total_invoices_amount);
																	break;
																}
																case 'total_paid': {
																	value = currencyFormat(quote.total_paid - quote.total_credited)
																	break;
																}
																case 'total_outstanding': {
																	value = currencyFormat(quote.total_outstanding)
																	break;
																}
																default: {
																	value = quote[key];
																	break;
																}
															}

															return (
																<div key={key} className="td cursor_pointer" onClick={e => this.setState({ ...this.state, quote: { _id: quote._id } })}>{value}</div>
															)
														})}
														<div className="td">
															<button className="icon_btns" onClick={e => this.setState({ ...this.state, quote: { _id: quote._id } })} >
																<GoLinkExternal />
															</button>
															{!['cancelled', 'canceled'].includes(quote.status) &&
																<>
																	{!quote.sageID && 1 == 2 &&
																		<button className="icon_btns" onClick={e => this.setState({ ...this.state, quote: { _id: quote._id } })} >
																			<AiOutlineEdit />
																		</button>}
																	{
																		quote.total_outstanding == quote.total_invoices_amount && <button className="icon_btns" onClick={e => this.cancelQuote(quote._id)} title='Cancel'><FaBan /></button>
																	}

																</>}
														</div>
													</div>
												)
											})}
										</div>

									</div>
								</div>
								:
								<h3>No records found.</h3>}
						</>}
				</div>
			</Wrapper>
		)
	}
}
