import { Component } from 'react';
import { AiFillSave } from 'react-icons/ai';

import { CustomerContext } from 'contexts/CustomerContext';
import ViewDetails from './ViewDetails';
import Popup from 'components/Popup/Popup';

import { addCountryCode, isValidEmail } from "utils/utils";

import './CustomerPopup.scss';

export default class CustomerPopup extends Component {
	static contextType = CustomerContext;

	constructor(props) {
		super(props)
		let customer = (this.props.quote) ? this.props.quote.customer : (this.props.customer) ? this.props.customer : {
			fname: '',
			lname: '',
			town: '',
			city: '',
			email: '',
			alternativeEmail: '',
			phone: '',
			mobile: '',
			postCode: '',
			country: '',
			address1: '',
			address2: '',

		};

		this.state = {
			quote: this.props.quote,
			customer,
			loading: false,
			mode: this.props.mode,
		}
	}


	handleInputValue = (e, listName, index) => {
		let customer = this.state.customer
		let list = customer[listName]
		let name = e.target.name;
		let val = (name == "qty" || name == "price") ? parseFloat(e.target.value) : e.target.value;
		if (name == "mobile") {
			val = addCountryCode(val, "UK");
		}
		if (!Array.isArray(list) || !list[index]) {
			customer[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : val
			this.setState({ ...this.state, customer })
			return
		}

		list[index][e.target.name] = e.target.type === 'checkbox' ? e.target.checked : val
		customer[listName] = list
		this.setState({ ...this.state, customer })
	}

	handleSubmit = async (e) => {
		e.preventDefault()
		var errors = [];

		let customer = this.state.customer;
		console.log("customer", customer);

		if (!customer.fname || customer.fname === "") { errors.push("fname"); }
		if (!customer.lname || customer.lname === "") { errors.push("lname"); }
		if (!customer.address1 || customer.address1 === "") { errors.push("address1"); }
		if (!customer.postCode || customer.postCode === "") { errors.push("postcode"); }
		if (customer.email !== "" && typeof (customer.email) !== "undefined") { if (!isValidEmail(customer.email)) { errors.push("email") } }
		if (customer.alternativeEmail !== "" && typeof (customer.alternativeEmail) !== "undefined") { console.log("is valid email? ", isValidEmail(customer.alternativeEmail)); if (!isValidEmail(customer.alternativeEmail)) { errors.push("alternativeEmail") } }

		console.log(errors)

		if (errors.length <= 0) {
			this.setState({ ...this.state, errors, customer, loading: true })
			let res = await this.context.createUpdateCustomer(customer)

			this.setState({ ...this.state, errors, loading: false, customer: res })
			if (typeof this.props.select == 'function') {
				this.props.select(res)
				this.props.hide()
			}
		}
		else {
			this.setState({ ...this.state, errors, customer, loading: false })
		}

	}

	editMode = () => {
		this.setState({ ...this.state, mode: 'edit' })
	}

	render() {
		let customer = this.state.customer;

		return (

			<Popup {...this.props} className="customer_popup">
				{this.state.mode == 'view' ?
					<ViewDetails edit={this.editMode} customer={this.state.customer} />
					:
					<form method="post" className="create_customer_form ">

						<h3>{this.state.customer._id ? 'Edit' : 'Create a New'} Customer</h3>
						<div >
							<label>
								<span>Company </span>
								<input autoComplete="off" type="text" name="company" placeholder="Company Name" onChange={this.handleInputValue} defaultValue={customer.company} />
							</label>
						</div>
						<div >
							<label>
								<span>First Name</span>
								<input autoComplete="off"
									className={`form-control ${this.state.errors?.includes("fname") ? "error" : ""}`}
									type="text" name="fname" required placeholder="Customer's First Name" onChange={this.handleInputValue} defaultValue={customer.fname} />
							</label>
							<label>
								<span>Last Name</span>
								<input autoComplete="off"
									className={`form-control ${this.state.errors?.includes("lname") ? "error" : ""}`}
									type="text" name="lname" required placeholder="Customer's Last Name" onChange={this.handleInputValue} defaultValue={customer.lname} />
							</label>
						</div>

						<div>
							<label>
								<span>Email</span>
								<input autoComplete="off" type="email"
									className={`form-control ${this.state.errors?.includes("email") ? "error" : ""}`}
									name="email" required placeholder="Customer's Email Address" onChange={this.handleInputValue} defaultValue={customer.email} />
							</label>
							<label>
								<span>Alternative Email</span>
								<input autoComplete="off" type="email"
									className={`form-control ${this.state.errors?.includes("alternativeEmail") ? "error" : ""}`}
									name="alternativeEmail" placeholder="Alternative Email Address" onChange={this.handleInputValue} defaultValue={customer.alternativeEmail} />
							</label>
						</div>

						<div >
							<label>
								<span>Landline #</span>
								<input autoComplete="off" type="text" name="phone" placeholder="Customer's Landline Number" onChange={this.handleInputValue} defaultValue={customer.phone} />
							</label>
							<label>
								<span>Mobile # ( Please Include Country Code )</span>
								<input autoComplete="off" type="text" name="mobile" placeholder="+44" value={customer?.mobile} onChange={this.handleInputValue} defaultValue={customer.mobile} />
							</label>
						</div>
						<label>
							<span>Address Line 1</span>
							<input autoComplete="off"
								className={`form-control ${this.state.errors?.includes("address1") ? "error" : ""}`}
								type="text" name="address1" placeholder="Address Line 1" onChange={this.handleInputValue} defaultValue={customer.address1} />
						</label>
						<label>
							<span>Address Line 2</span>
							<input autoComplete="off" type="text" name="address2" placeholder="Address line 2" onChange={this.handleInputValue} defaultValue={customer.address2} />
						</label>
						<div >
							<label>
								<span>Town</span>
								<input autoComplete="off" type="text" name="town" placeholder="Town" onChange={this.handleInputValue} defaultValue={customer.town} />
							</label>
							<label>
								<span>City</span>
								<input autoComplete="off" type="text" name="city" placeholder="City" onChange={this.handleInputValue} defaultValue={customer.city} />
							</label>
						</div>

						<div >
							<label>
								<span>Postal Code</span>
								<input autoComplete="off" type="text"
									className={`form-control ${this.state.errors?.includes("postcode") ? "error" : ""}`}
									name="postCode" placeholder="Post Code" onChange={this.handleInputValue} defaultValue={customer.postCode} />
							</label>
							<label>
								<span>Country</span>
								<input autoComplete="off" type="text" name="country" placeholder="Country" onChange={this.handleInputValue} defaultValue={customer.country} />
							</label>
						</div>

						<div className="submit_bitton_wrapper">
							<button type="button" onClick={this.handleSubmit} disabled={this.state.loading}><AiFillSave /> {customer._id ? 'Update' : 'Create'}</button>
						</div>

					</form>
				}
			</Popup>
		)
	}
}