import { Component } from 'react';
import { InventoryContext } from 'contexts/InventoryContext';
import FadingBalls from "react-cssfx-loading/lib/FadingBalls";
import {currencyFormat} from "utils/utils";

import './InventorySearch.scss';

// Context provider wrapper
/* export default class InventorySearch extends Component {
	render() {
		return <InventorySearchCompnent {...this.props} /></InventoryContextProvider>
	}
} */
export default class InventorySearch extends Component {
// class InventorySearchCompnent extends Component {
	static contextType = InventoryContext;

	constructor(props) {
		super(props)

		this.state = {
			search : this.props.item.name,
			loading : false,
			showSearchResults: false,
			selected: this.props.item,
			newItem: {name:this.props.item.name,cat:"",price:""}
		}
		this.timeout = null
	}

	showSearch = (e) => {
		this.setState({...this.state, showSearchResults: true})
	}

	hideSearch = () => {
		setTimeout(()=>{
			let search = this.state.selected.name || this.props.item.name
			this.setState({...this.state, search, showSearchResults: false })
		},200)
	}

	select = item => {
		// this.setState({...this.state, showSearchResults: false, search: item.name, selected: item, loading: false})
		
		let newItem = {...item,itemname:item.name};
		
		this.props.selectInventoryItem(newItem, this.props.index)
		this.hideSearch();
	}

	searchItems = e => {
		this.setState( { ...this.state, search: e.target.value, newItem:{...this.state.newItem, name:e.target.value} } )
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.initialValue !== this.props.initialValue) {
			let valbox = "Item Name";
			if (nextProps.initialValue)
			{
				valbox = nextProps.initialValue.name;
			}
			this.setState({...this.state, search : valbox})
		  return true;
		} 
		
		else if (nextState != this.state)
		{
			return true;
		}

		else {
			return false;
		  
		}
	  }

	  choosecategory = (e) => {
		this.setState({...this.state,newItem: {...this.state.newItem,cat:{...this.state.newItem.cat,_id:e.target.value}}});
	  }

	  addtoinventory = async (e) => {
		
		e.preventDefault()
		if ( !this.state.loading){
			this.setState({...this.state, loading: true, newItem: {...this.state.newItem}})
			
			let res = await this.context.addToQuoteInventory(this.state.newItem);
			
			this.select(res, this.props.index)
			this.setState({...this.state,loading:false})
		}
		
	  }

	getParentCat = (catid) => {
		
		let cat = this.context.inventory.cats.find(c => c._id == catid)
		if(cat){
		
			if (catid == cat.parent){
				//recursive ???
				return cat.name + ' / ';
			}
			let parent = this.getParentCat(cat.parent)
			if(parent){
				return  parent + cat.name + ' / '
			}else{
				return  cat.name + ' / '
			}
		}else{
			//console.log("none found");
			return ''
		}
	}

	getSelCats = (pcatid, items) =>{
		
		let selcats = "";
		this.context.inventory.cats.map((cat) => 
		{
			let catparent = cat.parent? cat.parent : "";

				if (catparent == pcatid ){
					let children = this.getSelCats(cat._id,[]);
					items.push({"value":cat._id,"children": children, "label": cat.name});
				}
		})
		
		return items;
	}
	
	rendercats = (cats,levels) => {
		let catshtml = [];
		let spacer = "";
		

		for(let a = 0; a < levels; a++)
			{
				spacer += "---";
			}
		
		cats.map((cat, key) => {
			
			let thislabel = spacer + " "  + cat.label;
			let thisvalue = cat.value;
			let catid = cat._id;

			let thischildren = cat.children;

			catshtml.push(<option key={catid} value={thisvalue} >{thislabel}</option>);

			if (thischildren.length > 0)
			{
				let childhtml = this.rendercats(thischildren,levels+1);
				for(let b = 0; b < childhtml.length; b++)
				{
					catshtml.push(childhtml[b]);
				}
			}
			
		});

		return catshtml;
	}

	render() {
		
		let cats = this.getSelCats("",[]);
	
		let regex = new RegExp( this.state.search, 'gi')
		let items = this.context?.inventory?.items?.map(i => {
			i.computedName = this.getParentCat( i.cat ) + i.name
			return i
		})
		
		items = items?.filter(i => regex.test(i.computedName))
		
		return (
			<>
				<div className="search_inventory">
					<input index={this.props.index} type="text" name={this.props.name} placeholder="Item Name" value={this.state.search} onChange={this.searchItems} onFocus={this.showSearch}  required autoComplete="off"/>
					
					{this.state.showSearchResults &&
						<div className="group_focus">
							<div className="search_inventory_list">
								{this.state.loading ? <div className="loading_inventorys">
									<FadingBalls />
								</div>:
								<>
								{!this.state.loading && items?.length == 0 && 
									<>
										{!this.state.addinventorybox &&
										<>
											<>
												<div className="search_inventory_not_found">
													<div className='grid'>
														<div><b>Insert Into Inventory</b></div>
														<div>
															<select className="" name='category' onChange={e => this.choosecategory(e)}>
																<option value='' key="0">Choose a Category</option>
																<option value='-1' key="-1">Add New Category</option>
																{this.rendercats(cats,0)}
															</select>
														</div>
														{
															this.state.newItem.cat._id == -1 &&
															<>
																<div className="grid2">
																	<div>
																		<select className="" name='category' onChange={e => (this.setState({...this.state,newItem: {...this.state.newItem,cat:{...this.state.newItem.cat,pcat:e.target.value}}}))}>
																			<option value='' key="0">Choose a Parent Category</option>
																			{this.rendercats(cats,0)}
																		</select>
																	
																		<input type='text' onChange={e => (this.setState({...this.state,newItem: {...this.state.newItem,cat: {...this.state.newItem.cat,name:e.target.value}}}))} placeholder="Category Name" name="catname" /></div>
																	
																</div>
															</>
														}
														
														<div className="grid2">
															<div><input type='text' onChange={e => (this.setState({...this.state,newItem:{...this.state.newItem,price:e.target.value}}))} placeholder="Price" name="price" /></div>
															<div>
																{this.state.newItem.cat !== "" && this.state.newItem.price !== "" &&
																	<button onClick={this.addtoinventory}>Add to Inventory</button>
																}
															</div>
														</div>
													</div>
												</div>
											</>
										
										</>
										}
									</>}
									
								{items?.map((item) => {
									return(
									<div key={item?._id} className="search_inventory_list_item" onClick={e => this.select(item)}>
										<span>{item.computedName}</span>
										<span>{currencyFormat(item.price)}</span>
									</div>
									)})}
									<div className="search_inventory_list_item" onClick={e => this.select({name: this.state.search})}>
										<span style={{fontWeight: 'normal'}}>Select "<b>{this.state.search}</b>" however?</span>
										<span></span>
									</div>
									<div key="close" className="search_inventory_list_item" onClick={e => this.setState({...this.state,showSearchResults:false})}>
										<span>Close</span>
										<span></span>
									</div>
								</>
								}
								
							</div>
						</div>
					}
				</div>
			</>
		)
	}
}