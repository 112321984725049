import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { toastOptions, sort, tillTimeString } from 'utils/utils.jsx';

const EventContext = React.createContext();

class EventContextProvider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			events: [],
			selectedEvent: {},
			
			loadEvents: this.loadEvents,
			createUpdateEvent: this.createUpdateEvent,
			getEvent: this.getEvent,
			deleteEvent: this.deleteEvent,
			
			sort: this.sort,
		}
	}

	componentDidMount(){
		this.loadEvents()
		// console.log(this)
	}

	api_error  = () => {
		if( window.confirm('There were some error loading this page. Please refresh') ){
			window.location.reload()
		}
	}

	loadEvents = () => {
		fetch(`/api/event/list`,{
			method: 'POST'
		})
		.then(res => res.json())
		.then(events => {
			this.setState({...this.state, events, loading: false });
		})
		.catch(err =>{
			this.setState({...this.state, loading: false });
			this.api_error()
			console.log(err)
		})
	}

	createUpdateEvent = async ( event ) => {

		let toastID = toast.loading(`${event._id ?'Updating':'Creating'} Event...`)
		try{
			let req = await fetch(`/api/event/manage`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				method: 'POST',
				body: JSON.stringify(event)
			})
			let res = await req.json()
			if(res.res !== 'success'){
				toast.update(toastID, {...toastOptions, render: res.text, type: res.res})
				return event
			}

			let events = this.state.events
			if(event._id){
				// Update the updated event in the loacal array as well instead of fetching all events again.
				events = this.state.events.map(e => event._id == e._id ? event : e)
			}else{
				events.push(res)
			}
			toast.update(toastID, {...toastOptions, render: `Event ${event._id ?'Updated':'Created'}`, type: "success"})
			this.setState({...this.state, events})
			return res

		}catch(err){
			toast.update(toastID, {...toastOptions, render: "There were some issues creating this event", type: "error"})
			this.api_error()
			console.log(err)
			return event
		}
	}

	getEvent = async ( cid ) => {
		fetch(`/api/event/${cid}`, {
			method: 'GET',
		})
		.then(res => res.json())
		.then(res =>{
			//this.setState({...this.state, events });
			console.log(res)
		})
		.catch(err =>{
			this.api_error()
			console.log(err)
		})
	}

	deleteEvent = async ( cid ) => {

		let toastID = toast.loading("Deleting Event...")
		fetch(`/api/event/${cid}`, {
			method: 'DELETE',
		})
		.then(res => res.json())
		.then(res =>{
			toast.update(toastID, {...toastOptions, render: res.text, type: res.res})
			let events = this.state.events.filter(event => {
				if(event._id !== cid){
					return event
				}
			})
			this.setState({...this.state, events})
		})
		.catch(err =>{
			toast.update(toastID, {...toastOptions, render: "There were some error deleting this event", type: "error"})
			this.api_error()
			console.log(err)
		})
	}

	sort = (sortKey, desc) => {
		let events = sort(this.state.events,sortKey, desc)
		this.setState({...this.state, events})
	}
	

	render() {
		return (
			<EventContext.Provider value={this.state}>
				{this.props.children}
			</EventContext.Provider>
		);
	}
}

export { EventContext, EventContextProvider };