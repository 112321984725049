import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
// import reportWebVitals from './reportWebVitals';

// Contexts
import { UserContextProvider } from 'contexts/UserContext';
import { CustomerContextProvider } from 'contexts/CustomerContext';
import { InventoryContextProvider } from 'contexts/InventoryContext';
import { QuoteContextProvider } from 'contexts/QuoteContext';
import { EventContextProvider } from 'contexts/EventContext';
import { DashContextProvider } from 'contexts/DashboardContext';

import AuthenticatedAccess from './AuthenticatedAccess';

import Login from 'pages/Login/Login';
import Recover from 'pages/Recover/Recover';
import Quote from 'pages/Quote/Quote';
import Dashboard from 'pages/Dashboard/Dashboard';
import NotFound from 'pages/NotFound/NotFound';
import Quotes from 'pages/Quotes/Quotes';
import Jobs from 'pages/Jobs/Jobs';
import Invoices from 'pages/Invoices/Invoices';
import Customers from 'pages/Customers/Customers';
import Diary from 'pages/Diary/Diary';
import Users from 'pages/Users/Users';
import Inventory from 'pages/Inventory/Inventory';
import Settings from 'pages/Settings/Settings';
import Invoice from './pages/Invoice/Invoice';

ReactDOM.render(
	<Router>
		<React.StrictMode>
			<ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
			<UserContextProvider>
			<DashContextProvider>
				<Routes>
					<Route exact path="/" element={<Login />} />
					<Route exact path="/Recover" element={<Recover />} />
					<Route exact path="/quote" element={
						<QuoteContextProvider>
							<Quote />
						</QuoteContextProvider>
					} />
					<Route exact path="/invoice" element={
						<QuoteContextProvider>
							<Invoice />
						</QuoteContextProvider>
					} />
					<Route exact path="/dashboard" element={
						<AuthenticatedAccess>
							<DashContextProvider>
								<Dashboard />
							</DashContextProvider>
						</AuthenticatedAccess>
					} />
					<Route exact path="/quotes" element={
						<AuthenticatedAccess>
							<QuoteContextProvider>
								<Quotes />
							</QuoteContextProvider>
						</AuthenticatedAccess>
					} />
					
					<Route exact path="/jobs" element={
						<AuthenticatedAccess>
							<QuoteContextProvider>
								<Jobs />
							</QuoteContextProvider>
						</AuthenticatedAccess>
					} />
					<Route exact path="/invoices" element={
						<AuthenticatedAccess>
							<QuoteContextProvider>
								<Invoices />
							</QuoteContextProvider>
						</AuthenticatedAccess>
					} />
					
					<Route exact path="/customers" element={
						<AuthenticatedAccess>
							<CustomerContextProvider>
								<Customers />
							</CustomerContextProvider>
						</AuthenticatedAccess>
					} />
					<Route exact path="/diary" element={
						<AuthenticatedAccess>
							<EventContextProvider>
								<Diary />
							</EventContextProvider>
						</AuthenticatedAccess>
					} />
					<Route exact path="/users" element={
						<AuthenticatedAccess>
							<Users />
						</AuthenticatedAccess>
					} />
					<Route exact path="/Inventory" element={
						<AuthenticatedAccess>
							<InventoryContextProvider>
								<Inventory />
							</InventoryContextProvider>
						</AuthenticatedAccess>
					} />
					<Route exact path="/settings" element={
						<AuthenticatedAccess>
							<Settings />
						</AuthenticatedAccess>
					} />
					<Route path="*" element={<NotFound />} />
				</Routes>
				</DashContextProvider>
			</UserContextProvider>
		</React.StrictMode>
	</Router>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
