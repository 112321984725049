import { Component } from 'react';
import { DashboardContext } from 'contexts/DashboardContext';
import { AiOutlineDelete } from "react-icons/ai";
import Popup from 'components/Popup/Popup';
import {dateFormat} from "utils/utils";
import FadingDots from "react-cssfx-loading/lib/FadingDots";

import './AllActivities.scss';

export default class AllActivities extends Component {
	static contextType = DashboardContext;

	componentDidMount() {
		this.context.loadAllActivities()
	}

	componentWillUnmount() {
		this.context.unloadAllActivities()
	}

	render() {
		return (
			<Popup {...this.props}>
				<div className="all_activities_popup">
					<h2>All Activities</h2>
					{this.context.loading ? 
						<div className="preloader">
							<FadingDots width="70px" height="70px" color="#62d3f1" />
						</div>
						: 
						<div className="activities_list">
							{this.context.activities.map((activity, index) => {
								return (
									<div className="activity_item" key={index}>

										<div>
											<p>{activity.title}</p>
											<span className="created_at">{dateFormat(activity.createdAt,"nice")}</span>
										</div>
										<AiOutlineDelete onClick={e => this.context.deleteActivity(activity)} color="red" />
									</div>
								)
							})}
						</div>}
				</div>
			</Popup>
		)
	}
}