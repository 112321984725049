import React, { Component } from 'react';
import { QuoteContext } from 'contexts/QuoteContext';
import  PrintQuote  from 'components/PrintQuote/PrintQuote';
import { infoItems, infoinvoicepayments, infocustomeraddress} from "utils/utils";
import logo from 'assets/logo.png'
import './Invoice.scss';
import BarWave from 'react-cssfx-loading/lib/BarWave';

class Invoice extends Component {
	static contextType = QuoteContext;

    constructor(props){
        super(props)
        
        this.state = {
            disabled: false,
            invoice: {},
            local: {},
            quote: {},
            loading: true
        }
    }

    acceptInvoice = async (e) => {
        return
        this.setState({...this.state, disabled: true})
        await this.context.acceptInvoice()
        this.setState({...this.state, disabled: false})
    }
    invoiceTCS  = (invoice) =>{
        if(invoice.tcs) return(
            <>
            <h3>Terms & Conditions</h3>
                    <div className="terms_n_conditions">{invoice.tcs}</div>
            </>
            )
    }

    loadInvoiceDetails  = async () => {
        let invoice_id = new URLSearchParams(window.location.search).get('id')
        console.log("getting invoice:" , invoice_id)
        let invoice = await this.context.getPublicInvoice(invoice_id);
        if (invoice)
        {
             console.log("setting new state",invoice)
        this.setState({...this.state, invoice, quote:invoice.quote, loading: false})
        }
       
    }

	componentDidMount(){
		document.title = "Invoice Details"
        console.log("compomnent mounted")
        let invoice = this.loadInvoiceDetails()
	}

	render() {
        
        let invoice = this.state.invoice || {}
       
        let invoice_id = new URLSearchParams(window.location.search).get('id');
        
        if(this.state.loading) return(
            <div className="pagePreloader"><span><BarWave width="50px" height="20px"/><br />loading...</span></div>
        )
        if(!('_id') in invoice || !invoice_id) return(
            <div className="pagePreloader"><h3>Invoice Not found or broken link</h3></div>
        )
        
		return (
            <PrintQuote invoice={invoice} />
		)
	}
}

export default Invoice;
