import { Component } from 'react';
import { DashboardContext } from 'contexts/DashboardContext';
import { AiOutlineDelete } from "react-icons/ai";
import { GrSend } from "react-icons/gr";
import Popup from 'components/Popup/Popup';
import FadingDots from "react-cssfx-loading/lib/FadingDots";

import './AllMessages.scss';

export default class AllMessages extends Component {
	static contextType = DashboardContext;

	componentDidMount() {
		this.context.loadAllMessages()
	}

	componentWillUnmount() {
		this.context.unloadAllMessages()
	}

	render() {
		return (
			<Popup {...this.props}>
				<div className="all_messages_popup">
					<h2>All Messages</h2>
					<form method="POST" className="new_message_form" onSubmit={this.context.sendMessage}>
						<label>
							<span>Post a new message</span>
							<textarea name="message" placeholder="Type your message here..." required></textarea>
						</label>
						<button type="submit" disabled={this.context.sendingMessage}>Send <GrSend /></button>
					</form>
					{this.context.loading ? 
						<div className="preloader">
							<FadingDots width="70px" height="70px" color="#62d3f1" />
						</div>
						: 
						<div className="messages_list">
							{this.context.messages.map((message, index) => {
								return (
									<div className="message_item" key={index}>

										<div>
											<div style={{display: 'flex', justifyContent: 'space-between'}}>
												<strong>{message.creator?.fname} {message.creator?.lname}</strong>
												<span className="created_at">{new Date(message.createdAt).toLocaleString('en-GB')}</span>
											</div>
											<p>{message.message}</p>
										</div>
										<AiOutlineDelete onClick={e => this.context.deleteMessage(message)} color="red" />
									</div>
								)
							})}
						</div>
					}
				</div>
			</Popup>
		)
	}
}