import { Component } from 'react';
import { CreditNoteContext } from 'contexts/CreditNoteContext';
import { toast } from 'react-toastify';
import Flatpickr from "react-flatpickr";
import { currencyFormat } from "utils/utils";
import { IoMdArrowRoundBack } from 'react-icons/io';
import FadingDots from "react-cssfx-loading/lib/FadingDots";
import { toastOptions } from 'utils/utils.jsx';

export default class RecordPayment extends Component {
	static contextType = CreditNoteContext;

	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			loading: false,
			disabledForm: false,
			accounts: [],
			methods: [],
		}
	}

	DateOptions = {
		altInput: true,
		altFormat: "F j, Y",
		dateFormat: "Z",
		enableTime: false,
		required: true,
		allowInput: true,
	}

	friendlyDate = (dateStr) => {
		return new Date(dateStr)?.toString().substring(4, 15)
	}

	componentDidMount() {
		this.loadInfo()
	}

	loadInfo = () => {
		// return
		fetch('/api/accounting/invoice/get_payment_info')
			.then(res => res.json())
			.then(res => {
				this.setState({ ...this.state, accounts: res.accounts, methods: res.methods, loading: false, disabledForm: false })
			})
			.catch(e => {
				toast.error('An unknown error occured while loading data!')
				this.setState({ ...this.state, accounts: [], methods: [], loading: false, disabledForm: false })
				console.log(e)
			})
	}

	handleSubmit = e => {
		e.preventDefault()
		let form = e.target

		if (Number(form.amount.value) > (Number(this.props.creditNote.amount) - Number(this.props.creditNote.total_paid)) || Number(form.amount.value) < 0.1) {
			return toast.error('Amount must be between £0.1 and £' + (Number(this.props.creditNote.amount) - Number(this.props.creditNote.total_paid)))
		}

		this.setState({ ...this.state, disabledForm: true })
		let toastID = toast.loading('Recording Payment... Please wait...')

		fetch('/api/credit_note/record_payment', {
			method: 'POST',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify({
				creditNoteID: this.props.creditNote._id,
				amount: form.amount.value,
				bank_account_id: form.payment_account.value,
				payment_method_id: form.payment_method.value,
				reference: form.reference.value,
				date: form.payment_date.value
			})
		})
			.then(res => res.json())
			.then(res => {

				if (Array.isArray(res.paid) && res.paid[0] && res.paid[0]['$severity']) {
					let field = res.paid[0]['$source'] == 'base' ? '' : res.paid[0]['$source'];
					this.setState({ ...this.state, disabledForm: false })
					return toast.update(toastID, { ...toastOptions, type: 'error', render: field + ' ' + res.paid[0]['$message'] })
				}

				this.context.updateLoadedCreditNoteData(res.local)
				toast.update(toastID, { ...toastOptions, type: 'success', render: 'Payment recorded' })
				this.setState({ ...this.state, disabledForm: false })
				this.props.hideMain()
			})
			.catch(e => {

				toast.update(toastID, { ...toastOptions, type: 'error', render: 'An unknown error has occured' })
				this.setState({ ...this.state, disabledForm: false })
			})
	}

	render() {

		if (this.state.loading) {
			return <div className="pagePreloader" style={{ width: '100%', height: '100%' }}><FadingDots width="100px" height="100px" color="#62d3f1" /></div>
		}
		let accounts = Array.isArray(this.state.accounts) ? this.state.accounts : []
		let methods = Array.isArray(this.state.methods) ? this.state.methods : []
		return (
			<div className="record_payment_container">
				<form disabled onSubmit={this.handleSubmit} className="record_payment_form">
					<h3 style={{ display: 'flex', alignItems: 'center', gap: '15px' }}><IoMdArrowRoundBack style={{ color: '#555' }} onClick={this.props.hide} className="cursor_pointer" /> Record Payment</h3>
					<label>
						<span>Amount Refunded</span>
						<input type="number" name="amount" value={this.props.creditNote.total_amount} placeholder="£0.00" step=".01" required />
					</label>
					<label>
						<span>Date refunded</span>
						<Flatpickr options={this.DateOptions} name='payment_date' value={new Date()} required />
					</label>
					<label>
						<span>Paid from</span>
						<select name="payment_account" required>
							{accounts.map((account, i) => {
								return <option key={i} value={account.id}>{account.displayed_as}</option>
							})}
						</select>
					</label>
					<label>
						<span>Payment Method</span>
						<select name="payment_method" required>
							{methods.map((method, i) => {
								return <option key={i} value={method.id}>{method.displayed_as}</option>
							})}
						</select>
					</label>
					<label>
						<span>Reference (Optional & Maximum 25 characters)</span>
						<input type="text" maxLength={25} placeholder="#1234abcd" name="reference" />
					</label>
					<div className="form_button_wrapper">
						<button type='submit' disabled={this.state.disabledForm}>Record Payment</button>
					</div>
				</form>
			</div>
		)
	}
}