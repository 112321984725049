import { Component } from 'react';
import Flatpickr from "react-flatpickr";
import { QuoteContext } from 'contexts/QuoteContext';
import Popup from 'components/Popup/Popup';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import {BsCashCoin} from "react-icons/bs";
import {FaSave} from "react-icons/fa";
import {GiCancel} from "react-icons/gi";
import './CreditNotePopup.scss';
import { toast } from 'react-toastify';
import RecordPayment from './RecordPayment';
import {dateFormat, DateOptions, currencyFormat} from "utils/utils";

export default class CreditNotePopup extends Component {
	static contextType = QuoteContext;

	constructor(props){
		super(props)
		let today = dateFormat(new Date(),"nice");

		let newblankline = { 
			description: '', 
			ledger_account_id: process.env.CREDIT_NOTE_LEDGER_ACCOUNT,
			quantity: 1, 
			unit_price: 0, 
			discount_amount: 0, 
			tax_amount: 0,
			currency_tax_amount:0,
			total_amount:0
		}

		this.state = {
			creditnote: {
				...this.props.creditNote,
				date: dateFormat(this.props.creditNote.date,"nice") || dateFormat(today,"nice"),
				notes:this.props.creditNote.notes || "",
				credit_note_lines : [...this.props.creditNote.credit_note_lines,newblankline] || [newblankline],
				terms_and_conditions:this.props.creditNote.terms_and_conditions || "",
				},

			quote:this.props.quote
		}
	}

	handleSubmit = async (e) => {
		e.preventDefault()
		
		let creditnote = this.state.creditnote;
		let credit_note_lines = creditnote.credit_note_lines || [{}];
		let newcredit_note_lines = [];

		let invalidNote = false;
		credit_note_lines.forEach(item => {
			
				let amounts = this.context.getTaxAndTotal(item);
				
				if(item.description == '' || amounts.tax > amounts.total || Number(amounts.quantity) <= 0 || amounts.total < 0 ){
					invalidNote = true;
				}
				else{
					newcredit_note_lines.push(item);
				}
		})

		if(newcredit_note_lines.length <= 0){
			return toast.error('Please add at least one valid line item')
		}
		newcredit_note_lines.push({});
		this.setState(
			{...this.state,
				disabled: true,
				creditnote:{
					...this.state.creditnote,
					credit_note_lines:newcredit_note_lines
				}
			}
		);

		let req = {};
		
		if(creditnote.sageID){
			
			req = await this.context.updateCreditNote(this.state)
		}else{
			
			req = await this.context.createCreditNote(this.state);
			
		}
		
		this.setState({...this.state, disabled: false})
		if(req.res == 'success'){
			this.props?.hide()
			// this.props?.hideQuote()
		}
	}
	
	preventSubmit = (e) => {
		if(e.keyCode === 13 && e?.target?.nodeName === 'INPUT'){
			e.preventDefault()
		}
	}
	
	handleItemInput = (e, i) => {
		let creditnote = this.state.creditnote;
		let emptylineitem = { 
			description: '', 
			ledger_account_id: '0bec6590b99e11ecb2850279d0cc53c5',
			quantity: 1, 
			unit_price: 0, 
			discount_amount: 0, 
			tax_amount: 0,
			currency_tax_amount:0,
			total_amount:0,
			tax_rate_id: 'GB_STANDARD'
		};
		let field = e.target, credit_note_lines = creditnote.credit_note_lines || [emptylineitem] ;
		if (field.name === "date")
		{
			creditnote.date = dateFormat(e.target.value);
		}
		else if (field.name === "terms_and_conditions")
		{
			creditnote.terms_and_conditions = e.target.value;
		}
		else if (field.name === "notes")
		{
			creditnote.notes = e.target.value;
		}
		else if (field.name === "unit_price")
		{
			credit_note_lines[i][field.name] = parseFloat(field.value);
		}
		
		else{
			credit_note_lines[i][field.name] = field.value;
		}
		
		if((i+1) == credit_note_lines.length ){	
			credit_note_lines.push( { 
				description: '', 
				ledger_account_id: '0bec6590b99e11ecb2850279d0cc53c5',
				quantity: 1, 
				unit_price: 0, 
				discount_amount: 0, 
				tax_amount: 0,
				currency_tax_amount:0,
				total_amount:0,
				tax_rate_id: 'GB_STANDARD'
			})
		}

		let itemtaxdetails = this.context.getTaxAndTotal(credit_note_lines[i]);
			
			if (itemtaxdetails)
			{
				credit_note_lines[i]["unit_price"] = parseFloat(credit_note_lines[i].unit_price);
				credit_note_lines[i]["tax_amount"] = parseFloat(itemtaxdetails.tax);
				credit_note_lines[i]["total_amount"] = parseFloat(itemtaxdetails.total);
			}
			
		this.setState({...this.state, creditnote:{...this.state.creditnote,credit_note_lines}})
	}

	handleInput = (e) => {
		let creditnote = this.state.creditnote;
	
		let field = e.target ;
		if (field.name === "date")
		{
			creditnote.date = dateFormat(e.target.value,"nice");
		}
		else if (field.name === "terms_and_conditions")
		{
			creditnote.terms_and_conditions = e.target.value;
		}
		else if (field.name === "notes")
		{
			creditnote.notes = e.target.value;
		}
		
		this.setState({...this.state, creditnote:{...this.state.creditnote}})
	}

	removeLineItem = index =>{
		
		let creditnote = this.state.creditnote;
		let credit_note_lines = creditnote.credit_note_lines;

		delete credit_note_lines[index]
		credit_note_lines = credit_note_lines.filter(i => i)
		this.setState({...this.state, creditnote:{...creditnote,credit_note_lines}})
	}

	setHeight = e => {
		if(e?.target?.nodeName === 'TEXTAREA'){
			e.target.style.height = (e.target.scrollHeight < 40 ? 40 : e.target.scrollHeight) + 'px'
		}
	}
	restoreHeight = e => {
		if(e?.target?.nodeName === 'TEXTAREA'){
			e.target.style.height = '40px'
		}
	}

	handleDate = (selectedDates, thisdate, instance,fieldname) => {
		let thiscreditnote = this.state.creditnote;
		if (Array.isArray(selectedDates))
		{
			selectedDates = selectedDates[0];
		}
		if (fieldname === "date")
		{
			thiscreditnote.date = dateFormat(selectedDates,"nice");
		}
		
		this.setState({...this.state, creditnote: thiscreditnote})
	}

	voidCreditNote = async (e) => {
		e.preventDefault()
		let creditnote = this.state.creditnote;
		this.setState({...this.state, voidingCreditNote: true})
		
		let res = await this.context.voidCreditNote(this.state)
		if (res.res == "success")
		{
			this.setState({...this.state, creditnote: res.creditnote, void:false, voidingCreditNote: false})
		}
		
		//this.props.hide()
	}

	voidReason = () => {
		return (
			<div className="void_note_form">
				<form onSubmit={this.voidCreditNote}>
					<h2>Void Reason</h2>
					<span>Please type in the reason to void the credit note.</span>
					<textarea name="reason" onChange={e => {this.setState({...this.state, creditnote:{...this.state.creditnote, void_reason:e.target.value}})}} placeholder="Reason to void this credit note" required={true} maxLength="50"></textarea>

					<div className="buttons form_button_wrapper">
						<button type="button" className="button cancel" onClick={e => this.setState({...this.state, void: false})}>Cancel</button>
						<button onClick={this.voidCreditNote} className="button success" disabled={this.state.voidingCreditNote}>Void</button>
					</div>
				</form>
			</div>
		)
	}


	render() {
		
		let creditnote = this.state.creditnote;
	
		let credit_note_lines = creditnote.credit_note_lines || [{}];
		
	

		let thissubtitle = null;
		
		thissubtitle = creditnote.status == "VOID" ? <><div className='subtitle'><h3 className="void">VOID</h3> <br />{dateFormat(creditnote.void_date,"nice")}<br /> {creditnote.void_reason && <> Reason: {creditnote.void_reason}<br /><br /></>}</div></> : null;
			
		let itemsTotalPrice = credit_note_lines.length > 0 ? credit_note_lines.reduce((total, item) => (total + this.context.getTaxAndTotal(item).total), 0) : 0
		return (
			<>
				<Popup {...this.props} className="creditnote_popup" hide={this.props.hide}>
					{this.state.void && this.voidReason()}
					{this.state.recordPayment && <RecordPayment hide={e => this.setState({...this.state, recordPayment: false})} hideMain={this.props.hide} creditNote={creditnote} />}
					<form onSubmit={this.handleSubmit}>
						<div className='title'><h2>{creditnote.sageID ? 'Edit' : 'Create'} Credit Note </h2> {thissubtitle}</div>
						
						<h3>Items</h3>
						<div className="table creditnote_items">
							<div className="table_headings">
								<div>#</div>
								<div className="th">Description</div>
								<div className="th">Ledger Account</div>
								<div className="th">Quantity</div>
								<div className="th">Price</div>
								<div className="th">Discount</div>
								<div className="th">VAT Rate</div>
								<div className="th">VAT Amount</div>
								<div className="th">Total</div>
								<div className="th"></div>
							</div>
							<div className="table_body">
								{
								credit_note_lines.map((item, i) => {
									
									let amounts = this.context.getTaxAndTotal(item)
									
									return(
										<div className="tr" key={i}>
											<div className="td">{i+1}</div>
											<div className="td"><textarea type='text' name='description' required={(i+1) != credit_note_lines.length} defaultValue={item.description} onChange={e => this.handleItemInput(e, i)} onKeyUp={this.setHeight} onFocus={this.setHeight} onBlur={this.restoreHeight}></textarea></div>
											<div className="td">
												<select name='ledger_account_id' defaultValue={item.ledger_account_id} onChange={e => this.handleItemInput(e, i)}>
												<option>Please Choose</option>
													{this.context?.ledgerAccounts()?.map((account, j) => {
														return <option key={j} value={account.id}>{account.displayed_as}</option>
													})}
												</select>
											</div>
											<div className="td"><input type='number'  placeholder='0.00' required={(i+1) != credit_note_lines.length} name="quantity" defaultValue={item.quantity} onKeyDown={e => this.preventSubmit(e)}  onChange={e => this.handleItemInput(e, i)}/></div>
											<div className="td"><input type='number' step="0.1" placeholder='0.00' required={(i+1) != credit_note_lines.length} name="unit_price" defaultValue={item.unit_price} onKeyDown={e => this.preventSubmit(e)}  onChange={e => this.handleItemInput(e, i)}/></div>
											<div className="td"><input type='number' step="0.1" placeholder='0.00' name="discount_amount" defaultValue={item.discount_amount} onKeyDown={e => this.preventSubmit(e)}  onChange={e => this.handleItemInput(e, i)}/></div>
											<div className="td">
												<select name='tax_rate_id' defaultValue={item.tax_rate_id} onChange={e => this.handleItemInput(e, i)}>
													<option>Please Choose</option>
													{this.context?.taxRates()?.map((rate, j) => {
														return <option key={j} value={rate.id}>{rate.displayed_as}</option>
													})}
												</select>
											</div>
											<div className="td"><input value={amounts.tax} disabled/></div>
											<div className="td"><input value={amounts.total} disabled/></div>
											{credit_note_lines.length != (i+1) && <div className="td" onClick={e => this.removeLineItem(i)}><AiOutlineCloseCircle color='red' className='cursor_pointer'/></div>}
										</div>
									)
								})}
							</div>
						</div>

						<h3 style={{textAlign:'right', fontSize: '20px'}}>Total: {currencyFormat(itemsTotalPrice)}</h3>

						<div className="creditnote_dates">
							<label>
								<span>Date</span>
								<Flatpickr options={DateOptions} name='date' onChange={(selectedDates, dateStr, instance) => {this.handleDate(selectedDates, dateStr, instance,"date")}}   value={new Date(creditnote.date || new Date())} required />
							</label>
						</div>
						
						<div className="bi_columns creditnote_description">
							<label>
								<span>Additional description</span>
								<textarea name="notes" placeholder="Credit note description" onChange={e => this.handleInput(e)} defaultValue={creditnote.notes}></textarea>
							</label>
							
							<label>
								<span>Terms and Conditions</span>
								<textarea name="terms_and_conditions" placeholder="Terms and Conditions (Optional)" onChange={e => this.handleInput(e)} defaultValue={creditnote.terms_and_conditions}></textarea>
							</label>
						</div>
						{(!creditnote.status || creditnote.status == 'UNPAID') &&
						<div className="form_button_wrapper">
							{creditnote?.sageID &&<>
								{ 1==2 &&
									<button disabled={this.state.disabled} className="convert" type="button" onClick={e => this.setState({...this.state, recordPayment: true})}><BsCashCoin/>Record Payment</button>
								}
								<button disabled={this.state.disabled} className="cancel" type="button" onClick={e => this.setState({...this.state, void: true})}><GiCancel/>Void Credit Note</button>
							</>}
							<button onClick={this.handleSubmit} disabled={this.state.disabled}><FaSave />{creditnote.sageID ? 'Save' : 'Create'} Credit Note</button>
						</div>}
					</form>
				</Popup>
			</>
		)
	}
}