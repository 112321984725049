import { Component } from 'react';
import { Navigate } from 'react-router-dom';
import Wrapper from 'pages/Wrapper';
import { InventoryContext } from 'contexts/InventoryContext';
import { HiUserAdd } from 'react-icons/hi';
import { MdOutlineProductionQuantityLimits, MdOutlineCategory, MdSave, MdEdit, MdDelete } from 'react-icons/md';
import FadingDots from "react-cssfx-loading/lib/FadingDots";
import { currencyFormat, dateFormat, DateOptions } from "utils/utils";

import './Inventory.scss';

export default class Inventory extends Component {
	static contextType = InventoryContext;

	constructor(props) {
		super(props);

		this.state = {
			showAddForm: '',
			objBeingEdited: {},
		}
	}



	structuredInventory = (inventory) => {
		let cats = inventory.cats
		let children = []
		let items = []

		cats = cats.map(cat => {

			cat.items = inventory.items.filter(item => {
				if (item.cat == cat._id) {
					items.push(item._id)
					return true
				}
			})
			cat.children = cats.filter(c => c.parent == cat._id)
			cat.children.forEach(cat => children.push(cat._id))
			return cat
		})
		if (items.length > 0) {
			let tempCat = { name: 'Uncategorized', _id: 'Uncategorized', items: [] }
			inventory.items.forEach(item => {
				if (!items.includes(item._id)) {
					tempCat.items.push(item)
				}
			})
			if (tempCat.items.length > 0) {
				cats.push(tempCat)
			}
		}

		let structured = cats.filter(cat => !children.includes(cat._id))
		return structured
	}

	addUpdateItem = async (item) => {
		console.log("addUpdateItem: ", item)
		this.context.createUpdateItem({ ...this.state.objBeingEdited, ...item })
	}

	addUpdateCat = async (cat) => {
		this.context.createUpdateCat({ ...this.state.objBeingEdited, ...cat })
	}

	showAddEditCat = (objBeingEdited = {}) => {
		let showAddForm = objBeingEdited._id ? 'cat' : this.state.showAddForm == 'cat' ? '' : 'cat'
		this.setState({ ...this.state, showAddForm, objBeingEdited })
		this.scrollAndFocus()
	}

	showAddEditItem = (objBeingEdited = {}) => {
		let showAddForm = objBeingEdited._id ? 'item' : this.state.showAddForm == 'item' ? '' : 'item'
		this.setState({ ...this.state, showAddForm, objBeingEdited })
		this.scrollAndFocus()
	}

	scrollAndFocus = () => {
		let form = document.querySelector('#inventoryEditorForm')
		if (!form) return
		form.scrollIntoView({ behavior: 'smooth' })
		setTimeout(() => {
			form[0]?.focus()
			form.animate([{ background: '#62d3f1' }, { background: 'transparent' }], { duration: 1000, iterations: 1 })
		}, 250)
	}

	componentDidMount() {
		document.title = 'Inventory - Manage Categories and Items';
	}

	itemsListing = (items) => {

		return (
			<div className="table inventory_items_table">
				<div className="table_headings">
					<div className="th sortable_th">Name</div>
					<div className="th sortable_th">Price</div>
					<div className="th sortable_th">Created Date</div>
					<div className="th sortable_th">Last Modified Date</div>
					<div className="th"></div>
				</div>
				<div className="table_body">
					{items.map((item, index) => {
						return (
							<div key={index} className="tr">
								<div className="td">{item.name}</div>
								<div className="td">{currencyFormat(item.price)}</div>
								<div className="td">{dateFormat(item.createdAt, "nice")}</div>
								<div className="td">{dateFormat(item.updatedAt, "nice")}</div>
								<div className="td">
									<button className="icon_btns" onClick={e => this.showAddEditItem(item)}><MdEdit /></button>
									<button className="icon_btns" onClick={e => this.context.deleteItem(item._id)}><MdDelete /></button>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		)
	}

	catListing = (cats) => {
		return cats.map(cat => {

			return (
				<div key={cat._id} className="category_block">
					<div className="category_content_block">
						<div className="cat_title_container">
							<h5>{cat.name}</h5>
							{cat._id != 'Uncategorized' &&
								<div>
									<button className="icon_btns" onClick={e => this.showAddEditCat(cat)}><MdEdit /></button>
									<button className="icon_btns" onClick={e => this.context.deleteCat(cat._id)}><MdDelete /></button>
								</div>
							}
						</div>
						<div className="cat_items">
							{cat.items?.length > 0 && this.itemsListing(cat.items)}
						</div>
					</div>

					{cat.children?.length > 0 &&
						<div className="child_categories_block">
							{this.catListing(cat.children)}
						</div>}
				</div>
			)
		})
	}

	getParentCat = (catid) => {

		let cat = this.context.inventory.cats.find(c => c._id == catid)
		if (cat) {

			if (catid == cat.parent) {
				//recursive ???
				return cat.name + ' / ';
			}
			let parent = this.getParentCat(cat.parent)
			if (parent) {
				return parent + cat.name + ' / '
			} else {
				return cat.name + ' / '
			}
		} else {
			//console.log("none found");
			return ''
		}
	}

	getSelCats = (pcatid, items) => {

		let selcats = "";
		this.context.inventory.cats.map((cat) => {
			let catparent = cat.parent ? cat.parent : "";

			if (catparent == pcatid) {
				let children = this.getSelCats(cat._id, []);
				items.push({ "value": cat._id, "children": children, "label": cat.name });
			}
		})

		return items;
	}

	categoryDropDown = (catid, levels) => {
		let cats = this.context.inventory.cats;

		let catshtml = [];
		let spacer = "";


		for (let a = 0; a < levels; a++) {
			spacer += "---";
		}

		cats.map((cat, key) => {

			let thisvalue = cat._id;
			let thiscatid = cat._id;
			let thispcatid = cat.parent;

			if (catid == thispcatid || (catid == "" && !thispcatid)) {
				let thislabel = spacer + " " + cat.name;
				catshtml.push(<option key={thiscatid} value={thisvalue} >{thislabel}</option>);
				catshtml.push(this.categoryDropDown(thiscatid, levels + 1));
			}


		});

		return catshtml;
	}

	Form = () => {
		let handleSubmit = (e) => {
			e.preventDefault()
			let data = {};
			[...e.target].forEach(field => {
				if (field.name == "cat") {
					data.cat = { _id: field.value }
				}
				else {
					data[field.name] = field.value
				}

			})


			this.state.showAddForm == 'cat' ? this.addUpdateCat(data) : this.addUpdateItem(data)
		}
		console.log("this.state.objBeingEdited: ", this.state.objBeingEdited)
		let name = this.state.showAddForm == 'cat' ? 'Category' : 'Item'
		return (
			<div className="add_inventory_section">
				<div className="add_item_section">
					<form onSubmit={handleSubmit} id="inventoryEditorForm" >
						<label>
							<span>{name} Name</span>
							<input type="text" autoComplete="off" name="name" key={this.state.objBeingEdited.name} placeholder="Item Name" defaultValue={this.state.objBeingEdited.name} required={true} />
						</label>
						{this.state.showAddForm == 'item' &&
							<label>
								<span>{name} Price</span>
								<input type="number" step="any" key={this.state.objBeingEdited.name} name="price" placeholder="Item Price" defaultValue={this.state.objBeingEdited.price} required={true} />
							</label>}
						<label>
							{this.state.showAddForm == 'item' && <span>Category</span>}
							{this.state.showAddForm == 'cat' && <span>Parent Category</span>}

							<select className="" name={this.state.showAddForm == 'cat' ? 'parent' : 'cat'} defaultValue={this.state.showAddForm == 'item' ? this.state.objBeingEdited.cat : this.state.objBeingEdited.parent} key={this.state.objBeingEdited.name}>
								<option value='' key="0">Choose a Category</option>

								{this.categoryDropDown("", 0)}
							</select>


						</label>
						<button type="submit" ><MdSave /> Save</button>
					</form>
				</div>
			</div>
		)
	}

	render() {

		let inventory = this.structuredInventory(this.context.inventory)

		return (
			<Wrapper>
				<div id="inventory_page_container">
					<div className="inventory_page_header">
						<button className="add_category_button" onClick={e => this.showAddEditCat()}>
							<MdOutlineCategory />
							Add Category
						</button>
						<button className="add_item_button" onClick={e => this.showAddEditItem()}>
							<MdOutlineProductionQuantityLimits />
							Add Item
						</button>
					</div>
					{this.state.showAddForm && this.Form()}
					{inventory.length > 0 && <h2>Inventory Hierarchy</h2>}

					{this.context.loading &&
						<div className="preloader">
							<FadingDots width="70px" height="70px" color="#62d3f1" />
						</div>}


					{this.catListing(inventory)}
				</div>
			</Wrapper>
		)
	}
}