import React, { useEffect, useRef, useState } from 'react';

export const paymentterms = 30;

export const toastOptions = {
    isLoading: false,
    autoClose: 5000,
    hideProgressBar: false,
    draggable: true,
    closeOnClick: true,
}

export const DateOptions = {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d", // how its displayed
    enableTime: false,
    required: true,
    allowInput: true,
}

export const daysoverdue_str = (outStandingAmount, due_date) => {

    let overduestr = outStandingAmount > 0 ? (Math.abs(daysoverdue(due_date)) == 0) ? "Invoice Due Today" : (daysoverdue(due_date) > 0) ? "" + daysoverdue(due_date) + " days overdue" : "Invoice Due In " + Math.abs(daysoverdue(due_date)) + " days" : "";
    return overduestr;
}

export const daysoverdue = (due_date, inc_str = false) => {

    let today = new Date();
    let duedate = new Date(due_date)
    let str;
    let days = Math.floor((today - duedate) / (24 * 3600 * 1000));

    if (inc_str) {
        if (days > 0) {
            return days + " overdue"
        }
        else {
            return days + " underdue"
        }
    }
    return days;

}

export const toNumber = (num) => {
    if (typeof (num) == "undefined") { return 0; }
    num = +num;

    num = +num.toFixed(2)
    return +num
}

export const quoteitemstotal = (items) => {
    return items?.reduce((total, item) => (
        !isNaN(item.price) ? (total += (Number(item.price) * (item.qty) ? Number(item.qty) : 1))
            : total
    ), 0)
}

export const ControlledInput = (props) => {
    const { value, onChange, ...rest } = props;
    const [cursor, setCursor] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        const input = ref.current;
        if (input) input.setSelectionRange(cursor, cursor);
    }, [ref, cursor, value]);

    const handleChange = (e) => {
        setCursor(e.target.selectionStart);
        onChange && onChange(e);
    };

    return <input ref={ref} value={value} onChange={handleChange} {...rest} />;
};

export const getTotalPaid = (job) => {

    let totalpaid = 0;

    totalpaid = job.invoices?.reduce((total, invoice) => (
        total += invoice.payments ? invoice.payments?.reduce((total, payment) => (
            (!isNaN(payment.payment_amount)) ? (total += (Number(payment.payment_amount)))
                : total
        ), 0) : 0
    ), 0);

    return totalpaid
}

export const getTotalInvoiced = (job) => {
    console.log("job", job._id, job)
    return job.invoices?.reduce((total, invoice) => (
        (!isNaN(invoice.amount)) ? (total += (Number(invoice.amount)))
            : total
    ), 0)
}

export const getTotalInvoiceAmount = (invoice) => {

    let invoiceAmount = invoice.invoice_lines?.reduce((total, item) => (

        total + (
            item.total_amount ?
                Number(item.total_amount.toFixed(2))
                : 0
        )
    ), 0);

    return isNaN(invoiceAmount) ? 0 : Number(invoiceAmount).toFixed(2);

}


export const getTotalInvoiceNETAmount = (invoice) => {

    let invoiceAmount = invoice.invoice_lines?.reduce((total, item) => (
        total + ((item.price_net) * item.qty)
    ), 0);

    return isNaN(invoiceAmount) ? 0 : parseFloat(invoiceAmount).toFixed(2);

}


export const getTotalInvoiceTAXAmount = (invoice) => {

    let invoiceAmount = invoice.invoice_lines?.reduce((total, item) => (
        total + ((item.tax_amount) * item.qty)
    ), 0);

    return isNaN(invoiceAmount) ? 0 : parseFloat(invoiceAmount).toFixed(2);

}


export const getTotalInvoiceCredits = (invoice) => {

    let creditAmount = invoice.creditnotes?.reduce((total, creditnote) => (
        (!isNaN(creditnote.total_amount)) ? (total += (parseFloat(creditnote.total_amount)))
            : total
    ), 0);

    return isNaN(creditAmount) ? 0 : parseFloat(creditAmount).toFixed(2);

}

export const getTotalCredited = (quote) => {

    let creditAmount = quote.creditnotes?.reduce((total, creditnote) => (

        (!isNaN(creditnote.total_amount)) ? (total += (parseFloat(creditnote.total_amount)))
            : total
    ), 0);

    return isNaN(creditAmount) ? 0 : creditAmount.toFixed(2);

}

export const getInvoiceOutstanding = (invoice) => {


    let invoiceAmount = getTotalInvoiceAmount(invoice);
    let invoicePayments = getTotalInvoicePayments(invoice);
    let invoiceCredits = getTotalInvoiceCredits(invoice);

    let outstanding = (parseFloat(invoiceAmount) + parseFloat(invoiceCredits)) - parseFloat(invoicePayments);

    return isNaN(outstanding) ? 0 : parseFloat(outstanding).toFixed(2);

}

export const getTotalQuoteInvoiced = (invoices) => {

    if (!invoices) return 0;

    let invoicedAmount = invoices.reduce((total, invoice) => (
        (!isNaN(invoice.total_amount) && invoice.status != "VOID") ? (total += (parseFloat(invoice.total_amount)))
            : total
    ), 0);

    return isNaN(invoicedAmount) ? 0 : parseFloat(invoicedAmount).toFixed(2);

}

export const getTotalInvoicePayments = (invoice) => {

    let paymentAmount = invoice.payments?.reduce((total, payment) => (
        (!isNaN(payment.payment_amount)) ? (total += (parseFloat(payment.payment_amount)))
            : total
    ), 0);

    return isNaN(paymentAmount) ? 0 : parseFloat(paymentAmount).toFixed(2);

}



export const getVAT = (amount, taxrateid, minusoradd) => {
    let mult = 1;
    if (taxrateid == "GB_STANDARD") {
        mult = 1.2
    }
    else if (taxrateid == "GB_LOWER") {
        mult = 1.05
    }
    if (minusoradd == "minus") {
        return amount - (amount / mult);
    }
    else {
        return (amount * mult) - amount;
    }

}


export const getTotalQuotePaid = (invoices) => {
    let total = 0;
    let paidAmount = invoices.reduce((total, invoice) => (
        total += parseFloat(getTotalInvoicePayments(invoice))
    ), 0);
    return paidAmount;
}

export const thistypename = (thistype = "") => {

    if (thistype === "job") {
        return "Job";
    }
    else if (thistype === "quote") {
        return "Quote";
    }
    else if (thistype === "invoice") {
        return "Invoice";
    }
    else {
        return "";
    }
}

export const defaultTCS = () => {
    let tcsstr = `<p><strong>Bank Payments</strong></p><p>Sort Code: 20-97-48</p><p>Account Number: 50237043</p><p>ALL OUTSTANDING INVOICE BALANCES MUST BE PAID EITHER BEFORE FITTING OR ON THE DAY OF FITTING</p><p>All prices include vat, unless shown separately.</p><p>An additional charge will be made by the fitter, if required to:</p><p>Uplift and remove old floor coverings; Move furniture or kitchen appliances; and cut doors (We can only cut standard internal doors). These things may already be included in your invoice if you have requested them.</p><p>- Please ask for further details</p><p>Although we are always very careful when fitting new floor coverings, you may need to touch up skirting boards, woodwork and walls after fitting as modern paintwork can flake off when getting the carpet into the room and during the fitting process.</p><p>Please Note - If you have selected a thinner floor covering than you currently have fitted, you may need to attend to any gaps under the skirting as we cannot be held responsible for this. Please talk to us on site if you have any concerns.</p><p>All fitting is guaranteed by Carpet Options Ltd. Fitting Date to be agreed upon receipt of the materials; Carpet Options Ltd will not be held responsible for delays caused due to circumstances beyond their control. All materials remain the property of Carpet Options Ltd until paid in full.</p><p>We accept most major Credit/Debit cards.</p><p>Payment is to be made on the day of fitting and no later, The easiest way to pay is via bank transfer (See details below) but you can also pay over the phone with your card when the fitter has finished, You can pay the fitter cheque or cash on site or Alternatively, you can come in to the store once the fitter has finished and use any of these methods.</p><p>Please be aware that overdue invoices will be collected by a third party and will incur costs and interest.</p><p></p>`;
    let whybuystr = `<p><strong>Why Buy From Carpet Options</strong></p><p>We are a family run business, we were established in 1983 and we have over 40 years experience in the industry.</p><p>We are members of the Carpet Foundation and we strictly follow their code of practice giving you complete safety when making your purchase.</p><p>We will never mislead you with our pricing - We give you our best prices first time and all year round.</p><p>We use industry specific planning software and systems to make sure you have the least amount of wastage/carpet loss as physically possible.</p><p>If you do have any waste carpet left over from your job we can arrange for these to be made into rugs for you for a small charge.</p><p>To remove any stress involved when replacing your floor coverings - We will undertake the moving of furniture, uplifting and removing of old floor coverings and trimming of bottoms of doors. We may have already included this in your price or we can add this in for you at a reasonable cost(please ask for details).</p><p>We will endeavor to leave your property clean and tidy.</p><p>All our fitting work is guaranteed by Carpet Options Limited, should there ever be a problem we will sort it out for you. However, our fitters have been with us a number of years and are totally trustworthy with excellent reputations.</p><p>All of us here at Carpet Options look forward to working together with you to make sure your job is carried out as smooth as possible and to the highest standard.</p>`;
    return ({ tcs: tcsstr, whybuy: whybuystr, both: tcsstr + `<div class="page-break"></div>` + whybuystr });
}

export const addCountryCode = (number, country) => {
    if (number.substr(0, 1) == "0") {
        if (country == "UK" || !country) {
            number = "+44" + number.substr(1);
        }
    }

    return number;
}

export const infoTcs = (quote, tcs_type) => {

    if (quote.tcs) {
        return quote.tcs;
    }
    let defaultTCs = defaultTCS();
    // let tcsstr = `<p>ALL OUTSTANDING INVOICE BALANCES MUST BE PAID EITHER BEFORE FITTING OR ON THE DAY OF FITTING</p><p>All prices include vat, unless shown separately.</p><p>An additional charge will be made by the fitter, if required to:</p><p>Uplift and remove old floor coverings; Move furniture or kitchen appliances; and cut doors (We can only cut standard internal doors). These things may already be included in your invoice if you have requested them.</p><p>- Please ask for further details</p><p>Although we are always very careful when fitting new floor coverings, you may need to touch up skirting boards, woodwork and walls after fitting as modern paintwork can flake off when getting the carpet into the room and during the fitting process.</p><p>Please Note - If you have selected a thinner floor covering than you currently have fitted, you may need to attend to any gaps under the skirting as we cannot be held responsible for this. Please talk to us on site if you have any concerns.</p><p>All fitting is guaranteed by Carpet Options Ltd. Fitting Date to be agreed upon receipt of the materials; Carpet Options Ltd will not be held responsible for delays caused due to circumstances beyond their control. All materials remain the property of Carpet Options Ltd until paid in full.</p><p>We accept most major Credit/Debit cards.</p><p>Payment is to be made on the day of fitting and no later, The easiest way to pay is via bank transfer (See details below) but you can also pay over the phone with your card when the fitter has finished, You can pay the fitter cheque or cash on site or Alternatively, you can come in to the store once the fitter has finished and use any of these methods.</p><p>Please be aware that overdue invoices will be collected by a third party and will incur costs and interest.</p><p></p><p><strong>Bank Payments</strong></p><p>Sort Code: 20-97-48</p><p>Account Number: 50237043</p><p></p>`;
    // let whybuystr = `<p><strong>Why Buy From Carpet Options</strong></p><p>We are a family run business, we were established in 1983 and we have over 30 years experience in the industry.</p><p>We are members of the Carpet Foundation and we strictly follow their code of practice giving you complete safety when making your purchase.</p><p>We will never mislead you with our pricing - We give you our best prices first time and all year round.</p><p>We use industry specific planning software and systems to make sure you have the least amount of wastage/carpet loss as physically possible.</p><p>If you do have any waste carpet left over from your job we can arrange for these to be made into rugs for you for a small charge.</p><p>To remove any stress involved when replacing your floor coverings - We will undertake the moving of furniture, uplifting and removing of old floor coverings and trimming of bottoms of doors. We may have already included this in your price or we can add this in for you at a reasonable cost(please ask for details).</p><p>We will endeavor to leave your property clean and tidy.</p><p>All our fitting work is guaranteed by Carpet Options Limited, should there ever be a problem we will sort it out for you. However, our fitters have been with us a number of years and are totally trustworthy with excellent reputations.</p><p>All of us here at Carpet Options look forward to working together with you to make sure your job is carried out as smooth as possible and to the highest standard.</p>`;
    if (typeof quote?.tcs === "undefined") {

        if (!quote.quote) {

            return (defaultTCs.both)
        }
        else {
            return (defaultTCs.tcs)
        }

    }
    else {

        return quote.tcs;
    }
}

export const getInvoiceStatus = (invoice) => {
    let invoiceAmount = invoice.total_amount;
    let totalPaid = invoice.total_paid;
    let voided = invoice.void_date || invoice.status == "VOID" ? true : false;;

    return voided ? "VOID" : totalPaid >= invoiceAmount ? "Paid" : totalPaid > 0 ? "Part Paid" : "Unpaid";
}






export const currencyFormat = (num, thistype = "") => {
    if (num === "" || typeof (num) === "undefined" || num == "NaN") {
        num = 0;
    }
    if (typeof (num) === "string") {
        num = num.replace('£', '').replace(',', '');
    }

    var newmoney = parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    if (thistype === "numbers") {
        newmoney = newmoney.replace('£', '').replace(',', '');

    }
    else {
        newmoney = '£' + newmoney;
    }
    //console.log(" returning newmoney:",newmoney);
    return newmoney;

}


export const dateFormat = (thisdate, thistype = "") => {

    if (!thisdate) {
        return "";
    }
    if (thisdate) {
        var thistime;
        // var newdate = new Date(thisdate);
        // if (typeof (thisdate) == "string") {
        //     if (thisdate.indexOf("T") > 0) {
        //         let thisdateparts = thisdate.split("T");
        //         //console.log("thisdateparts; " , thisdateparts)
        //         thisdate = thisdateparts[0];
        //         thistime = thisdateparts[1];
        //         thistime = thistime.split(".");
        //         thistime = thistime[0]
        //     }
        // }

        var date = new Date(thisdate);
    }

    if (date instanceof Date && !isNaN(date.valueOf())) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const monthnumber = date.getMonth() + 1;

        if (thistime = "00:00:00") {
            thistime = "";
        }
        if (thistype == "nice") {
            var newdate = date.getDate() + ' ' + (monthNames[date.getMonth()]) + ' ' + date.getFullYear() + " " + thistime;
        }
        else if (thistype = "yyyy-mm-dd") {
            var newdate = date.getFullYear() + '-' + monthnumber + '-' + ("0" + date.getDate()).slice(-2);
        }
        else if (thistype = "dd-mm-yyyy") {
            var newdate = ("0" + date.getDate()).slice(-2) + '-' + monthnumber + '-' + date.getFullYear();
        }

        else if (thistype = "Y-m-d H:i:s") {
            var newdate = (date.getFullYear() + "-" + monthnumber + "-" + "0" + date.getDate()).slice(-2) + ' ' + date.getHours() + ':' + date.getMinutes() + ":" + date.getSeconds();
        }

        else {
            var newdate = date.getDate() + ' ' + (monthNames[date.getMonth()]) + ' ' + date.getFullYear();
        }

        return newdate;
    }
    else {
        //console.log("is not a date: ", date)
        return "";
    }
}


export const sort = (data, sortKey, desc) => {

    for (let i in data) {
        for (let j in data) {
            if (desc) {
                if (data[i][sortKey] > data[j][sortKey]) {
                    let fcus = data[i]
                    data[i] = data[j]
                    data[j] = fcus
                }
            } else {
                if (data[i][sortKey] < data[j][sortKey]) {
                    let fcus = data[j]
                    data[j] = data[i]
                    data[i] = fcus
                }
            }
        }
    }
    return data
}

export const tillTimeString = (date) => {
    if (!date) {
        return "";
    }
    date = new Date(date)
    let now = new Date()
    let diff = now - date
    let years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365))
    let months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30))
    let days = Math.floor(diff / (1000 * 60 * 60 * 24))
    let hours = Math.floor(diff / (1000 * 60 * 60))
    let minutes = Math.floor(diff / (1000 * 60))
    let seconds = Math.floor(diff / 1000)

    let ds = date.toDateString().substring(4) + ', ' + date.toTimeString().substring(0, 5)
    if (years > 0) {
        return `${ds} (${years} year${years > 1 ? 's' : ''} ago)`
    } else if (months > 0) {
        return `${ds} (${months} month${months > 1 ? 's' : ''} ago)`
    } else if (days > 0) {
        return `${ds} (${days} day${days > 1 ? 's' : ''} ago)`
    } else if (hours > 0) {
        return `${ds} (${hours} hour${hours > 1 ? 's' : ''} ago)`
    } else if (minutes > 0) {
        return `${ds} (${minutes} minute${minutes > 1 ? 's' : ''} ago)`
    } else if (seconds > 0) {
        return `${ds} (${seconds} second${seconds > 1 ? 's' : ''} ago)`
    } else {
        return 'Just now'
    }
}

export const isValidEmail = (email) => {
    // console.log(email);
    let valid = email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )

    return (!valid) ? false : true;
}