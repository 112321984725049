import { Component } from 'react';
import { QuoteContext } from 'contexts/QuoteContext';
import { currencyFormat, dateFormat,thistypename } from 'utils/utils.jsx';
import InvoicesDetails from './InvoicesTable';
import RelatedCreditNotes from './CreditnotesTable';



export default class ViewDetails extends Component{
	static contextType = QuoteContext;

	friendlyDate = (dateStr) => {
		return new Date(dateStr)?.toString().substring(4,15)
	}
	
	render() {
		let quote = this.props.quote;

		let totalqty = 0;
		let totalunitprice = 0;
		let totallineprice = 0;
		let totalItemsPrice= 0;

		let a = 0;
		quote.items?.map((item, index) => {
			let itemprice = item.price;
			let itemqty =item.qty ? item.qty : 1;
			// let itemprice = item.price;
			// let itemqty = quote.items[a].qty ? quote.items[a].qty : 1;

			totalqty += itemqty;
			totalunitprice += itemprice;
			totallineprice += (itemqty * itemprice)
			totalItemsPrice += totallineprice;
		});

	
		let thisquotePriceTotal = quote.quotePriceTotal ? quote.quotePriceTotal : totalItemsPrice;

		return (
			
			<>
				<div className="view_quote_details_container">
					<div className="quote_details">
						
						<div className="section header_section">
							<h3 className="section_title">{thistypename(quote.type)} Details <small>#{quote.numericID}</small></h3>

							<div className="header">
								<div>
									<span className="label_text">Customer Name</span>
									<p><a href={`mailto:${quote.customer?.email}`}>{`${quote.customer?.fname} ${quote.customer?.lname}`}</a></p>
									<span className="label_text">Show on Invoice</span>
									<p>{quote.showNotesOnInvoice ? 'Yes' : 'No'}</p>
								</div>
								<div>
									
									<span className="label_text">Status:</span> {(quote.status == "cancelled") ? <>Cancelled</> : <>Active</> }
									
									<p></p>
									<span className="label_text">Description</span>
									<p>{quote.description}</p>
								</div>
							</div>
							<div className="table">
								<div className="table_headings">
									<div className="th">Date Created</div>
									<div className="th">Confirmed?</div>
									<div className="th">Confirmed Date</div>
									<div className="th">Date Emailed</div>
									<div className="th">Date Viewed</div>
									<div className="th">Credited</div>
									<div className="th">Created By</div>
								</div>
								<div className="table_body">
									<div className="tr">
										<div className="td">{quote.createdAt ? dateFormat(quote.createdAt) : "(empty)" }</div>
										<div className="td">{quote.quoteConfirmedDate ? 'Yes':'No'}</div>
										<div className="td">{quote.quoteConfirmedDate ? dateFormat(quote.quoteConfirmedDate) : "(empty)" }</div>
										<div className="td">{quote.emailed_date ? dateFormat(quote.emailed_date) : 'Never'}</div>
										<div className="td">{quote.viewed_date ? dateFormat(quote.viewed_date) : 'Never'}</div>
										<div className="td">{quote.creditedDate ? 'Yes': 'No'}</div>
										<div className="td"><strong><a href={`mailto:${quote.creator?.email}`}>{`${quote.creator?.fname} ${quote.creator?.lname}`}</a></strong></div>
									</div>
								</div>
							</div>
						</div>

						<div className="section quote_items_details">
							<div className="bi_columns bi_columns_title">
								<h4 className="section_title">Items<small> ({quote.items?.length})</small></h4>
								<span>Quoted Price: <strong>{currencyFormat(thisquotePriceTotal)}</strong></span>
							</div>
							<div className="table quote_items">
								<div className="table_headings">
									<div className="th">Item</div>
									<div className="th">Item Qty</div>
									<div className="th">Unit Price</div>
									<div className="th">Line Total</div>
									<div className="th">Created On</div>
									<div className="th">Last Updated At</div>
								</div>
								<div className="table_body">
										{
											
										quote.items?.map((item, index) => {
											let itemprice = item.price;
											let itemqty =item.qty ? item.qty : 1;
											
											return( 
												
												<div className="tr quote_item" key={index}>
													<div className="td">{item.name ? <><b>{item.name}</b><br /></> : "" }{item.description}</div>
													<div className="td">{itemqty}</div>
													<div className="td">{currencyFormat(itemprice)}</div>
													<div className="td">{currencyFormat(itemprice * itemqty)}</div>
													<div className="td">{item.createdAt ? dateFormat(item.createdAt) : "(empty)" }</div>
													<div className="td">{item.updatedAt ? dateFormat(item.updatedAt) : "(empty)" }</div>
												</div>
											)
										})}
								</div>
								<div className="table_headings">
									<div className="th">Totals</div>
									<div className="th">{totalqty}</div>
									<div className="th">{currencyFormat(totalunitprice)}</div>
									<div className="th">{currencyFormat(totallineprice)}</div>
									<div className="th"></div>
									<div className="th"></div>
								</div>
							</div>
						</div>

						<div className="section quote_jobs_details">
							<h4 className="section_title">Jobs<small> ({quote.jobs?.length})</small></h4>
							<div className="table quote_jobs">
								<div className="table_headings">
									<div className="th">Customer</div>
									<div className="th">Supplier</div>
									<div className="th">Item</div>
									<div className="th">Size</div>
									<div className="th">Ordered Date</div>
									<div className="th">Due Date</div>
									<div className="th">Fit Date</div>
									<div className="th">Instock</div>
									
								</div>
								<div className="table_body">
									{quote.jobs?.map((job, index) => {
										return( 
											<div className="tr quote_job" key={index}>
												<div className="td">{job.areaArrived}</div>
												<div className="td">{job.suplier}</div>
												<div className="td">{job.item}</div>
												<div className="td">{job.size}</div>
												<div className="td">{dateFormat(job.orderDate)}</div>
												<div className="td">{dateFormat(job.dueDate)}</div>
												<div className="td">{dateFormat(job.fitDate)}</div>
												<div className="td">{job.inStock ? 'Yes' : 'No'}</div>
											</div>
										)
									})}
								</div>
							</div>
						</div>

						<div className="section quote_notes_details">
							<h4 className="section_title">Notes<small> ({quote.notes?.length})</small></h4>
							<div className="table quote_notes">
								<div className="table_headings">
									<div className="th">Note Text</div>
									<div className="th">Created On</div>
									<div className="th">Last Updated At</div>
								</div>
								<div className="table_body">
										{quote.notes?.map((note, index) => {
											return( 
												<div className="tr quote_note" key={index}>
													<div className="td">{note.description}</div>
													<div className="td">{note.createdAt ? dateFormat(note.createdAt) : "(empty)" }</div>
													<div className="td">{note.updatedAt ? dateFormat(note.updatedAt) : "(empty)" }</div>
												</div>
											)
										})}
								</div>
							</div>
						</div>
						{quote.type == 'job' &&
						<>
							<div className="section quote_invoices_details">
								<h4 className="section_title">Invoices<small> ({quote.invoices?.length ? quote.invoices.length : 0})</small></h4>
								<InvoicesDetails quote={quote}/>
							</div>
							<div className="quote_form">
								<RelatedCreditNotes  quote={quote}/>
							</div>
						</>
						}
					</div>	
				</div>	
			</>
		)
	}
}