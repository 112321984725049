import { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { currencyFormat, dateFormat, ControlledInput, infocustomeradd, infoTcs } from "utils/utils";
import { CustomerContext } from 'contexts/CustomerContext';
import Wrapper from 'pages/Wrapper';
import { HiUserAdd } from 'react-icons/hi';
import { AiOutlineEdit } from 'react-icons/ai';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FaEye } from 'react-icons/fa';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import FadingDots from "react-cssfx-loading/lib/FadingDots";

import CustomerPopup from 'components/CustomerPopup/CustomerPopup';

import './Customers.scss';

export default class Customers extends Component {
	static contextType = CustomerContext;

	constructor(props) {
		super(props)

		this.state = {
			selectedCustomer: {},
			showCusPopup: false,
			sort: {
				key: 'createdAt',
				desc: true,
			},
			deletingCustomers: [],
			popupMode: 'edit'
		}

		this.columnNames = {
			company: 'Company',
			fname: 'First Name',
			lname: 'Last Name',
			email: 'Email',
			phone: 'Phone',
			mobile: 'Mobile',
			createdAt: 'Created Date',
		}
	}

	showCusPopup = (selectedCustomer = {}, popupMode = 'edit') => {
		this.setState({ selectedCustomer, showCusPopup: true, popupMode })
	}
	hideCusPopup = () => {
		this.setState({ selectedCustomer: {}, showCusPopup: false })
	}

	deleteCustomer = async (customer) => {
		let deletingCustomers = this.state.deletingCustomers
		deletingCustomers.push(customer)
		this.setState({ ...this.state, deletingCustomers })

		this.context.deleteCustomer(customer)
		this.setState({ ...this.state, deletingCustomers: this.state.deletingCustomers })
	}

	sort = (key) => {
		let desc = this.state.sort.key == key && this.state.sort.desc
		this.context.sort(key, !desc)
		this.setState({ ...this.state, sort: { key, desc: !desc } })
	}

	componentDidMount() {
		document.title = 'Customers';
		this.context?.sort('createdAt', true)
	}



	render() {
		let customers = []
		if (this.state.searchFilter) {
			customers = this.context.customers.filter(customer => {

				let filter = this.state?.searchFilter?.toLowerCase()
				let str = ''
				let keys = ["address1", "address2", "city", "country", "email", "fname", "lname", "phone", "mobile", "company", "town", "postCode"]
				keys.forEach(key => {
					str += `"${customer[key]}" `
				})
				// console.log(str)
				let regex = new RegExp(`(${filter})`, 'i')
				return regex.test(str)
			})
		} else {
			customers = this.context.customers
		}

		let params = new URLSearchParams(window.location.search)
		if (customers.length > 0 && params.get('id')) {
			let customer = customers.find(customer => customer._id == params.get('id'))
			if (customer) {
				this.showCusPopup(customer, 'view')
				return <Navigate to="/customers" />
			}
		}
		return (
			<Wrapper>
				{this.state.showCusPopup && <CustomerPopup customer={this.state.selectedCustomer} hide={this.hideCusPopup} mode={this.state.popupMode} />}
				<div id="customer_page_container">
					<div className="customer_page_header">
						<button className="add_customer_button" onClick={e => this.showCusPopup()}>
							<HiUserAdd />
							Add Customer
						</button>
						<input type="text" placeholder="Start typing to filter customers..." onInput={e => this.setState({ ...this.state, searchFilter: e.target.value })} className="filter_search" autoComplete="off" />
					</div>
					{this.context.loading ?
						<div className="preloader">
							<FadingDots width="70px" height="70px" color="#62d3f1" />
						</div>
						:
						<>
							{customers.length != 0 ?
								<div className="customers_table">
									<div className="table">
										<div className="table_headings">
											{Object.keys(this.columnNames).map((key) => {
												return (
													<div key={key} className="th sortable_th" onClick={e => this.sort(key)}>
														{this.columnNames[key]}&nbsp;
														{this.state.sort.key == key ?
															this.state.sort.desc ? <GoTriangleDown /> : <GoTriangleUp />
															: ''
														}
													</div>
												)
											})}
											<div className="th"></div>
										</div>

										<div className="table_body">
											{Array.isArray(customers) && customers.map((customer, index) => {
												console.log(customer);
												return (
													<div className="tr" key={index}>
														{Object.keys(this.columnNames).map(key => <div key={key} className="td cursor_pointer" onClick={e => this.showCusPopup(customer, 'view')}>{key == 'createdAt' ? dateFormat(customer[key], "nice") : customer[key]}</div>)}

														<div className="td">
															<button className="icon_btns" onClick={e => this.showCusPopup(customer, 'edit')} disabled={this.state.deletingCustomers.includes(customer._id)}>
																<AiOutlineEdit />
															</button>
															<button className="icon_btns" onClick={e => this.showCusPopup(customer, 'view')} disabled={this.state.deletingCustomers.includes(customer._id)}>
																<FaEye />
															</button>
															{!customer.hasOwnProperty('quotes') || customer.quotes?.length <= 0 &&
																<button className="icon_btns" onClick={e => this.deleteCustomer(customer._id)} disabled={this.state.deletingCustomers.includes(customer._id)}>
																	<RiDeleteBinLine />
																</button>}
														</div>
													</div>
												)
											})}
										</div>

									</div>
								</div>
								:
								<h3>No records found.</h3>}
						</>
					}
				</div>
			</Wrapper>
		)
	}
}
