import { Component } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { CustomerContext } from 'contexts/CustomerContext';
import FadingBalls from "react-cssfx-loading/lib/FadingBalls";
import QuotePopup from 'components/QuotePopup/QuotePopup'
import { QuoteContextProvider } from 'contexts/QuoteContext'
import { currencyFormat, quoteitemstotal, dateFormat } from "utils/utils";
import './CustomerPopup.scss';


export default class ViewDetails extends Component {
	static contextType = CustomerContext;

	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			invoices: [],
			quotes: [],
			popup: <></>,
		}
	}

	loadHistory = async () => {
		let req = await this.context.getCustomerHistory(this.props.customer._id)
		this.setState({ ...this.state, loading: false, quotes: req.quotes || [], invoices: req.invoices || [] })
	}
	componentDidMount() {
		this.loadHistory()
	}

	view = (page, item) => {

		page = item.type == 'job' ? 'jobs' : page

		window.location.href = window.location.origin + '/' + page + '?id=' + item._id
	}

	hide = e => {
		this.setState({ ...this.state, popup: <></> })
	}

	createQuote = () => {

		this.setState({
			...this.state,
			popup: <QuotePopup quoteorjob="quote" quote={{
				customer: this.props.customer,
				_id: 0
			}} hide={this.hide} mode='edit' className="quote_popup slidein_right" />

		})
	}

	render() {
		console.log("customer viewdetails: ", this.state);
		let cus = this.props.customer
		return (
			<>
				<QuoteContextProvider>{this.state.popup}</QuoteContextProvider>
				<div className="cus_personal_details">
					<div className="personal_details_header">
						<button className="new_quote_button" onClick={this.createQuote}>Start new quote</button>
						<button className="new_quote_button" onClick={this.props.edit}>Edit Customer Details</button>
					</div>
				</div>

				<div className="cus_shipping_details">
					<div className="bi_columns">
						<div>
							<p><strong>Company: </strong>{cus.company}</p>
							<p><strong>Name: </strong>{cus.fname} {cus.lname}</p>
							<p><strong>Email: </strong>{cus.email}</p>
							<p><strong>Alternative Email: </strong>{cus.alternativeEmail}</p>
							<p><strong>Phone #: </strong>{cus.phone}</p>
							<p><strong>Mobile #: </strong>{cus.mobile}</p>
						</div>
						<div>
							<p><strong>Address Line 1: </strong>{cus.address1}</p>
							<p><strong>Address Line 2: </strong>{cus.address2}</p>
							<p><strong>Town: </strong>{cus.town}</p>
							<p><strong>City: </strong>{cus.city}</p>
							<p><strong>ZIP/Post Code: </strong>{cus.postCode}</p>
							<p><strong>Country: </strong>{cus.country}</p>
						</div>
					</div>
				</div>

				<div className="cus_shipping_details">
					<h4>Customer History</h4>


					{this.state.loading && <div className="preloader"><FadingBalls color="#dd4477" /></div>}

					<h5>Quotes & Jobs</h5>
					{
						this.state.quotes.length <= 0 &&
						<>
							This customer has no related quotes or jobs<br /><br />
						</>
					}
					{
						this.state.quotes.length > 0 &&
						<>
							<table width="100%" className="table clickable">
								<thead>
									<th>Created</th>
									<th>#</th>
									<th>Status</th>
									<th>Items</th>
									<th>Total</th>
								</thead>
								<tbody>
									{this.state.quotes.map((quote, i) => {

										let itemscount = quote.items.length
										let status = (quote.accepted_date) ? "Job" : "Quote";
										return (
											<tr key={i}  >
												<td onClick={e => this.view('quotes', quote)}>{dateFormat(quote.createdAt, "nice")}</td>
												<td onClick={e => this.view('quotes', quote)}>{quote.numericID}</td>
												<td onClick={e => this.view('quotes', quote)}>{status}</td>
												<td><a onClick={e => this.showItems(quote.numericID)}>{itemscount} item(s)</a>
													<div>
														{
															quote.items.map((item, i) => {
																return (
																	<>
																		{item.qty ? item.qty : 1} x {item.name}<hr />
																	</>
																)
															})
														}
													</div>
												</td>
												<td onClick={e => this.view('quotes', quote)}>{currencyFormat(quote.quotePriceTotal)}</td>
											</tr>
										)
									})}
								</tbody>
							</table>
							<br /><br />
						</>
					}
					<hr />
					<h5>Invoices</h5>
					{
						this.state.invoices.length <= 0 &&
						<>
							This customer has no related invoices
						</>
					}
					{!this.state.loading && this.state.invoices.length > 0 && <>
						<table width="100%" className="table clickable">
							<thead>
								<th>#</th>
								<th>Created</th>
								<th>Due</th>
								<th>Sent</th>
								<th>Status</th>
								<th>Paid</th>
								<th>Total</th>
							</thead>
							<tbody>
								{this.state.invoices.map((invoice, i) => {
									return (
										<tr key={i} onClick={e => this.view('invoices', invoice)}>
											<td>{invoice.displayed_as}</td>
											<td>{dateFormat(invoice.createdAt, "nice")}</td>
											<td>{dateFormat(invoice.due_date, "nice")}</td>
											<td>{dateFormat(invoice.sent_date, "nice")}</td>
											<td>{invoice.status}</td>
											<td>{currencyFormat(invoice.total_paid)}</td>
											<td>{currencyFormat(invoice.total_amount)}</td>
										</tr>
									)
								})}
							</tbody>
						</table>

					</>}

				</div>

			</>
		)
	}
}