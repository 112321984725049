import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { toastOptions, sort } from 'utils/utils.jsx';

const InventoryContext = React.createContext();

class InventoryContextProvider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			inventory: {
				cats: [],
				items: [],
				structured: []
			},
			loadInventory: this.loadInventory,
			createUpdateItem: this.createUpdateItem,
			addToQuoteInventory: this.addToQuoteInventory,
			createUpdateCat: this.createUpdateCat,
			deleteItem: this.deleteItem,
			deleteCat: this.deleteCat,
			catsOptions: "",
			sort: this.sort,
		}
	}

	componentDidMount() {
		this.loadInventory()
	}

	api_error = () => {
		if (window.confirm('There were some error loading this page. Please refresh')) {
			window.location.reload()
		}
	}

	loadInventory = () => {
		fetch(`/api/inventory`)
			.then(res => res.json())
			.then(inventory => {
				//inventory.structured = this.structureCats(inventory.cats, 0);
				this.setState({ ...this.state, inventory, loading: false });
			})
			.catch(err => {
				this.setState({ ...this.state, loading: false });
				this.api_error()
				console.log(err)
			})
	}

	getParentCat = (catid) => {

		let cat = this.inventory.cats.find(c => c._id == catid)
		if (cat) {

			if (catid == cat.parent) {
				//recursive ???
				return cat.name + ' / ';
			}
			let parent = this.getParentCat(cat.parent)
			if (parent) {
				return parent + cat.name + ' / '
			} else {
				return cat.name + ' / '
			}
		} else {
			//console.log("none found");
			return ''
		}
	}

	getSelCats = (pcatid, items) => {

		let selcats = "";
		this.inventory.cats.map((cat) => {
			let catparent = cat.parent ? cat.parent : "";

			if (catparent == pcatid) {
				let children = this.getSelCats(cat._id, []);
				items.push({ "value": cat._id, "children": children, "label": cat.name });
			}
		})

		return items;
	}

	getCatsOptions = async () => {
		this.setState({ ...this.state, catsOptions: this.catsOptions(this.inventory.cats, 0) });
	}

	structureCats = (cats, levels) => {


	}

	catsOptions = (cats, levels) => {
		let catshtml = [];
		let spacer = "";

		for (let a = 0; a < levels; a++) {
			spacer += "---";
		}

		cats.map((cat, key) => {
			console.log("this cat: ", cat)
			let thislabel = spacer + " " + cat.label;
			let thisvalue = cat.value;
			let catid = cat._id;

			let thischildren = cat.children || [];

			catshtml.push(<option key={catid} value={thisvalue} >{thislabel}</option>);

			if (thischildren.length > 0) {
				let childhtml = this.catsOptions(thischildren, levels + 1);
				for (let b = 0; b < childhtml.length; b++) {
					catshtml.push(childhtml[b]);
				}
			}

		});

		return catshtml;
	}

	createUpdateItem = async (item) => {
		console.log("createUpdateItem: ", item)
		let itemUpdated = await this.createUpdateObj(item, `/api/inventory/item/${item._id ? item._id : ''}`, 'Item', 'items')
	}

	createUpdateCat = async (cat) => {
		let catUpdated = await this.createUpdateObj(cat, `/api/inventory/cat/${cat._id ? cat._id : ''}`, 'Category', 'cats')
	}

	addToQuoteInventory = async (item) => {
		let toastID = toast.loading('Creating Inventory Item');
		console.log("adding item to inventory: ", item);
		try {
			let req = await fetch(`/api/inventory/item`, {
				method: 'POST',
				body: JSON.stringify(item),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			})
			let res = await req.json();
			let cats = this.state.inventory.cats;
			if (item.cat?.name) {

				cats.push({ name: item.cat.name, _id: res.cat, parent: item.cat.pcat });
			}

			let newItem = { itemname: res.name, name: res.name, itemcat: res.cat, cat: res.cat, price: res.price, itemid: res._id }


			let items = this.state.inventory.items;
			items.push(newItem);

			this.setState({ ...this.state, inventory: { ...this.state.inventory, items, cats }, loading: false });

			toast.update(toastID, { ...toastOptions, render: res.text, type: res.res })

			return newItem

		} catch (err) {
			this.setState({ ...this.state, saving: false })
			toast.update(toastID, { ...toastOptions, render: "There were some issues creating this quote", type: "error" })
			this.api_error()
			console.log(err)
			return { error: 'error' }
		}
	}


	createUpdateObj = async (obj, route, objName, inventoryKey) => {
		console.log("createUpdateObj: ", obj)
		console.log("JSON.stringify(obj): ", JSON.stringify(obj))
		let toastID = toast.loading(`${obj._id ? 'Updating' : 'Creating'} ${objName}...`)
		let res;
		try {
			let req = await fetch(route, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				method: 'POST',
				body: JSON.stringify(obj)
			})

			res = await req.json()
			let objs = this.state.inventory[inventoryKey]

			if (!res._id) {
				throw new Error("Error Updating Object.")
			}
			toast.update(toastID, { ...toastOptions, render: `${objName} ${obj._id ? 'Updated' : 'Created'}`, type: "success" })
			if (obj._id) {
				// Replace the updated obj in the local array as well instead of fetching all inventory again.
				objs = objs.map(i => obj._id == i._id ? obj : i)
			} else {
				if (res._id) objs.push(res)
			}

			let newState = { ...this.state }
			newState.inventory[inventoryKey] = objs

			this.setState(newState)
			return res

		} catch (err) {
			toast.update(toastID, { ...toastOptions, render: `There were some issues creating/updating the ${objName}.`, type: "error" })
			this.api_error()
			console.log(err)
			return obj
		}
	}

	deleteItem = (id) => {
		this.deleteObj(id, `/api/inventory/item/${id}`, 'Item', 'items')
	}

	deleteCat = (id) => {
		this.deleteObj(id, `/api/inventory/cat/${id}`, 'Category', 'cats')
	}

	deleteObj = async (id, route, objName, inventoryKey) => {

		let toastID = toast.loading(`Deleting ${objName}...`)
		fetch(route, {
			method: 'DELETE',
		})
			.then(res => res.json())
			.then(res => {
				toast.update(toastID, { ...toastOptions, render: `${objName} Deleted`, type: "success" })
				let objs = this.state.inventory[inventoryKey].filter(obj => obj._id !== id)

				let newState = { ...this.state }
				newState.inventory[inventoryKey] = objs

				this.setState(newState)
			})
			.catch(err => {
				toast.update(toastID, { ...toastOptions, render: `There were some error deleting this ${objName}`, type: "error" })
				this.api_error()
				console.log(err)
			})
	}

	sort = (sortKey, desc) => {
		let customers = sort(this.state.customers, sortKey, desc)
		this.setState({ ...this.state, customers })
	}


	render() {
		return (
			<InventoryContext.Provider value={this.state}>
				{this.props.children}
			</InventoryContext.Provider>
		);
	}
}

export { InventoryContext, InventoryContextProvider };
