import { Component } from 'react';
import { QuoteContext } from 'contexts/QuoteContext';
import Wrapper from 'pages/Wrapper';

import { MdRequestQuote, MdCancelScheduleSend } from 'react-icons/md';

import { FaEye, FaBan } from 'react-icons/fa';
import { GoTriangleUp, GoTriangleDown } from 'react-icons/go';
import { GoLinkExternal } from 'react-icons/go';
import FadingDots from "react-cssfx-loading/lib/FadingDots";
import { dateFormat, currencyFormat } from "utils/utils";
import QuotePopup from 'components/QuotePopup/QuotePopup';

import './Quotes.scss';

export default class Quotes extends Component {
	static contextType = QuoteContext;

	constructor(props) {
		super(props)

		this.state = {
			sort: {
				key: 'createdAt',
				desc: true
			},
			jobs: [],
			load: true,
			loading: false
		}

		this.columnNames = {
			numericID: '#',
			customerName: 'Customer',
			quotePriceTotal: 'Total Price',
			outofstockjobs: 'Out Of Stock',
			items: 'Items',
			jobs: 'Jobs',
			createdAt: 'Date Created',
			nextFitDate: 'Next Fit Date',
			emailed_date: 'Last Sent',
			viewedDate: 'Last Viewed',
		}
	}

	sort = (key) => {
		this.setState({ ...this.state, sort: { key, desc: !this.state.sort.desc } })
		this.context.sort(key, !this.state.sort.desc)
	}

	showPopup = (showjobid, mode = 'edit') => {
		this.setState({ ...this.state, showjobid, mode });
	}

	hidePopup = () => {
		this.setState({ ...this.state, showjobid: null });
	}



	deleteQuote = async (quoteId) => {
		this.setState({ ...this.state, deletingQuotes: [...this.state.deletingQuotes, quoteId] })
		await this.context.deleteQuote(quoteId)
		this.setState({ ...this.state, deletingQuotes: this.state.deletingQuotes.filter(cid => cid !== quoteId) })

	}
	openQuoteWindow = async (quoteId) => {
		window.open(`${window.location.origin}/quote?id=${quoteId}`);
	}

	cancelQuote = async (quoteId) => {
		this.setState({ ...this.state, deletingQuotes: [...this.state.deletingQuotes, quoteId] })
		await this.context.cancelQuote(quoteId)
		this.setState({ ...this.state, deletingQuotes: this.state.deletingQuotes.filter(cid => cid !== quoteId) })

	}

	async componentDidMount() {
		document.title = 'Quotes';
		if (this.state.load && !this.state.loading) {
			this.setState({ ...this.state, loading: true })
			let quotes = await this.context.getQuotes({ type: 'quote' });
			this.setState({ ...this.state, quotes, load: false, loading: false })
		}

		let quoteid = new URLSearchParams(window.location.search).get('id');
		quoteid = quoteid ? quoteid : null;
		if (quoteid) {
			this.setState({ ...this.state, quote: { _id: quoteid } })
		}
	}

	render() {
		let quotes = this.context.quotes;

		if (this.state.searchFilter) {
			quotes = quotes?.filter(quote => {
				let filter = this.state?.searchFilter?.toLowerCase()
				let str = `"${quote?.customer?.email}" "${quote?.customer?.fname}" "${quote?.customer?.lname}" "${quote?.creator?.email}" "${quote?.creator?.fname}" "${quote?.creator?.lname}" "${quote?.QuotePriceTotal}"`
				quote?.items?.forEach(item => { str += `"${item.name}" ` })
				quote?.jobs?.forEach(job => { str += `"${job.areaArrived}" "${job.item}" "${job.suplier}"` })
				quote?.notes?.forEach(note => { str += `"${note.description}" ` })
				str += ` "${quote.numericID}"`

				let regex = new RegExp(`(${filter})`, 'i')

				return regex.test(str)
			})
		}

		return (
			<Wrapper>
				<div id="quote_page_container">
					{this.state.quote != null &&
						<>
							<QuotePopup className="quote_popup slidein_right" quoteorjob="quote" hide={() => { this.setState({ ...this.state, quote: null }) }} quote={this.state.quote} mode="edit" />
						</>
					}
					<div className="quote_page_header">
						<button className="add_quote_button" onClick={e => this.setState({ ...this.state, quote: { _id: 0 } })}>
							<MdRequestQuote />
							New Quote
						</button>
						<input type="text" autoComplete="off" autofill="off" placeholder="Start typing to filter quotes..." onInput={e => this.setState({ ...this.state, searchFilter: e.target.value })} className="filter_search" />
					</div>
					{this.state.loading ?
						<div className="preloader">
							<FadingDots width="70px" height="70px" color="#62d3f1" />
						</div>
						:
						<>
							{quotes.length != 0 ?
								<div className="quotes_table">
									<div className="table quotes">
										<div className="table_headings">
											{Object.keys(this.columnNames).map((key) => {
												return (
													<div key={key} className="th sortable_th" onClick={e => this.sort(key)}>
														{this.columnNames[key]}&nbsp;
														{this.state.sort.key == key ?
															this.state.sort.desc ? <GoTriangleDown /> : <GoTriangleUp />
															: ''
														}
													</div>
												)
											})}
											<div className="th">Status</div>
											<div className="th">Actions</div>
										</div>

										<div className="table_body">
											{Array.isArray(quotes) && quotes.map((quote, index) => {
												return (
													<div className="tr" key={index}>
														{Object.keys(this.columnNames).map(key => {

															let value = key;
															switch (value) {
																case 'status': {
																	value = <span className={"status " + quote[key]}>{quote[key].slice(0, 1).toUpperCase() + quote[key].slice(1)}</span>;
																	break;
																}
																case 'customerName': {
																	let customer = quote.customer[0] ? quote.customer[0] : quote.customer ? quote.customer : { fname: "", lname: "" };
																	//console.log("customer", customer);

																	let customerName = customer.fname + " " + customer.lname;
																	value = <span>{customer.company ? <><b>{customer.company}</b><br /></> : <></>}{customerName}</span>;
																	break;
																}

																case 'emailed_date': {
																	value = <span>{dateFormat(quote[key], "nice")}</span>;
																	break;
																}
																case 'outofstockjobs': {
																	value = <span>{quote["outofstock"] ? "Out of Stock" : "In Stock"}</span>;
																	break;
																}
																case 'quotePriceTotal': {
																	value = currencyFormat(quote.quotePriceTotal);
																	break;
																}
																case 'viewed_date': {
																	value = <span>{dateFormat(quote[key], "nice")}</span>;
																	break;
																}
																case 'createdAt': {
																	value = <span>{dateFormat(quote["createdAt"], "nice")}</span>;
																	break;
																}
																case 'items': {
																	value = <span>{quote.items?.length}</span>;
																	break;
																}
																case 'jobs': {
																	value = <span>{quote.jobs?.length}</span>;
																	break;
																}
																case 'nextFitDate': {
																	value = <span>{dateFormat(quote[key], "nice")}</span>;
																	break;
																}
																default: {
																	value = quote[key];
																	break;
																}
															}

															return (
																<div key={key} className="td cursor_pointer" onClick={e => { this.setState({ ...this.state, quote: { _id: quote._id, loadquote: true } }) }}>{value}</div>
															)
														}


														)
														}
														<div className="td tr_status">
															{(quote.status == "cancelled") ? <>Cancelled</> : <>Active</>}
														</div>
														<div className="td tr_options">
															{quote.status != 'cancelled' &&
																<>
																	<button className="icon_btns" onClick={e => this.openQuoteWindow(quote._id)} dis>
																		<GoLinkExternal />
																	</button>

																</>}


															{quote.status != 'cancelled' &&
																<button className="icon_btns" onClick={e => this.cancelQuote(quote._id)} title="Cancel">
																	<FaBan />
																</button>}
														</div>
													</div>
												)
											})}
										</div>

									</div>
								</div>
								:
								<h3>No records found.</h3>}
						</>}
				</div>
			</Wrapper>
		)
	}
}
