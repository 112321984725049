import { Component } from 'react';
import { Link } from 'react-router-dom';

import { DashboardContext } from 'contexts/DashboardContext';
import { QuoteContextProvider } from 'contexts/QuoteContext';

import FadingBalls from "react-cssfx-loading/lib/FadingBalls";
import Calendar from "react-widgets/Calendar";
import { Chart } from "react-google-charts";
import Flatpickr from "react-flatpickr";

import { AiOutlineDelete } from 'react-icons/ai';

import Wrapper from 'pages/Wrapper';
import AllActivities from 'components/AllActivities/AllActivities';
import AllMessages from 'components/AllMessages/AllMessages';
import Reports from 'components/Reports/Reports';
import QuotePopup from 'components/QuotePopup/QuotePopup';
import InvoicePopup from 'components/InvoicePopup/InvoicePopup'
import { currencyFormat, dateFormat, DateOptions } from "utils/utils";

import "flatpickr/dist/themes/material_green.css";
import "./calendar.css"
import './Dashboard.scss';

export default class Dashboard extends Component {
	static contextType = DashboardContext;

	state = {
		showAllActivities: false,
		showAllMessages: false,
		showReports: false,
		selectedQuote: null,
		showQuote: false,
		report_from: new Date(),
		report_to: new Date(),
		report_type: "financials"

	}

	componentDidMount() {
		document.title = 'Dashboard';
		let user = this.context.user;
		console.log("getUser: ", user)
		this.setState({ ...this.state, user })
	}

	handleDate = (selectedDates, thisdate, instance, fieldname) => {
		let thisInvoice = this.state.invoice;

		if (Array.isArray(selectedDates)) {
			selectedDates = selectedDates[0];
		}
		if (fieldname === "report_from") {
			this.setState({ ...this.state, report_from: selectedDates })
		}
		else if (fieldname === "report_to") {
			this.setState({ ...this.state, report_to: selectedDates })
		}
	}

	openquote = (quoteid) => {
		this.setState({ ...this.state, invoice: null, quote: { _id: quoteid, loadquote: true } })
	}

	openinvoice = (invoiceid) => {
		this.setState({ ...this.state, invoiceid })
	}

	hideinvoice = () => {
		this.setState({ ...this.state, invoice: null })
	}

	requestHelp = (e) => {
		e.preventDefault()
		console.log('help requested')
	}

	showReports = (e) => {
		e.preventDefault()
		this.setState({ ...this.state, showReports: true })
	}

	showActivity = activity => {
		window.location = activity.link;
	}

	render() {
		console.log("user: ", this.context.user)
		let quoteColor = "#3498db"
		let jobColor = "#97cfe2"
		let invoiceColor = "#07bc0c"
		let barChartData = [["Month", "Quotes", { role: "style" }, "Jobs", { role: "style" }, "Invoices", { role: "style" }]];

		let pieChartData = [["Month", "Total Sum", { role: "style" }]];

		let chartData = this.context.dashboardData.ChartData
		let date = new Date(chartData?.startDate)
		date.setMonth(date.getMonth() + 1)

		for (let i = 0; i < 12; i++) {

			let dateStr = date.toString().substring(4, 7)
			let quotes = chartData?.quotes.filter(q => (new Date(q.createdAt).toString().substring(4, 7) == dateStr && q.type == 'quote'))
			let jobs = chartData?.quotes.filter(j => (new Date(j.createdAt).toString().substring(4, 7) == dateStr && j.type == 'job'))
			let invoices = chartData?.invoices.filter(inv => new Date(inv.createdAt).toString().substring(4, 7) == dateStr)

			barChartData.push([dateStr.substring(0, 3), quotes?.length || 0, quoteColor, jobs?.length || 0, jobColor, invoices?.length || 0, invoiceColor])
			pieChartData.push([dateStr.substring(0, 3), (quotes?.length || 0) + (jobs?.length || 0) + (invoices?.length || 0), invoiceColor])

			date.setMonth(date.getMonth() + 1)

		}

		let options = {
			bar: { groupWidth: '70%' },
			legend: 'none',
			chartArea: { 'width': '84%', 'height': '80%' },
			/* title: "Revenue",
			titlePosition: 'none',
			title: 'Motivation Level 4hroughout the Day',
			hAxis: {
			  title: 'Time of Day',
			  format: 'h:mm a',
			  viewWindow: {
				min: [7, 30, 0],
				max: [17, 30, 0]
			  }
			}, */
			vAxis: {
				gridlines: {
					count: 2,
					color: '#ddd',
				},
				/* format: '£###,###',
				titlePosition: 'none',
					title: 'Rating (scale of 1-10)' */
			},
			animation: {
				startup: true,
				easing: "ease",
				duration: 1500,
			},
		};
		return (
			<Wrapper className="page_dashboard">
				{this.state.quote != null && <QuoteContextProvider><QuotePopup className="quote_popup slidein_right" hide={e => this.setState({ ...this.state, quote: null })} quote={this.state.quote} openinvoice={this.openinvoice} mode={'view'} /></QuoteContextProvider>}
				{this.state.showAllActivities && <AllActivities hide={e => this.setState({ ...this.state, showAllActivities: false })} className="activities_popup" />}
				{this.state.showAllMessages && <AllMessages hide={e => this.setState({ ...this.state, showAllMessages: false })} className="messages_popup" />}
				{this.state.showReports && <Reports hide={e => this.setState({ ...this.state, showReports: false })} report_from={this.state.report_from} report_to={this.state.report_to} report_type={this.state.report_type} className="reports_popup" />}

				{this.state.invoice && <>
					<QuoteContextProvider><InvoicePopup invoice={this.state.invoice} quote={this.state.quote} hide={this.hideinvoice} /></QuoteContextProvider>
				</>}


				<div className="dashboard_container">
					<div className="recent_stuff">

						<div className="widget recent_activities">
							<h3 className="widget_title">Recent Activity</h3>
							{this.context.loading ?
								<div className="preloader"><FadingBalls color="#901aa5" /></div>
								:
								<>
									{this.context.dashboardData?.recentActivities?.length > 0 ?
										<>
											<div className="link show_all" onClick={e => this.setState({ ...this.state, showAllActivities: true })}>Show all activities</div>
											{this.context.dashboardData?.recentActivities?.map((activity, index) => {
												//console.log(activity);
												return (
													<div className="activity loop_item" key={index} onClick={e => this.showActivity(activity)}>
														<div className="activity_title">{activity.title}</div>
														<div className="created_at">{dateFormat(activity.createdAt, "nice")}</div>
														<AiOutlineDelete onClick={e => this.context.deleteActivity(activity)} />

													</div>
												)
											})}
										</>
										:
										<center>No recent activity.</center>}
								</>
							}
						</div>

						<div className="widget outof_stock">
							<h3 className="widget_title">Out of Stock Jobs ({this.context.dashboardData?.outofStockJobs?.length})</h3>
							{this.context.loading ?
								<div className="preloader"><FadingBalls color="#901aa5" /></div>
								:
								<>
									{this.context.dashboardData?.outofStockJobs?.length > 0 ?
										<>
											<Link className="link show_all" to="/jobs">Show all jobs</Link>
											<div className="table outofstockjobs">
												<div className="table_headings">
													<div className="th">Job No</div>
													<div className="th">Upcoming Fit Date</div>
													<div className="th">Customer</div>
													<div className="th">Items Out Of Stock</div>
													<div className="th">Job Status</div>
												</div>
												<div className="table_body">

													{this.context.dashboardData?.outofStockJobs?.map((quote, index) => {
														//console.log("outof stock jobs: ", quote)
														return (
															<div className="tr quote loop_item" key={index} onClick={e => this.setState({ ...this.state, quote })}>
																<div className="td">{quote.numericID}</div>
																<div className="td">{dateFormat(quote.nextFitDate, "nice")}</div>
																<div className="td">{quote.customer.fname} {quote.customer.lname}</div>
																<div className="td">{quote.outofstockjobs}</div>
																<div className="td">{quote.status}</div>
															</div>
														)
													})}
												</div>
											</div>
										</>
										:
										<center>No items found.</center>}
								</>
							}
						</div>

						<div className="widget unpaid_invoices">
							<h3 className="widget_title">Overdue Invoices ({this.context.dashboardData?.overdueInvoices?.length})</h3>
							{this.context.loading ?
								<div className="preloader"><FadingBalls color="#901aa5" /></div>
								:
								<>
									{this.context.dashboardData?.overdueInvoices?.length > 0 ?
										<>
											<Link className="link show_all" to="/invoices">Show all invoices</Link>
											<div className="table invoices">
												<div className="table_headings">
													<div className="th">#</div>
													<div className="th">Due Date</div>
													<div className="th">Customer</div>
													<div className="th">Amount Outstanding</div>
												</div>
												<div className="table_body">

													{this.context.dashboardData?.overdueInvoices?.map((invoice, index) => {

														return (
															<div className="tr invoice loop_item" key={index} onClick={e => this.setState({ ...this.state, invoice, quote: { _id: invoice.quote } })}>
																<div className="td">{invoice.displayed_as}</div>
																<div className="td">{dateFormat(invoice.due_date, "nice")}</div>
																<div className="td">{invoice.customer?.fname} {invoice.customer?.lname}</div>
																<div className="td">{currencyFormat(Number(invoice.invoice_outstanding))}</div>
															</div>

														)
													})}
												</div>
											</div>
										</>
										:
										<center>No item found.</center>}
								</>
							}
						</div>

						<div className="widget recent_messages">
							<h3 className="widget_title">Recent Messages</h3>
							<div className="link show_all" onClick={e => this.setState({ ...this.state, showAllMessages: true })}>Show all messages</div>
							{this.context.loading ?
								<div className="preloader"><FadingBalls color="#901aa5" /></div>
								:
								<>
									{this.context.dashboardData?.recentMessages?.length > 0 ?
										<>
											{this.context.dashboardData?.recentMessages?.map((msg, index) => {
												return (
													<div className="message loop_item" key={index}>
														<div className="message_info">
															<strong>{msg.creator?.fname} {msg.creator?.lname}</strong>
															<span className="created_at">{dateFormat(msg.createdAt)}</span>
															<p>{msg.message}</p>
														</div>
														<AiOutlineDelete onClick={e => this.context.deleteMessage(msg)} />
													</div>
												)
											})}
										</>
										:
										<center>No item found.</center>}
								</>
							}
						</div>

						<div className="widget request_help">
							<h3 className="widget_title">Request Help</h3>
							<form onSubmit={this.context.requestHelp}>
								<label>
									<span>Subject</span>
									<input name="subject" placeholder="Subject for the email" required />
								</label>
								<label>
									<span>Description</span>
									<textarea name="description" placeholder="Detailed description of the issue" required></textarea>
								</label>

								<button className="request_btn">Request Help</button>
							</form>
						</div>

						<div className="widget calendar">
							<h3 className="widget_title">Calendar</h3>
							<Calendar defaultValue={new Date()} />
						</div>


					</div>

					{
						this.context.user.role == "admin" && <>


							<div className="reports widget">
								<h3 className="widget_title">Reports</h3>
								<form onSubmit={this.showReports}>
									<label>
										<span>Starting from</span>
										<Flatpickr options={DateOptions} onChange={(selectedDates, dateStr, instance) => { this.handleDate(selectedDates, dateStr, instance, "report_from") }} name='report_from' value={this.state.report_from} required />
									</label>
									<label>
										<span>Until</span>
										<Flatpickr options={DateOptions} onChange={(selectedDates, dateStr, instance) => { this.handleDate(selectedDates, dateStr, instance, "report_to") }} name='report_to' value={this.state.report_to} required />
									</label>
									<label>
										<span>Report Type</span>
										<select className="form-control" name="report_type" onChange={e => { this.setState({ ...this.state, report_type: e.target.value }) }}>
											<option value='financials'>Financials</option>

										</select>
									</label>
									<button className="report_btn">Generate</button>
								</form>
							</div>

							{/* Reports section ends here */}

							{/* <div className="odds_summary">
								<div className="widget">
									<h3 className="widget_title">Total Quotes</h3>
									{this.context.loading ?
										<div className="preloader"><FadingBalls color="#3097b9" /></div>
										:
										<span className="big_text_number" style={{ color: '#3097b9' }}>{this.context.dashboardData.quotesCount}</span>}
								</div>

								<div className="widget">
									<h3 className="widget_title">Total Jobs</h3>
									{this.context.loading ?
										<div className="preloader"><FadingBalls color="#901aa5" /></div>
										:
										<span className="big_text_number" style={{ color: '#901aa5' }}>{this.context.dashboardData.jobsCount}</span>}
								</div>

								<div className="widget">
									<h3 className="widget_title">Total Invoices</h3>
									{this.context.loading ?
										<div className="preloader"><FadingBalls color="#009688" /></div>
										:
										<span className="big_text_number" style={{ color: '#009688' }}>{this.context.dashboardData.invoicesCount}</span>}
								</div>

								<div className="widget">
									<h3 className="widget_title">Paid Invoices</h3>
									{this.context.loading ?
										<div className="preloader"><FadingBalls color="#a37666" /></div>
										:
										<span className="big_text_number" style={{ color: '#a37666' }}>{this.context.dashboardData.paidInvoicesCount}</span>}
								</div>

								<div className="widget">
									<h3 className="widget_title">Out Of Stock</h3>
									{this.context.loading ?
										<div className="preloader"><FadingBalls color="#dd4477" /></div>
										:
										<span className="big_text_number" style={{ color: '#dd4477' }}>{this.context.dashboardData.outofStockCount}</span>}
								</div>

								<div className="widget">
									<h3 className="widget_title">Total Customers</h3>
									{this.context.loading ?
										<div className="preloader"><FadingBalls color="#cddc39" /></div>
										:
										<span className="big_text_number" style={{ color: '#cddc39' }}>{this.context.dashboardData.customersCount}</span>}
								</div>

								<div className="widget">
									<h3 className="widget_title">Total Categories</h3>
									{this.context.loading ?
										<div className="preloader"><FadingBalls color="#97cfe2" /></div>
										:
										<span className="big_text_number" style={{ color: '#97cfe2' }}>{this.context.dashboardData.categoriesCount}</span>}
								</div>

								<div className="widget">
									<h3 className="widget_title">Total Items</h3>
									{this.context.loading ?
										<div className="preloader"><FadingBalls color="#9e9e9e" /></div>
										:
										<span className="big_text_number" style={{ color: '#9e9e9e' }}>{this.context.dashboardData.itemsCount}</span>}
								</div>
							</div> */}

							{/* Numbers section ends here */}

							<div className="statistics_section">

								<div className="revenue">
									<div className="revenue_chart widget">
										<h3 className="widget_title">Yearly Statistics</h3>
										{this.context.loading ?
											<div className="preloader"><FadingBalls color="#dd4477" /></div>
											:
											<Chart
												chartType="ColumnChart"
												width="100%"
												height="100%"
												data={barChartData}
												options={options}
											/>}
									</div>
								</div>

								<div className="statistics">
									<div className="revenue_distribution widget">
										<h3 className="widget_title">Statistics Distribution</h3>
										{this.context.loading ?
											<div className="preloader"><FadingBalls color="#dd4477" /></div>
											:
											<Chart
												chartType="PieChart"
												width="100%"
												height="100%"
												data={pieChartData}
												options={{
													...options,
													pieHole: 0.4,
													is3D: false,
													chartArea: {
														'width': '90%',
														'height': '90%'
													},
												}}
											/>}
									</div>
								</div>
							</div>
						</>
					}
				</div>
			</Wrapper>
		)
	}
}
