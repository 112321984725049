import { Component } from 'react';

import './PrintQuote.scss';
import { dateFormat, infocustomeradd, currencyFormat } from "utils/utils";

import logo from 'assets/printlogo.png';

export default class PrintQuote extends Component {
    render() {

        let quote = this.props.quote || {};
        let invoice = this.props.invoice || {};

        // console.log("printquote", quote)
        // console.log("printinvoice", invoice)

        var itemsTotal, nextFitDate, paymentstotal, jobnotes, itemsTotalNET, remainder, discount, createdAt, operator, tcs, description, customer, items, quotePriceTotal, totalVAT, payments, showvat = false
        paymentstotal = 0;
        let isinvoice = invoice._id ? true : false;

        if (isinvoice) {
            operator = invoice.creator.fname;
            items = invoice.invoice_lines
            itemsTotalNET = items.reduce((total, item) => (item.net_amount ? (total += (Number(item.net_amount))) : total), 0)?.toFixed(2)
            itemsTotal = items.reduce((total, item) => (item.net_amount ? (total += (Number(item.net_amount) + Number(item.tax_amount))) : total), 0)?.toFixed(2)
            totalVAT = items.reduce((totalvat, item) => (item.tax_amount ? (totalvat += Number(item.tax_amount)) : totalvat), 0)?.toFixed(2);
            remainder = invoice.total_amount - paymentstotal;
            quotePriceTotal = invoice.total_amount;
            showvat = invoice.quote.showvat;
            description = invoice.notes;
            jobnotes = invoice.quote.showNotesOnInvoice ? invoice.quote.description : "";
            customer = invoice.customer;
            createdAt = invoice.createdAt;
            tcs = invoice.tcs;
            payments = invoice.payments;
            nextFitDate = invoice.quote.nextFitDate ? dateFormat(invoice.quote.nextFitDate, "nice") : "";
            paymentstotal = payments ? payments.reduce((total, payment) => (payment.payment_amount ? (total += Number(payment.payment_amount)) : 0), 0)?.toFixed(2) : 0;
        }
        else {
            operator = quote.creator.fname;
            items = quote.items;
            showvat = quote.showvat;
            customer = quote.customer;
            createdAt = quote.createdAt;
            itemsTotalNET = items.reduce((total, item) => (item.net_amount ? (total += (Number(item.net_amount))) : total), 0)?.toFixed(2)
            itemsTotal = items.reduce((total, item) => (item.net_amount ? (total += (Number(item.net_amount) + Number(item.tax_amount))) : total), 0)?.toFixed(2)
            totalVAT = items.reduce((totalvat, item) => (item.tax_amount ? (totalvat += Number(item.tax_amount)) : totalvat), 0)?.toFixed(2);
            remainder = quote.total_amount;
            jobnotes = (quote.description && quote.showNotesOnInvoice) ? quote.description : "";
            quotePriceTotal = quote.altPrice ? quote.altPrice : itemsTotal;
            tcs = quote.tcs;
            nextFitDate = quote.nextFitDate ? dateFormat(quote.nextFitDate, "nice") : "";
            paymentstotal = 0;
        }

        discount = itemsTotal - quotePriceTotal;
        let quoteAccepted = quote.accepted_date ? dateFormat(quote.accepted_date, "nice") : "Not Yet Accepted"
        let status = paymentstotal == quotePriceTotal ? "PAID" : paymentstotal > 0 ? "PART PAID" : "NOT PAID";

        return (
            <>
                <div className='printdiv'>
                    <div className='table quotetable'>
                        <div className='tbody'>
                            <div className='tr header'>
                                <div className="td">
                                    <img src={logo} className="logo" /><br />
                                    Avenue 4, Station Lane, Witney, OX28 4BN
                                    <br />
                                    TEL: 01993 703833 FAX: 01993 703833
                                    <br />
                                    info@carpetoptions.co.uk
                                    <br />
                                    VAT Number:  410755967
                                </div>
                                <div className="td quotejobinvoicedetails">
                                    <b>Operator: </b> {operator}<br />

                                    {(isinvoice) ? <>
                                        <b>Related Job #: </b> {invoice.quote.numericID}<br />
                                        {nextFitDate && <>
                                            <b>Fitting Date: </b> {nextFitDate}<br />
                                        </>}

                                        <b>Invoice Raised: </b> {dateFormat(invoice.createdAt, "nice")}<br />
                                        <b>Invoice Due: </b> {dateFormat(invoice.due_date, "nice")}<br />
                                        <b>Invoice: {invoice.displayed_as}</b><br /><br />
                                        <h3>{status}</h3>
                                    </> : ""}
                                    {(!isinvoice) ? <>

                                        <b>Created:</b> {dateFormat(createdAt, "nice")}<br />
                                        {quoteAccepted ? <><b>Accepted:</b>  {quoteAccepted}</> : ""}<br />
                                        {nextFitDate && <>
                                            <b>Fit Date: </b> {nextFitDate}<br />
                                        </>}
                                        <h3>{quoteAccepted ? "Job" : "Quote"}# {quote.numericID} </h3>
                                    </> : ""}

                                </div>
                            </div>

                            <div className='table customerdetails border'>
                                <div className='td'>
                                    <strong>Customer </strong><br /><br />
                                    {customer.company && <><b>{customer.company}</b><br /></>}
                                    {customer?.title} {customer?.fname} {customer?.lname}<br />
                                    {(customer.address1) ? <>{customer.address1}<br /></> : ""}
                                    {(customer.address2) ? <>{customer.address2}<br /></> : ""}
                                    {(customer.town) ? <>{customer.town}<br /></> : ""}
                                    {(customer.city) ? <>{customer.city}<br /></> : ""}
                                    {(customer.postCode) ? <>{customer.postCode}<br /></> : ""}
                                    {(customer.country) ? <>{customer.country}<br /></> : ""}
                                </div>
                                <div className='td'>

                                    {customer.phone && <><strong>Phone: </strong> {customer.phone}<br /></>}
                                    {customer.mobile && <><strong>Mobile: </strong> {customer.mobile}<br /></>}

                                </div>
                            </div>

                            {description && <>
                                <div className="border quotenotes"><b>Please Note :</b><br />{description}<br /><br /></div>
                            </>
                            }
                            {jobnotes && <>
                                <div className="border quotenotes"><b>Job Notes :</b><br />{jobnotes}<br /><br /></div>
                            </>
                            }
                            <div className={`border table quoteitems ${showvat && 'vat'}`}>
                                <div className='thead'>
                                    <div className="td">Area and Description of Goods</div>
                                    <div className="td">QTY</div>
                                    {showvat && <><div className="td">Net Price</div><div className="td">Item VAT</div></>}
                                    <div className="td">Line Total</div>
                                </div>
                                <div className='tbody'>
                                    {items.map((item, index) => {
                                        let net_amount, tax_amount, itemqty, unit_price
                                        if (isinvoice) {
                                            net_amount = item.net_amount;
                                            tax_amount = item.tax_amount;
                                            unit_price = item.unit_price;
                                            itemqty = item.quantity;
                                        }
                                        else {
                                            net_amount = item.net_amount;
                                            tax_amount = item.tax_amount;
                                            unit_price = item.unit_price;
                                            itemqty = item.qty;
                                        }
                                        let total_amount = tax_amount + net_amount;
                                        let qty = itemqty ? itemqty : 1;
                                        return (
                                            <div className='tr' key={index}>
                                                <div className='td'><b>{item.name}</b><br />{item.description !== "undefined" && typeof (item.description) != "undefined" ? item.description : ""}</div>
                                                <div className='td qty'>{qty}</div>

                                                {showvat && <div className='td net_amount'>{net_amount > 0 && currencyFormat(net_amount)}</div>}
                                                {showvat && <div className='td tax_amount'>{tax_amount > 0 && currencyFormat(tax_amount)}</div>}
                                                <div className='td grand_total'>{total_amount > 0 && currencyFormat(total_amount)}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            {(payments?.length > 0) ? <>{
                                <>
                                    <h4>Payments</h4>
                                    <div className={`border table payments ${showvat && 'vat'}`}>
                                        <div className='thead'>
                                            <div className='td'><b>Date</b></div>
                                            <div className='td'><b>Amount</b></div>
                                            <div className='td'><b>Method</b></div>
                                            <div className='td'><b>Status</b></div>
                                        </div>
                                        {payments.map((payment, index) => {

                                            return (
                                                <div className='tr' key={index}>
                                                    <div className='td'>{dateFormat(payment.payment_date, "nice")}</div>
                                                    <div className='td'>{currencyFormat(payment.payment_amount)}</div>
                                                    <div className='td'>{payment.payment_method}</div>
                                                    <div className='td'>{payment.payment_status}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </>
                            }</> : ""}

                            <div className="wrap_right">
                                <div className={`table pricesummarybox ${showvat && 'vat'}`}>
                                    {
                                        showvat &&
                                        <div className='tr'>
                                            <div className='td'><b>Net Amount:</b> </div>
                                            <div className='td'>{currencyFormat(itemsTotalNET)}</div>
                                        </div>
                                    }
                                    {
                                        !showvat &&
                                        <div className='tr'>
                                            <div className='td'><b>Total Price:</b> </div>
                                            <div className='td'>{currencyFormat(itemsTotal)}</div>
                                        </div>
                                    }
                                    {discount > 0 &&
                                        <>

                                            <div className='tr'>
                                                <div className='td'><b>Discount: </b></div>
                                                <div className='td'>{currencyFormat(discount)}</div>
                                            </div>
                                        </>
                                    }
                                    {showvat &&
                                        <>
                                            <div className='tr'>
                                                <div className='td'><b>VAT: </b></div>
                                                <div className='td'>{currencyFormat(totalVAT)}</div>
                                            </div>
                                            <div className='tr'>
                                                <div className='td'><b>Total: </b></div>
                                                <div className='td'>{currencyFormat(quotePriceTotal)}</div>
                                            </div>
                                        </>
                                    }
                                    {!showvat && discount > 0 &&
                                        <>
                                            <div className='tr'>
                                                <div className='td'><b>Total: </b></div>
                                                <div className='td'>{currencyFormat(quotePriceTotal)}</div>
                                            </div>
                                        </>
                                    }
                                    {paymentstotal > 0 &&
                                        <>
                                            <div className='tr'>
                                                <div className='td'><b>Payments: </b></div>
                                                <div className='td'>{currencyFormat(paymentstotal)}</div>
                                            </div>
                                            <div className='tr'>
                                                <div className='td'><b>Remainder: </b></div>
                                                <div className='td'>{currencyFormat(quotePriceTotal - paymentstotal)}</div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className='table tcs'>
                        {tcs && (<><div dangerouslySetInnerHTML={{ __html: tcs }} /></>)}
                    </div>
                </div>
            </>
        )
    }
}