import React, { Component } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BiUser } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import Search from 'components/Search/Search';

import './NavBar.scss';

class NavBar extends Component {

	render() {

		return (
			<>
				<nav className="nav">
					<div className="bread-crumbs">
						{/*<img src={logo.default} alt="logo"/>*/}
						<Link to="/dashboard" style={{color: '#fff', textDecoration: 'none'}}><span>Dashboard</span></Link>
						<span> / {window.location.pathname.substring(1).charAt(0).toUpperCase() + window.location.pathname.substring(2)}</span>
					</div>

					<div className="right-nav-side">
						<div className="navbar_right_icon_btns">
							<Search />
							{/* <button className="info">
								<AiOutlineInfoCircle
									style={{height: '22px', width: '22px'}}
									stroke={1}
								/>
							</button> */}

							<button className="user">
								<BiUser
									style={{height: '22px', width: '22px'}}
								/>
								{this.props.user.fname}
							</button>
						</div>

					</div>
				</nav>

			</>

		);
	}
}

export default NavBar;
